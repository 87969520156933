import ReactDOM from 'react-dom';
import { fetchData } from '../components/Helper'
import { useContext, useEffect, useRef, useState } from 'react';
import DataTables from '../components/Datatable';
import { useHistory } from 'react-router';
import GlobalContext from '../components/GlobalContext';

function Auctions() {
    const [context, setContext] = useContext(GlobalContext)
    const dt_reload = useRef()
    const history = useHistory();
    const [dt] = useState({
                            dt_url: 'auctions'+history.location.search,
                            dt_name: 'auction',
                            dt_column: [
                                { "data":"id", "title": "ID"},
                                { "data":"auction_start_date", "title":"Start Date"},
                                { "data":"auction_end_date", "title":"End Date" },
                                { "data":"total_vehicles", "name":"car_auctions.vehicle_id", "title":"Total Vehicles" },
                                { "data":"total_bids", "name":"biddings.id", "title":"Total Bids" },
                                { "data":"status", "title":"Status" },
                                { "data":"id", "title":"Action", searchable:false, sortable:false, class: 'text-end'}
                            ],
                            dt_column_defs: [
                                {
                                    targets: 6,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {
                                            rowData.total_vehicles > 0 ?
                                            <button type="button" className="btn btn-xs btn-outline-secondary m-2px" title="View Vehicles" onClick={ () => viewVehicles(rowData.id, rowData.status)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_eyefill" />
                                                </svg>
                                            </button>
                                            : ''
                                        }
                                        {
                                            rowData.status === "open" && context.role == "admin" ?
                                        
                                            <button type="button" className="btn btn-xs btn-outline-danger" title="Close" onClick={ () => handleChangeStatus('closed', rowData.id)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_checkcross" />
                                                </svg>
                                            </button>
                                            : ''
                                        }
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 5,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {
                                            rowData.status === "open" ? <span className="text-success">Open</span> : <span className="text-danger">Close</span>
                                        }
                                        </>, td)
                                    },
                                },

                            ],
                            dt_paging: true,
                        })

    const handleChangeStatus = (status, id) => {
        fetchData(`auction/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            dt_reload.current.reloadDatatable()
        }, (err) => {
            
        })
    }

    const viewVehicles = (id, status) => {
        history.push({pathname:`/auction-list/${id}/vehicles`, state:status})
    }

    useEffect(() => {dt_reload.current.reloadDatatable('reload', 'auctions'+history.location.search)}, [history.location])
    return (
        <>
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row py-2 align-items-center">
                    <div className="col-sm-6 py-1">
                        <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_gavel"></use>
                            </svg>Auctions
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTables dt_data={dt} ref={dt_reload}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="editCategory" aria-hidden="true" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header bg-light py-2">
                        <h5 className="modal-title">Edit Category</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
              
                </div>
            </div>
        </div>
        </>
    )
}

export default Auctions