import _ from "lodash";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import $ from 'jquery'

const API_URL = process.env.REACT_APP_API_URL
export const  IMAGE_URL = process.env.REACT_APP_ASSET_URL

export const showAlert = (data, autohide=true) => {
    if(data.message){
        let idGenerate = _.floor((_.random(0, 9) * 1000000) + 1)
        let alertType = (data.status === true) ? 'success' : 'danger'
        alertType = data.message.type ?? alertType
        
        const htmlToast = `<div id="${idGenerate}" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000" data-bs-autohide="${autohide}"><div class="d-flex bg-${alertType}">
                <div class="toast-body fw-medium text-white">
                    ${data.message.alert ?? data.message}
                </div>
                <button type="button" class="btn-close ms-auto me-2 mt-2 min-wh-20px" data-bs-dismiss="toast" aria-label="Close"></button></div>
            </div>`;
        
        let toastContainer = document.getElementById('toastContainer')
        toastContainer.innerHTML = htmlToast;
    
        let getIdToast = document.getElementById(idGenerate);
        let toast = new bootstrap.Toast(getIdToast);
        toast.show();
        getIdToast.addEventListener('hidden.bs.toast', function() {
            setTimeout(() => {
                this.remove();
            }, 700);
        });

    }else if(data.errors) {
        for(let key in data.errors){
            let errorClass = document.getElementsByClassName(`${key}-error`)[0]
            let check = /edit_/;
            if( check.test(key) ) {
                let edit_error = data.errors[key][0].replace(' edit ', ' ')
                errorClass.innerHTML = edit_error
            }else {
                errorClass.innerHTML = data.errors[key][0]
            }
            errorClass.classList.remove('d-none')
        }
    }
}

export const fetchData = async (url, method, data, token, process, res, err, autohide=true, loader = true) => {
    if(loader){
        $('#loader').removeClass('d-none')
    }
    
    let headers = {
        'Accept': 'application/json',
    }

    if(process){
        headers = {...headers, 'contentType': false, 'processData': false}
    }else{
        headers = {...headers, 'Content-Type': 'application/json'}
    }

    if(token){
        const TOKEN = localStorage.getItem("ma-token") ? localStorage.getItem("ma-token") : ''
        headers = {...headers, 'Authorization': 'Bearer ' + TOKEN}
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if(data){
        request = {...request, 'body': process ? data : JSON.stringify(data)}
    }
    
    await fetch(`${API_URL}${url}`, request).then((response) => response.json()).then((json) => {
        if(loader){
            $('#loader').addClass('d-none')
        }
        if(json.message == "Unauthenticated."){
            localStorage.removeItem("ma-token")
        }else{
            showAlert(json, autohide)
            res(json)
        }
    })
    .catch((error) => {
        //err(error);
    });
}

export const checkFile = (e) => {
    const fileExt = ['jpeg', 'jpg', 'png'];
    const files = e.target.files[0];
    let ext = files.name.split('.').pop();
    if (!fileExt.includes(ext.toLowerCase())) {
        alert('Please upload file in pdf, jpeg, jpg, png format.');
        return ''
    } else {
        return files
    }
}

export const countDown = (date) => {
    var countDownDate = new Date(date).getTime();
    
    var x = setInterval(function() {
        let time_text = 'Auction Ended';
        var now = new Date().getTime();
        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            clearInterval(x);
        }else if(distance < 86400){
            time_text = "<span class='text-danger'>"+ days + "d " + hours + "h "
            + minutes + "m " + seconds + "s </span>";
        }else{
            time_text = "<span>"+ days + "d " + hours + "h "
            + minutes + "m " + seconds + "s </span>";
        }

        $(".auctionCounter").html(time_text);
    }, 1000);
}

export const scrollbarSetToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
}

export const isNumberKey = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode !== 190 || charCode !== 110) && (charCode > 105 || charCode < 96)){
        evt.preventDefault();
    }
}

export const isNumberFloatKey = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}
