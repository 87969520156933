import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useContext, useEffect } from "react";
import GlobalContext from "../components/GlobalContext";
import { scrollbarSetToTop } from '../components/Helper'

function Home() {
    const [context, setContext] = useContext(GlobalContext)

    useEffect(() => {
        scrollbarSetToTop()
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Quality Car Auctions | Home</title>
            </Helmet>
            <section className="hero-section bg-black d-flex align-items-center justify-content-center">
                <img src="/images/hero2.jpg" className="w-100 h-100 position-absolute start-0 top-0 img-cover opacity-25 zindex-0 pointer-none"/>
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-8 text-center">
                            <h1 className="display-2 fw-bold text-white mb-4">Quality Car Auctions</h1>
                            <p className="display-6 text-white fw-light m-0">
                            Vehicle Auctions Made Easy
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-light py-5">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-sm-12 col-md-10 col-xl-8">
                            <h2 className="h1 fw-bold w-75 mx-auto">
                                Quality Car Auctions
                            </h2>
                            <p className="opacity-80 lh-base mb-4 fs-5 ps1-4">
                            Quality Car Auction is Pakistan’s Premier online car auction website for the trade and public. We have a collection of used, salvage and seized vehicles for sale in our weekly online auctions. Browse through our online stock of vehicles including cars, vans, and other domestic and commercial vehicles for sale.
                            </p>
                            <Link to="/auctions" className="btn btn-tiled btn-dark px-4 m-1" onClick={() => localStorage.setItem('redirect_path', 'auctions')}>Buy A Vehicle</Link>
                            <Link to={context.role === 'buyer' ? "/register" : "/vehicle-list/sell-a-vehicle"} className="btn btn-tiled btn-primary px-4 m-1" onClick={() => localStorage.setItem('redirect_path', 'vehicle-list/sell-a-vehicle')}>Sell A Vehicle</Link>
                            <Link to="/register" className="btn btn-tiled btn-success px-4 m-1">Register with Quick Car Auction</Link>
                        </div>
                    </div>
                </div>

                <div className="container py-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="text-muted"/>
                        </div>
                    </div>
                </div>

                <div className="container py-2 py-sm-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="image-tiled image-tiled-reverse bg-blackis rounded-10">
                                <img src="/images/car.jpg" className="w-100"/>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-5 py-3">
                            <h3 className="text-blackis fw-medium">Open Auction of Vehicles for General Public</h3>
                            <ul className="opacity-80 lh-base fs-5 ps1-4">
                                <li>Buy and sell from anywhere in Pakistan</li>
                                <li>Real time transparency of Auctioned prices</li>
                                <li>All vehicles are inspected by our experienced and qualified technicians</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container py-2 py-sm-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-1">
                            <div className="image-tiled image-tiled-reverse bg-blackis rounded-10">
                                <img src="/images/car2.jpg" className="w-100"/>
                            </div>
                        </div>
                        <div className="col-md-6 pe-md-5 py-3">
                            <h3 className="text-blackis fw-medium">Buy with Confidence</h3>
                            <ul className="opacity-80 lh-base fs-5 ps1-4">
                                <li>All Vehicles go through professional mechanical and physical checks</li>
                                <li>Accurate vehicle mechanical and condition report with each vehicle</li>
                                <li>Fair, transparent, and open bidding system</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container py-2 py-sm-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="image-tiled image-tiled-reverse bg-blackis rounded-10">
                                <img src="/images/car4.jpg" className="w-100"/>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-5 py-3">
                            <h3 className="text-blackis fw-medium">Country wide coverage</h3>
                            <ul className="opacity-80 lh-base fs-5 ps1-4">
                                <li>Selection of vehicles from all over the country</li>
                                <li>Multiple Auction Sites to buy from</li>
                                <li>Buy Vehicles from the site closest to you</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container py-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="text-muted"/>
                        </div>
                    </div>
                </div>

                <div className="container py-5">
                    <div className="row justify-content-center text-center">
                        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 mb-4">
                            <h3 className="text-blackis fw-medium">Our Expertise</h3>
                            <p className="opacity-80 lh-base fs-5 ps1-4">
                                Over 25 years of vehicle trade experience
                            </p>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 g-4 g-sm-5">
                        <div className="col">
                            <div className="card-tiled card-tiled-reverse shadow border p-4 rounded-10 h-100">
                                <div className="no-tiled">
                                    <h6 className="display-8 fw-medium">Additional Services (With additional cost)</h6>
                                    <p className="m-0 opacity-80 lh-base fs-6 ps1-4">
                                        Vehicle valet (wash and clean),
                                        Vehicle Oil service,
                                        Vehicle Delivery (Please phone the site office for latest delivery fees).
                                    </p>
                                </div>
                                <a href="#" className="stretched-link"></a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-tiled shadow border p-4 rounded-10 h-100">
                                <div className="no-tiled">
                                    <h6 className="display-8 fw-medium">Fleet Management</h6>
                                    <p className="m-0 opacity-80 lh-base fs-6 ps1-4">
                                        We partner with companies for vehicle fleet to be auctioned.
                                    </p>
                                </div>
                                <a href="#" className="stretched-link"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-white py-5 d-none">
                <div className="container py-sm-4">
                    <div className="row">
                        <div className="col-sm-12 text-center mb-4">
                            <h2 className="fw-bold">This week's top picks</h2>
                        </div>
                    </div>
                    <div className=" row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
                        <div className="col">
                            <a href="#" className="card shadow-sm overflow-hidden rounded-0 border-0 text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src="/images/car9.jpg" className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h5 className="text-in2line fw-light h3">MBW</h5>
                                            <div className="fw-light">5 Series (2013)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1 border">
                                    <div className="row row-cols-4 text-center text-secondary">
                                        <div className="col">
                                            <svg className="bi bi-4x text-primary">
                                                <use xlinkHref="#bi_thumbsup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Start & Drive</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-3x text-primary">
                                                <use xlinkHref="#bi_key" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has Keys</div>
                                        </div>
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_tools" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Category N/R</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-4x text-primary">
                                                <use xlinkHref="#bi_filetext" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has V5</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_speedometer" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">139,000 Miles</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_arrowdownup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Automatic</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_geo" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Shildon</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_fuelpump" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Diesel</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex border border-top-0 bg-light py-3 rounded-0">
                                    <div className="">
                                        <h6 className="fw-light small mt-0 mb-1">Current Bid:</h6>
                                        <h5 className="m-0">$3921</h5>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary ms-auto align-self-center">
                                        View Details
                                    </button>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="#" className="card shadow-sm overflow-hidden rounded-0 border-0 text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src="/images/car10.jpg" className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h5 className="text-in2line fw-light h3">FORD</h5>
                                            <div className="fw-light">Focus (2011)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1 border">
                                    <div className="row row-cols-4 text-center text-secondary">
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_thumbsdown" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Start & Drive</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-3x text-primary">
                                                <use xlinkHref="#bi_key" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has Keys</div>
                                        </div>
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_tools" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Category N/R</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_filetext" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has V5</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_speedometer" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">139,000 Miles</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_arrowdownup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Automatic</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_geo" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Shildon</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_fuelpump" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Diesel</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex border border-top-0 bg-light py-3 rounded-0">
                                    <div className="">
                                        <h6 className="fw-light small mt-0 mb-1">Current Bid:</h6>
                                        <h5 className="m-0">$2134</h5>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary ms-auto align-self-center">
                                        View Details
                                    </button>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="#" className="card shadow-sm overflow-hidden rounded-0 border-0 text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src="/images/car11.jpg" className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h5 className="text-in2line fw-light h3">Volkswagen</h5>
                                            <div className="fw-light">Golf (2011)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1 border">
                                    <div className="row row-cols-4 text-center text-secondary">
                                        <div className="col">
                                            <svg className="bi bi-4x text-primary">
                                                <use xlinkHref="#bi_thumbsup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Start & Drive</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-3x text-primary">
                                                <use xlinkHref="#bi_key" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has Keys</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_tools" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Category N/R</div>
                                        </div>
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_filetext" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has V5</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_speedometer" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">139,000 Miles</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_arrowdownup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Automatic</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_geo" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Shildon</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_fuelpump" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Diesel</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex border border-top-0 bg-light py-3 rounded-0">
                                    <div className="">
                                        <h6 className="fw-light small mt-0 mb-1">Current Bid:</h6>
                                        <h5 className="m-0">$1988</h5>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary ms-auto align-self-center">
                                        View Details
                                    </button>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="#" className="card shadow-sm overflow-hidden rounded-0 border-0 text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src="/images/car13.jpg" className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h5 className="text-in2line fw-light h3">Mahindara</h5>
                                            <div className="fw-light">4 Series (2014)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1 border">
                                    <div className="row row-cols-4 text-center text-secondary">
                                        <div className="col">
                                            <svg className="bi bi-4x text-primary">
                                                <use xlinkHref="#bi_thumbsup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Start & Drive</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-3x text-primary">
                                                <use xlinkHref="#bi_key" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has Keys</div>
                                        </div>
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_tools" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Category N/R</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-4x text-primary">
                                                <use xlinkHref="#bi_filetext" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has V5</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_speedometer" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">139,000 Miles</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_arrowdownup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Automatic</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_geo" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Shildon</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_fuelpump" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Diesel</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex border border-top-0 bg-light py-3 rounded-0">
                                    <div className="">
                                        <h6 className="fw-light small mt-0 mb-1">Current Bid:</h6>
                                        <h5 className="m-0">$3921</h5>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary ms-auto align-self-center">
                                        View Details
                                    </button>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="#" className="card shadow-sm overflow-hidden rounded-0 border-0 text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src="/images/car14.jpg" className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h5 className="text-in2line fw-light h3">Suzuki</h5>
                                            <div className="fw-light">Mark (2011)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1 border">
                                    <div className="row row-cols-4 text-center text-secondary">
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_thumbsdown" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Start & Drive</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-3x text-primary">
                                                <use xlinkHref="#bi_key" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has Keys</div>
                                        </div>
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_tools" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Category N/R</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_filetext" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has V5</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_speedometer" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">139,000 Miles</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_arrowdownup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Automatic</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_geo" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Shildon</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_fuelpump" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Diesel</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex border border-top-0 bg-light py-3 rounded-0">
                                    <div className="">
                                        <h6 className="fw-light small mt-0 mb-1">Current Bid:</h6>
                                        <h5 className="m-0">$2134</h5>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary ms-auto align-self-center">
                                        View Details
                                    </button>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="#" className="card shadow-sm overflow-hidden rounded-0 border-0 text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src="/images/car7.jpg" className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h5 className="text-in2line fw-light h3">Audi</h5>
                                            <div className="fw-light">Golf (2011)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1 border">
                                    <div className="row row-cols-4 text-center text-secondary">
                                        <div className="col">
                                            <svg className="bi bi-4x text-primary">
                                                <use xlinkHref="#bi_thumbsup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Start & Drive</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-3x text-primary">
                                                <use xlinkHref="#bi_key" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has Keys</div>
                                        </div>
                                        <div className="col">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_tools" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Category N/R</div>
                                        </div>
                                        <div className="col opacity-50">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_filetext" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Has V5</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_speedometer" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">139,000 Miles</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_arrowdownup" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Automatic</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-4x">
                                                <use xlinkHref="#bi_geo" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Shildon</div>
                                        </div>
                                        <div className="col mt-3">
                                            <svg className="bi bi-3x">
                                                <use xlinkHref="#bi_fuelpump" />
                                            </svg>
                                            <div className="small fs-13px lh-sm">Diesel</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex border border-top-0 bg-light py-3 rounded-0">
                                    <div className="">
                                        <h6 className="fw-light small mt-0 mb-1">Current Bid:</h6>
                                        <h5 className="m-0">$1988</h5>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary ms-auto align-self-center">
                                        View Details
                                    </button>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-sm-12 text-center">
                            <p>
                                Can't see the vehicle you're looking for? Search, or view all our listings!
                            </p>
                            <a href="#" className="btn btn-outline-primary">View All Linsting &#8594;</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
