import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { fetchData, scrollbarSetToTop } from '../components/Helper'

function ForgotPassword() {
    const [email, setEmail] = useState();
    const [role, setRole] = useState('admin');

    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        setEmail(e.target.value)
    }

    const submitForgotForm = () => {
        fetchData('forgot-password', 'POST', {email: email, 'role': role}, false, false, (res) => {
        }, (err) => {
            //console.log(err)
        })
    }

    useEffect(() => {
        scrollbarSetToTop()
    }, []);

    return (
        <>
            <section className="py-5">
                <div className="container py-md-5">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-10">
                            <div className="row mx-0">
                                <div className="col-md-6 col-lg-5 order-md-2 bg-light ">
                                    <form className="py-3 p-lg-5">
                                        <h4 className="lh-1">Forgot Password</h4>
                                        <hr className="mt-3 mb-4 w-40px text-muted" />
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" type="radio" id='admin' defaultChecked onClick={() => setRole('admin')} />
                                                <label className="form-check-label" htmlFor="admin">Admin</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" type="radio" id='buyer' onClick={() => setRole('buyer')} />
                                                <label className="form-check-label" htmlFor="buyer">Buyer</label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" type="radio" id='seller_private' onClick={() => setRole('seller_private')} />
                                                <label className="form-check-label" htmlFor="seller_private">Private Seller</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" id='seller_corporate' type="radio" onClick={() => setRole('seller_corporate')} />
                                                <label className="form-check-label" htmlFor="seller_corporate">Corporate Seller</label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" name="email" className="form-control" placeholder="Enter Your Email"  onChange={handleInputChange} />
                                            <div className="error email-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div>
                                        <button type="button" className="btn btn-primary fw-medium px-4"  onClick={submitForgotForm} >Send Password Reset Link &#10140;</button>
                                        </div>
                                        <div className="small text-muted py-3 mt-4">
                                            Already have an account? <Link to={"/login"} className="fw-medium">Login</Link>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6 col-lg-7">
                                    <img src="images/login.svg" className="w-100" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword