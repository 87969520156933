import ReactDOM from 'react-dom';
import { fetchData } from '../components/Helper'
import { useContext, useEffect, useRef, useState } from 'react';
import DataTables from '../components/Datatable';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import GlobalContext from '../components/GlobalContext';
import _ from "lodash";

function AuctionVehicles() {
    const dt_reload = useRef()
    const history = useHistory();
    const [auctionId] =  useState(useParams().id)
    const [context, setContext] = useContext(GlobalContext)
    const [data, setData] = useState()

    useEffect(() => {
        fetchData(`auction-details/${auctionId}`, 'GET', '', true, false, (res) => {
            if(res.status){
                setData(res)
            }
        }, (err) => {
            //console.log(err)
        })
    }, []);

    const [dt] = useState({
                            dt_url: 'vehicles?auction='+auctionId,
                            dt_name: 'vehicle',
                            dt_column: [
                                { "data":"id" , "title": "ID"},
                                { "data":"make", "title":"Make" },
                                { "data":"model", "title":"Model" },
                                { "data":"year", "title":"Year" },
                                { "data":"type", "title":"Type" },
                                { "data":"colour", "title":"Color" },
                                { "data":"fuel", "title":"Fuel" },
                                { "data":"transmission", "title":"Transmission" },
                                { "data":"mileage", "title":"Mileage" },
                                { "data":"location", "title":"Location" },
                                { "data":"reserve_price", "name":"reserve_price", "title":"Reserve Price", "visible": context.role === 'buyer' ? false: true },
                                { "data":"city_of_registration", "title":"City of Registration" },
                                { "data":"date_of_registration", "title":"Date of Registration" },
                                { "data":"calender", "title":"Vehicle Delivery Date", "visible": context.role === 'buyer' ? false: true },
                                { "data":"max_bid", "name":"biddings.max_bid", "title":"Maximum Bid Amount", "visible": context.role === 'buyer' ? false: true },
                                { "data":"number_plate", "title":"Number Plate" },
                                { "data":"token_paid_till", "title":"Token Paid Till"},
                                { "data":"status", "title":"Status" },
                                { "data":"id", "title":"Action", searchable:false, sortable:false , class:'text-end'}
                            ],
                            dt_column_defs: [
                                {
                                    targets: 18,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {rowData.status != "approved" && rowData.max_bid > 0 ?
                                            <button type="button" className="btn btn-xs btn-outline-secondary" title="View Bids" onClick={ () => viewBids(rowData.id, rowData.max_bid, rowData.auction_status)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_eyefill" />
                                                </svg>
                                            </button>
                                        :'' }
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 10,
                                    createdCell: (td, cellData, rowData, row, col) => {
                                        ReactDOM.render(<><span>PKR {rowData.reserve_price.toFixed(2)}</span></>, td)
                                    },
                                },
                                {
                                    targets: 14,
                                    createdCell: (td, cellData, rowData, row, col) => {
                                        ReactDOM.render(<><span>PKR {rowData.max_bid ? rowData.max_bid.toFixed(2) : '0.00'}</span></>, td)
                                    },
                                },
                                // {
                                //     targets: 5,
                                //     createdCell: (td, cellData, rowData, row, col) => {
                                //         ReactDOM.render(<><span className={`${_.kebabCase(rowData.colour)} ${rowData.colour == 'Yellow' || rowData.colour == 'White' ?  "badge text-dark border": "badge" }`} >{rowData.colour}</span></>, td)
                                //     },
                                // },
                                {
                                    targets: 17,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        { rowData.status == "approved" ? <span className="text-success">Approved</span> : '' }
                                        { rowData.status == "pending" ? <span className="text-warning">Pending</span> : '' }
                                        { rowData.status == "rejected" ? <span className="text-danger">Rejected</span> : '' }
                                        { rowData.status == "in_auction" ? <span className="text-info">In Auction</span> : '' }
                                        { rowData.status == "reserve_not_met" ? <span className="text-info">Reserve Not Met</span> : '' }
                                        { rowData.status == "on_sale" ? <span className="text-info">On Sale</span> : '' }
                                        { ['pending_payment', 'paid'].includes(rowData.status) && context.role != 'buyer' ? <span className="text-info">Sold</span> : '' }
                                        { ['pending_payment', 'paid'].includes(rowData.status) && context.role == 'buyer' ? <span className="text-info">{ rowData.won_buyer_id == context.id ? 'Won' : 'On Sale' }</span> : '' }

                                        {/* { rowData.status == "on_sale" && rowData.auction_status == 'open' ? <span className="text-info">On Sale</span> : '' }
                                        { (context.role == 'seller_private' || context.role == 'seller_corporate' || context.role == 'admin') && rowData.status == "on_sale" && rowData.auction_status == 'closed' ? <span className="text-info">Sold</span> : rowData.status == "on_sale" && history.location.state == 'closed' && context.role != 'buyer' ? <span className="text-success">Approved</span> : '' }
                                        { context.role == 'buyer' && rowData.status == "on_sale" && rowData.auction_status == 'closed' && rowData.user_max_bid == rowData.max_bid ? <span className="text-info">Won</span> :  rowData.status == "on_sale" && context.role == 'buyer' && history.location.state == 'closed' ? <span className="text-info">On Sale</span> : ''} */}
                                        </>, td)
                                    },
                                },

                            ],
                            dt_paging: true,
                        })

    const viewBids = (id, max_bid, status) => {
        history.push({pathname: `vehicles/${id}/bids` , state : [max_bid, status]})
    }

    return (
        <>
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row py-2 align-items-center">
                    <div className="col-sm-6 py-1">
                        <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_gavel"></use>
                            </svg>Vehicle's List for Auction #{auctionId} - From {data ? data.data.auction_start_date : '#'} to {data ? data.data.auction_end_date : '#'}
                        </h6>
                    </div>
                    <div className="col-sm-6 py-1 text-sm-end">
                        <Link className="btn btn-dark" to={"/auction-list"} role="button">
                            <svg className="bi me-1">
                                <use xlinkHref="#bi_arrowleft" />
                            </svg>
                            Go Back
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTables dt_data={dt} ref={dt_reload}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="editCategory" aria-hidden="true" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header bg-light py-2">
                        <h5 className="modal-title">Edit Category</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
              
                </div>
            </div>
        </div>
        </>
    )
}

export default AuctionVehicles