import ReactDOM from 'react-dom';
import { fetchData } from '../components/Helper'
import { useEffect, useRef, useState } from 'react';
import DataTables from '../components/Datatable';
import { Link } from 'react-router-dom';
import _ from "lodash";

function BidVehicles() {
    const dt_reload = useRef()
    const [data, setData] = useState()

    useEffect(() => {
        fetchData(`bid-vehicles`, 'GET', '', true, false, (res) => {
            if(res.status){
                setData(res)
            }
        }, (err) => {})
    }, []);

    const [dt] = useState({
                            dt_url: 'bid-vehicles',
                            dt_name: 'vehicle',
                            dt_column: [
                                { "data":"id" , "title": "ID"},
                                { "data":"make", "title":"Make" },
                                { "data":"model", "title":"Model" },
                                { "data":"year", "title":"Year" },
                                { "data":"type", "title":"Type" },
                                { "data":"colour", "title":"Color" },
                                { "data":"fuel", "title":"Fuel" },
                                { "data":"transmission", "title":"Transmission" },
                                { "data":"mileage", "title":"Mileage" },
                                { "data":"location", "title":"Location" },
                                { "data":"city_of_registration", "title":"City of Registration" },
                                { "data":"date_of_registration", "title":"Date of Registration" },
                                { "data":"number_plate", "title":"Number Plate" },
                                { "data":"token_paid_till", "title":"Token Paid Till"},
                                { "data":"status", "title":"Status" },
                            ],
                            dt_column_defs: [
                                // {
                                //     targets: 5,
                                //     createdCell: (td, cellData, rowData, row, col) => {
                                //         ReactDOM.render(<><span className={`${_.kebabCase(rowData.colour)} ${rowData.colour == 'Yellow' || rowData.colour == 'White' ?  "badge text-dark border": "badge" }`} >{rowData.colour}</span></>, td)
                                //     },
                                // },
                                {
                                    targets: 14,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        { rowData.status == "approved" ? <span className="text-success">Approved</span> : '' }
                                        { rowData.status == "pending" ? <span className="text-warning">Pending</span> : '' }
                                        { rowData.status == "rejected" ? <span className="text-danger">Rejected</span> : '' }
                                        { rowData.status == "in_auction" ? <span className="text-info">In Auction</span> : '' }
                                        { rowData.status == "reserve_not_met" ? <span className="text-info">Reserve Not Met</span> : '' }
                                        { rowData.status == "on_sale" ? <span className="text-info">On Sale</span> : '' }
                                        { rowData.status == "pending_payment" ? <span className="text-info">Sold</span> : '' }
                                        { rowData.status == "paid" ? <span className="text-success">Paid</span> : '' }
                                        </>, td)
                                    },
                                },
                            ],
                            dt_paging: true,
                        })


    return (
        <>
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row py-2 align-items-center">
                    <div className="col-sm-6 py-1">
                        <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_gavel"></use>
                            </svg>Bid Vehicle
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTables dt_data={dt} ref={dt_reload}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default BidVehicles