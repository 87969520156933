import React, { useEffect, useState } from 'react';
import { Switch } from "react-router-dom";
import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import VehicleSetting from '../pages/VehicleSetting';
import ForgotPassword from '../pages/ForgotPassword';
import Dashboard from '../pages/Dashboard';
import Vehicle from '../pages/Vehicle';
import User from '../pages/User';
import Auction from '../pages/Auction';
import VehicleDetail from '../pages/VehicleDetails';
import Auctions from '../pages/Auctions';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import Privacy from '../pages/Privacy';
import ScrapVehicle from '../pages/ScrapVehicle';
import ChangePassword from '../pages/ChangePassword';
import Profile from '../pages/Profile';
import Setting from '../pages/Setting';
import Bids from '../pages/Bids';
import TotalBids from '../pages/TotalBids';
import GlobalContext from './GlobalContext';
import { fetchData } from './Helper';
import FrontendLayout, { BackendLayout } from './Layout';
import AuctionVehicles from '../pages/AuctionVehicles';
import ResetPassword from '../pages/reset-password';
import NotFound from './NotFound';
import InvoiceList from '../pages/Invoice-list';
import BidVehicles from '../pages/Bid-vehicles';

export default function Routing () {
    const [context, setContext] = useState('');
    const [token] = useState(localStorage.getItem('ma-token'));
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        if(token){
            fetchData('get-auth-user-info', 'get', '', true, false, (res) => {
                if(res.status){
                    setLoader(false)
                    setContext(res.data)
                }
            }, (err) => {
                //console.log(err)
            })
        }else{
            setLoader(false)
        }
    }, [token, loader]);

    return (
        <>  
        {/* {console.log(loader)} */}
            <div className={`loader ${ loader ? '' : 'd-none' }`} id="loader">
                <div className="spinner-border text-blue" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>
            <GlobalContext.Provider value={[context, setContext]}>
                <Switch>
                <FrontendLayout exact path="/"><Home/></FrontendLayout>
                <FrontendLayout exact path="/home"><Home/></FrontendLayout>
                <FrontendLayout exact path="/login"><Login/></FrontendLayout>
                <FrontendLayout exact path="/register"><Register/></FrontendLayout>
                <FrontendLayout exact path="/forgot-password"><ForgotPassword/></FrontendLayout>
                <FrontendLayout exact path="/reset-password/:token/:email/:role"><ResetPassword/></FrontendLayout>
                <FrontendLayout exact path="/about-us"><AboutUs/></FrontendLayout>
                <FrontendLayout exact path="/contact-us"><ContactUs/></FrontendLayout>
                <FrontendLayout exact path="/scrap-a-vehicle"><ScrapVehicle/></FrontendLayout>
                <FrontendLayout exact path="/privacy-policy"><Privacy/></FrontendLayout>
                
                <FrontendLayout exact path="/auctions"><Auction/></FrontendLayout>
                <FrontendLayout exact path="/vehicle/details/:id"><VehicleDetail/></FrontendLayout>

                <BackendLayout exact path="/dashboard"><Dashboard/></BackendLayout>
                {
                    context.role === 'admin' ? 
                        <BackendLayout exact path="/user/:status?"><User/></BackendLayout>
                    : ''    
                }
                {
                    context.role !== 'buyer' ? 
                        <BackendLayout exact path="/vehicle-list/:sell_option?"><Vehicle/></BackendLayout>
                    : ''
                }
                {
                    <BackendLayout exact path="/total-bids"><TotalBids/></BackendLayout>
                }
                {
                    context.role === 'buyer' ? 
                        <BackendLayout exact path="/bid-vehicles"><BidVehicles/></BackendLayout>
                    : ''
                }
                {
                    context.role === 'admin' ?
                        <BackendLayout exact path="/vehicle-setting"><VehicleSetting/></BackendLayout>
                    : ''
                }
                   
                <BackendLayout exact path="/auction-list"><Auctions/></BackendLayout>
                <BackendLayout exact path="/auction-list/:id/vehicles/:vehicle_id/bids"><Bids/></BackendLayout>
                <BackendLayout exact path="/auction-list/:id/vehicles"><AuctionVehicles/></BackendLayout>
                <BackendLayout exact path="/change-password"><ChangePassword/></BackendLayout>
                <BackendLayout exact path="/profile"><Profile/></BackendLayout>
                {
                    context.role === 'admin' ?
                        <BackendLayout exact path="/setting"><Setting/></BackendLayout>
                    : ''
                }
                {
                    context.role === 'admin' ?
                        <BackendLayout exact path="/invoice-list"><InvoiceList/></BackendLayout>
                    : ''
                }
                
                <FrontendLayout><NotFound/></FrontendLayout>
                </Switch>
            </GlobalContext.Provider>
        </>
    )
}
