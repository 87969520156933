import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './style/style.css';
import Icons from './components/Icons';
import Routing from './components/Routing';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Routing/>
            </Switch>
        </BrowserRouter>
        <Icons/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
