import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useHistory, Route, Switch } from "react-router-dom";
import GlobalContext from "./GlobalContext";
import { Helmet } from "react-helmet";
import { fetchData } from "./Helper";

function FrontendLayout({ ...component }) {
    const [context, setContext] = useContext(GlobalContext)
    const History = useHistory()

    const handleLogout = () => {
        fetchData('logout', 'POST', '', true, false, (res) => {
            if (res.status) {
                setContext('')
                localStorage.removeItem("ma-token");
                if (localStorage.getItem("redirect_path")) {
                    localStorage.removeItem("redirect_path")
                }
                History.push("/")
            }
        }, (err) => {
            //console.log(err)
        })
    }
    return (
        <>
            <nav className="navbar navbar-expand-xl navbar-light shadow-sm">
                <div className="container">
                    <Link to='/' className="navbar-brand">
                        <img src="/images/logo-black.png" alt="logo" />
                    </Link>
                    <button className="navbar-toggler btn-tiled" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-xl-0">
                            <li className="nav-item">
                                <NavLink to='/home' className="nav-link">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/about-us' className="nav-link">About Us</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/auctions' className="nav-link">Vehicles</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/contact-us' className="nav-link">Contact Us</NavLink>
                            </li>
                            <li className="nav-item align-self-center ms-xl-3">
                                {
                                    context ? <>
                                        <Link to='/dashboard' className="btn btn-outline-primary btn-tiled px-4 me-1">Dashboard</Link>
                                        <button type="button" className="btn btn-primary btn-tiled px-4" onClick={handleLogout}>Logout</button>
                                    </>
                                        :
                                        <>
                                            <Link to='/register' className="btn btn-outline-primary btn-tiled px-4 me-1">Register</Link>
                                            <Link to='/login' className="btn btn-primary btn-tiled px-4">Login</Link>
                                        </>
                                }

                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Switch>
                <Route {...component} />
            </Switch>
            <footer className="front-footer">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4 mt-5">
                            <h5 className="text-white">About Us</h5>
                            <hr className="mt-2 h-2px w-40px opacity-60 text-light" />
                            <p className="pe-lg-5 mb-1">
                                Working with the public and private sectors, we manage the sale, de-fleet, or scrappage of vehicles from A to Z. We are open for any kind of vehicle on Quality Car Auctions site and ensure the safe storage of all the vehicles in stock until it gets sold,
                                from no damage vehicles in perfect running condition to accident damaged or faulty vehicles, we can auction them all.
                            </p>
                            <NavLink to={'/about-us'} className="small text-light opacity-50" title="Read more">
                                Read more &#10140;
                            </NavLink>
                        </div>
                        <div className="col-sm-6 col-lg-3 offset-lg-1 mt-5">
                            <h5 className="text-white">Links</h5>
                            <hr className="mt-2 h-2px w-40px opacity-60 text-light" />
                            <ul className="p-0 m-0 list-unstyled">
                                <li className="mb-2">
                                    <NavLink to={'/'}>
                                        Home
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to={'/about-us'}>
                                        About Us
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to={context.role === 'buyer' ? "/register" : "/vehicle-list/sell-a-vehicle"} onClick={() => localStorage.setItem('redirect_path', 'vehicle-list/sell-a-vehicle')}>
                                        Sell A Vehicle
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to={'/register'}>
                                        Register with us & Buy a Vehicle
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink to={'/contact-us'}>
                                        Contact Us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-lg-4 mt-5">
                            <h5 className="text-white">Get in touch</h5>
                            <hr className="mt-2 h-2px w-40px opacity-60 text-light" />
                            <ul className="p-0 m-0 list-unstyled">
                                <li className="mb-2">
                                    <svg className="bi bi-3x mt-1 me-2 mmw-20px">
                                        <use xlinkHref="#bi_geo" />
                                    </svg>
                                    Quality Car Auctions, (Near Grammar Public School), Sialkot Byepass, Gujranwala, Punjab, 
                                </li>
                                <li className="mb-2">
                                    <a href="tel:0123 456789" className="d-flex align-items-center">
                                        <svg className="bi bi-2x me-2 mmw-20px">
                                            <use xlinkHref="#bi_phone" />
                                        </svg>
                                        0322 6443232
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a href="mailto:contact@qcauction.com" className="d-flex align-items-center">
                                        <svg className="bi bi-2x me-2 mmw-20px">
                                            <use xlinkHref="#bi_mail" />
                                        </svg>
                                        enquiries@qcarauctions.com
                                    </a>
                                </li>
                            </ul>
                            {/* <hr className="mt-4 h-2px w-40px opacity-60 text-light"/>
                        <ul className="p-0 m-0 list-unstyled list-inline">
                            <li className="list-inline-item">
                                <a href="#">
                                    <svg className="bi fs-20px">
                                        <use xlinkHref="#bi_facebook" />
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#">
                                    <svg className="bi fs-20px">
                                        <use xlinkHref="#bi_twitter" />
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#">
                                    <svg className="bi fs-20px">
                                        <use xlinkHref="#bi_instagram" />
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#">
                                    <svg className="bi fs-20px">
                                        <use xlinkHref="#bi_linkedin" />
                                    </svg>
                                </a>
                            </li>
                        </ul> */}
                        </div>
                        <div className="col-sm-12 mt-2">
                            <hr />
                        </div>
                        <div className="col-md-6 order-md-1 text-center text-md-end pt-3 small">
                            <ul className="p-0 m-0 list-unstyled list-inline">
                                <li className="list-inline-item">
                                    <NavLink to={'/privacy-policy'}>
                                        Terms & Conditions
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 text-center text-md-start pt-3 small">
                            &copy; {new Date().getFullYear()}  Quality Car Auction - All Rights Reserved.
                        </div>
                    </div>
                </div>
            </footer>
            <div className="position-fixed top-0 end-0 p-2 toast-end" id="toastContainer" style={{ zIndex: '1000' }}></div>
        </>
    )
}

function BackendLayout({ ...component }) {
    const History = useHistory()
    const [context, setContext] = useContext(GlobalContext)
    const [token] = useState(localStorage.getItem('ma-token'));
    const [auctionId, setAuctionId] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        if (!token) {
            setContext('')
            History.push('/login')
        }

        if (context.role === 'buyer') {
            fetchData('statistics', 'GET', '', true, false, (res) => {
                if (res.status) {
                    setAuctionId(res.data[0].auction_id)
                }
            }, (err) => {
                //console.log(err)
            })
        }

        if (context.role === 'buyer' || context.role === 'seller_corporate' || context.role === 'seller_private') {
            fetchData(`user/note/${context.id}`, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setNote(res.data.notes)
                }
            }, (err) => { })
        }

    }, [token, context]);

    const handleLogout = () => {
        fetchData('logout', 'POST', '', true, false, (res) => {
            if (res.status) {
                setContext('')
                localStorage.removeItem("ma-token");
                if (localStorage.getItem("redirect_path")) {
                    localStorage.removeItem("redirect_path")
                }
                History.push("/")
            }
        }, (err) => {
            //console.log(err)
        })

    }

    return (
        <>
            {context ?
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Quality Car Auctions | Vehicle Management Section</title>
                    </Helmet>
                    <header>
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <Link to='/' className="logo">
                                        <img src="/images/logo-black.png" alt="logo" />
                                    </Link>
                                </div>
                                <div className="col text-end">
                                    <div className="hstack gap-2">
                                        <a className="ms-auto position-relative text-reset" aria-current="page" href="#">
                                            {context && context.role !== 'admin' ? <>
                                                <svg className="bi bi-3x opacity-65" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    <use xlinkHref="#bi_bell" />
                                                </svg>

                                                <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger rounded-circle">
                                                </span>
                                            </> : ''}

                                        </a>
                                        <div className="vr mx-2 mx-sm-3 opacity-15"></div>
                                        <div className="dropdown">
                                            <button className="dropdown-profile dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-offset="0,11">
                                                <svg className="bi">
                                                    <use xlinkHref="#bi_usercircle" />
                                                </svg>
                                                <span>{context ? context.name : 'Username'}</span>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end shadow border-0 p-2">
                                                <li><NavLink to='/profile' className="dropdown-item rounded">Profile</NavLink></li>
                                                <li><NavLink to='/change-password' className="dropdown-item rounded">Change Password</NavLink></li>
                                                {
                                                    context.role === 'admin' ? (
                                                        <li><NavLink to='/setting' className="dropdown-item rounded">Settings</NavLink></li>
                                                    ) : ''
                                                }

                                                <li>
                                                    <hr className="dropdown-divider opacity-10" />
                                                </li>
                                                <li>
                                                    <button className="dropdown-item rounded" onClick={handleLogout}>Logout</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="nav">
                                            <li className="nav-item">
                                                <NavLink to="/dashboard" className="nav-link" title="Dashboard">
                                                    <svg className="bi">
                                                        <use xlinkHref="#bi_home" />
                                                    </svg>
                                                    Dashboard
                                                </NavLink>
                                            </li>
                                            {
                                                    context.role === 'buyer' ?
                                                    <li className="nav-item">
                                                        <NavLink to='/total-bids' className="nav-link" title="Total Bids">
                                                            <svg className="bi">
                                                                <use xlinkHref="#bi_vat" />
                                                            </svg>
                                                            Total Bids
                                                        </NavLink>
                                                    </li> : ''
                                            }

                                            {
                                                context.role === 'seller_private' || context.role === 'seller_corporate' ?
                                                    <li className="nav-item">
                                                        <NavLink to="/vehicle-list" className="nav-link" title="Vehicle">
                                                            <svg className="bi">
                                                                <use xlinkHref="#bi_car" />
                                                            </svg>
                                                            Vehicle
                                                        </NavLink>
                                                    </li> :
                                                    context.role === 'admin' ?
                                                        <li className="nav-item dropdown">
                                                            <a className="nav-link dropdown-toggle" title="Vehicle" href="#" data-bs-toggle="dropdown">
                                                                <svg className="bi bi-2x">
                                                                    <use xlinkHref="#bi_car" />
                                                                </svg>
                                                                Vehicle
                                                            </a>
                                                            <ul className="dropdown-menu shadow border-0 p-2">
                                                                <li><NavLink to='/vehicle-list' className="dropdown-item rounded" title="Vehicle list">Vehicle List</NavLink></li>
                                                                <li><NavLink to='/vehicle-setting' className="dropdown-item rounded" title="Vehicle settting">Vehicle Setting</NavLink></li>
                                                            </ul>
                                                        </li> : ''
                                            }
                                                        <li className="nav-item">
                                                            <NavLink to="/auction-list" className="nav-link" title="Auctions">
                                                                <svg className="bi">
                                                                    <use xlinkHref="#bi_gavel" />
                                                                </svg>
                                                                Auctions
                                                            </NavLink>
                                                        </li>
                                            {
                                                context.role === 'admin' ? (
                                                    <>
                                                        <li className="nav-item">
                                                            <NavLink to="/user" className="nav-link" title="Users">
                                                                <svg className="bi">
                                                                    <use xlinkHref="#bi_usercircle" />
                                                                </svg>
                                                                Users
                                                            </NavLink>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink to="/invoice-list" className="nav-link" title="Support">
                                                                <svg className="bi">
                                                                    <use xlinkHref="#bi_file" />
                                                                </svg>
                                                                Invoices
                                                            </NavLink>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink to="/contact-us" className="nav-link" title="Support">
                                                                <svg className="bi">
                                                                    <use xlinkHref="#bi_headset" />
                                                                </svg>
                                                                Support
                                                            </NavLink>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink to="/setting" className="nav-link" title="Setting">
                                                                <svg className="bi">
                                                                    <use xlinkHref="#bi_tools" />
                                                                </svg>
                                                                Setting
                                                            </NavLink>
                                                        </li>
                                                    </>
                                                ) : ''
                                            }


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </header>

                    {/* Show Notes */}
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title text-blue" id="exampleModalLabel">Note From Admin</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <span>{note ? note : ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-mobile">
                        <NavLink to={"/dashboard"} className="footer-link">
                            <svg className="bi">
                                <use href="#bi_home"></use>
                            </svg>
                            <span>Dashboard</span>
                        </NavLink>
                        {
                            context.role !== 'admin' ? (
                                <NavLink to={"/user"} className="footer-link">
                                    <svg className="bi">
                                        <use href="#bi_usercircle"></use>
                                    </svg>
                                    <span>User</span>
                                </NavLink>
                            ) : ''
                        }

                        <NavLink to={"/vehicle"} className="footer-link">
                            <svg className="bi">
                                <use href="#bi_car"></use>
                            </svg>
                            <span>Vehicle</span>
                        </NavLink>
                    </div>
                    <Switch>
                        <Route {...component} />
                    </Switch>
                    <footer className="admin-footer">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-7">
                                    &copy; {new Date().getFullYear()} - Quality Car Action - All rights reserved.
                                </div>
                                <div className="col-5 text-end">
                                    v1.0.0
                                </div>
                            </div>
                        </div>
                    </footer>

                    <div className="position-fixed top-0 end-0 p-2 toast-end pointer-none" id="toastContainer" style={{ zIndex: '1111' }}></div>
                </> : ''
            }
        </>
    )
}

export default FrontendLayout
export { BackendLayout }
