import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { scrollbarSetToTop } from "../components/Helper";

function ScrapVehicle(){

	useEffect(() => {
        scrollbarSetToTop()
    }, []);

    return (
    <>
	<Helmet>
		<meta charSet="utf-8" />
		<title>Quality Car Auctions | Scrap A Vehicle</title>
	</Helmet>
    <section className="bg-light py-5">
		<div className="container py-sm-2">
			<div className="row justify-content-center text-center">
				<div className="col-sm-12 col-md-10 col-xl-8">
					<h2 className="h1 fw-bold w-75 mx-auto">
						Scrap A Vehicle
					</h2>
					<nav className="d-flex justify-content-center" style={{'--bs-breadcrumb-divider': '&#10140;'}} aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
							<li className="breadcrumb-item active" aria-current="page">Scrap A Vehicle</li>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	</section>

	<section className="bg-white py-5">
		<div className="container py-sm-5">
			<div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4 g-5">
				<div className="col">
					<div className="d-flex align-items-start">
						<div className="ratio ratio-1x1 mmw-60px border rounded-8">
							<div className="d-flex justify-content-center align-items-center">
								<svg className="bi bi-9x text-primary">
									<use xlinkHref="#bi_stopwatch" />
								</svg>
							</div>
						</div>
						<div className="ps-3">
							<h4>Easy</h4>
							<hr className="my-2 w-40px"/>
							<p className="opacity-80">
								It takes under 30 seconds to get a quote for your scrap car or vehicle.
							</p>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="d-flex align-items-start">
						<div className="ratio ratio-1x1 mmw-60px border rounded-8">
							<div className="d-flex justify-content-center align-items-center">
								<svg className="bi bi-9x text-primary">
									<use xlinkHref="#bi_hammer" />
								</svg>
							</div>
						</div>
						<div className="ps-3">
							<h4>Quick</h4>
							<hr className="my-2 w-40px"/>
							<p className="opacity-80">
								Our Scrap Car Collection network lets us pick up your vehicle within hours in most cases.
							</p>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="d-flex align-items-start">
						<div className="ratio ratio-1x1 mmw-60px border rounded-8">
							<div className="d-flex justify-content-center align-items-center">
								<svg className="bi bi-9x text-primary">
									<use xlinkHref="#bi_thumbsup" />
								</svg>
							</div>
						</div>
						<div className="ps-3">
							<h4>Fair</h4>
							<hr className="my-2 w-40px"/>
							<p className="opacity-80">
								Our Scrap Car Collection network also lets us offer you the best price possible for your scrap vehicle.
							</p>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="d-flex align-items-start">
						<div className="ratio ratio-1x1 mmw-60px border rounded-8">
							<div className="d-flex justify-content-center align-items-center">
								<svg className="bi bi-9x text-primary">
									<use xlinkHref="#bi_shieldlock" />
								</svg>
							</div>
						</div>
						<div className="ps-3">
							<h4>Safe</h4>
							<hr className="my-2 w-40px"/>
							<p className="opacity-80">
								Our experience and industry leading practises protect you from start to end.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    </>
    )
}

export default ScrapVehicle