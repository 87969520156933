import { useContext, useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import GlobalContext from "./GlobalContext"
import { fetchData, showAlert } from "./Helper"


export const ShowBid = (props) => {
    
    return (
        <div className="bg-black p-3 text-center">
            <h4 className="m-0">
                {props.auctionOpen ? 'Current' : 'Closing'} Bid: 
                <div className="text-primary m-0 fw-medium" style={{ 'font-size': '35px' }}>
                    PKR {props.showBidAmount ? props.showBidAmount : '0'}
                </div>
            </h4>
        </div>
    )
}

export const WithoutLogin = (props) => {
    
    return (
        <div className="col text-center mb-3">
            <Link to='/login' className="btn btn-primary  px-5 m-4 fs-5 fw-bold">
                Login to place a bid
            </Link>
        </div>
    )
}

export const AuctionEnded = () => {
    
    return (
        <div className="alert alert-danger h2 d-flex align-items-center fw-medium" role="alert">
            <svg className="bi bi-3x me-3">
                <use xlinkHref="#bi_gavel" />
            </svg>
            <div className="d-block">This auction has ended</div>
        </div>
    )
}

export const BidPlace = (props) => {
    const [context] = useContext(GlobalContext)
    const [history] = useState(useHistory())
    const [vehicleId] = useState(useParams().id)
    const [showBidAmount, setShowBidAmount] = useState(0)
    const [auctionOpen, setAuctionOpen] = useState(false)
    const [bid, setBid] = useState({vehicle_id: vehicleId, auction_id: history.location.state})

    useEffect(() => {
        if(context && props.sellerId){
            setBid(prevState => ({...prevState, 'buyer_id': context.id, 'seller_id': props.sellerId}))
            var alert = false
            function getBidDetails() {
                if(history.location.state){
                    fetchData(`current-auction-details/${vehicleId}/${history.location.state}`, 'GET', '', true, false, (res) => {
                        if(res.data.second_winner && res.data.second_winner === context.id){
                            if(!alert){
                                showAlert({message: res.data.message}, false)
                                alert = true
                            }
                        }else{
                            if(alert) alert = false
                        }
                        setAuctionOpen(res.data.status)
                        setShowBidAmount(res.data.show_current_bid)
                        setBid(prevState => ({...prevState, 'bid_amount': res.data.op_current_bid}))
                    }, (err) => {}, true, false);
                }
            }

            getBidDetails()
            var loop = setInterval(() => getBidDetails(), 10000);
            history.listen(() => {
                let toastContainer = document.getElementById('toastContainer')
                if(toastContainer) toastContainer.innerHTML = ''
                clearInterval(loop)
            })
        }
    }, [context, vehicleId, history, props.sellerId]);


    const handleInputChange = (e) => {
        let value = e.target.value
        if(value.match(/[^0-9]/g)){
            document.getElementById('max_bid').value = value.substr(0, value.length -1)
            showAlert({message: 'Please enter only integer value.'}, false)
        }else{
            setBid(prevState => ({...prevState, [e.target.name]: value}))
        }
    }

    const handleSubmitForm = (e, type) => {
        e.preventDefault();
        var reset = document.getElementById('max_bid')
        if(type === 'place_max_bid' && ! reset.value){
            showAlert({message: 'Please enter maximum bid amount.'}, false)
            return
        }
        if ((type === 'place_max_bid' && bid.max_bid > 0) || (type === 'place_bid' && bid.bid_amount > 0)) {
            fetchData(`auction/place-bids?${type}`, 'POST', bid, true, false, (res) => {
                if (res.status) {
                    setShowBidAmount(res.data.show_current_bid)
                    setBid(prevState => ({...prevState, 'bid_amount': res.data.op_current_bid}))
                    reset.value = ''
                }
            }, (err) => {}, false)
        }
    }
    
    return (
        <>
            <div className="bg-dark text-white bg-gradient rounded overflow-hidden">
                <ShowBid auctionOpen={auctionOpen} showBidAmount={showBidAmount} />
                <div className="p-3 p-sm-4">
                    {
                        !context ? <WithoutLogin /> :
                            context.role === 'seller_private' || context.role === 'seller_corporate' ? '' :
                                !auctionOpen ? <AuctionEnded /> :
                                <>
                                    <div className="col text-center mb-4">
                                        <button className="btn btn-primary px-4 fs-4" type="submit" onClick={(e) => handleSubmitForm(e, 'place_bid')}>
                                            Place Bid <p className="m-0 h1 fw-medium">PKR {bid.bid_amount ? (bid.bid_amount) : '0'}</p>
                                        </button>
                                    </div>
                                    <div className="alert alert-light">
                                        <div className="input-group">
                                            <span className="input-group-text min-w38px justify-content-center">PKR</span>
                                            <input type="text" className="form-control" name="max_bid" id="max_bid" autoComplete="off" onChange={handleInputChange} required />
                                            <button className="btn btn-primary" type="submit" onClick={(e) => handleSubmitForm(e, 'place_max_bid')}>
                                                Place Maximum Bid
                                            </button>
                                        </div>
                                    </div>
                                </>
                    }
                    {props.children}
                </div>
            </div>
        </>
    )
}