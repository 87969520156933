import { useEffect, useState } from "react";
import { fetchData, checkFile, scrollbarSetToTop } from '../components/Helper'
import DatePicker from "react-datepicker";
import bootstrap from 'bootstrap/dist/js/bootstrap';

function Register() {
    const [usertype, setUserType] = useState(
        localStorage.getItem("redirect_path") === 'vehicle-list/sell-a-vehicle' ? 'seller' : 'buyer')
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        business_name: "",
        phone: "",
        email: "",
        password: "",
        confirm_password: "",
        address: "",
        nic_card_number: "",
        business_person_role: "",
        estimated_vehicles_per_week: "",
        terms: "",
        nic_card_front_image: "",
        nic_card_back_image: "",
    });
    console.log(data.terms)
    const [payment_date, setPayment_date] = useState(new Date());
    const current = new Date()

    const [frontImage, setFrontImage] = useState('');
    const handleFrontImageChange = (e) => {
         document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')

        setFrontImage(checkFile(e))
    }

    const [backImage, setBackImage] = useState('');
    const handleBackImageChange = (e) => {
         document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')

        setBackImage(checkFile(e))
    }

    const handleInputChange = (e) => {
         document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));

        let isChecked = e.target.checked;
        if(isChecked == true){
            new bootstrap.Modal(document.getElementById('termsModal')).show();            
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        var reset = document.getElementById('registerForm')
        
        const formData = new FormData();
        formData.append('role', usertype)
        formData.append('address', data.address)
        formData.append('confirm_password', data.confirm_password)
        formData.append('email', data.email)
        formData.append('first_name', data.first_name)
        formData.append('last_name', data.last_name)
        formData.append('password', data.password)
        formData.append('phone', data.phone)
        formData.append('terms', data.terms)
        

        if(usertype === 'seller_corporate') {
            formData.append('business_name', data.business_name)
            formData.append('business_person_role', data.business_person_role)
            formData.append('estimated_vehicles_per_week', data.estimated_vehicles_per_week)
        }else{
            formData.append('nic_card_number', data.nic_card_number)
            formData.append('nic_card_front_image', frontImage)
            formData.append('nic_card_back_image', backImage)
        }

        if(usertype === 'buyer') {
            formData.append('payment_date', payment_date.toISOString())
        }

        fetchData('register', 'POST', formData, false, true, (res) => {
            if(res.status) {
                setData(prevState => ({
                    first_name: "",
                    last_name: "",
                    business_name: "",
                    phone: "",
                    email: "",
                    password: "",
                    confirm_password: "",
                    address: "",
                    nic_card_number: "",
                    business_person_role: "",
                    estimated_vehicles_per_week: "",
                    terms: "",
                    nic_card_front_image: "",
                    nic_card_back_image: "",
                    payment_reference: "",
                }))
                reset.reset()
            }
        }, (err) => {
            console.log(err)
        })
    };

    useEffect(() => {
        scrollbarSetToTop()
    }, []);


    
    return (
        <>
            <section className="py-5">
                <div className="container py-md-5 mb-5">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-lg-8">
                            <div className="p-3 p-sm-5 bg-light" autoComplete="off">
                            { localStorage.getItem("redirect_path") === 'vehicle-list/sell-a-vehicle' ?
                                <h4 className="lh-1"> Register as a seller </h4>
                                :
                                <>
                                <h4 className="lh-1"> Register </h4>
                                <hr className="mt-3 mb-4 w-40px text-muted" />
                                </>
                            }
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                               { localStorage.getItem("redirect_path") === 'vehicle-list/sell-a-vehicle' ?
                                    ''
                                    :
                                    <>
                                    <li className="nav-item me-2" role="presentation">
                                        <button className={ usertype === 'buyer' ? "nav-link fw-medium active" : "nav-link fw-medium"}  onClick={()=>{setUserType('buyer')}}  type="button">
                                            <span className="d-inline-block rounded-circle form-check-input me-1"></span>
                                            Buyer
                                        </button>
                                    </li> 
                                    <li className="nav-item" role="presentation">
                                        <button className={ usertype === 'seller_private' || usertype === 'seller_corporate' ? "nav-link fw-medium active" : "nav-link fw-medium"} onClick={()=>{setUserType('seller_private')}}  type="button">
                                            <span className="d-inline-block rounded-circle form-check-input me-1"></span>
                                            Seller
                                        </button>
                                    </li>
                                    </>
                                }
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="buyerbox" role="tabpanel">
                                        <form className="row g-3" id="registerForm">
                                            <div className="col-12">
                                                <hr className="text-muted mt-2 mb-1" />
                                            </div>
                                            {
                                                usertype != 'buyer' ? 
                                                <>
                                                <div className="col-md-12" >
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" name="role" type="radio" defaultChecked  onClick={()=>{setUserType('seller_private')}} id="seller_private"  />
                                                        <label className="form-check-label" htmlFor="seller_private">Private</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" name="role" type="radio"  onClick={()=>{setUserType('seller_corporate')}} id="seller_corporate"  />
                                                        <label className="form-check-label" htmlFor="seller_corporate">Corporate</label>
                                                    </div>
                                                    <div className="error role-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                </>
                                                : ''
                                            }

                                            <div className="col-md-6">
                                                <label htmlFor="first_name" className="form-label text-muted mb-1">First Name</label>
                                                <input type="text" name="first_name" onChange={handleInputChange} className="form-control" id="first_name" />
                                                <div className="error first_name-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="last_name" className="form-label text-muted mb-1">Last Name</label>
                                                <input type="text" name="last_name" onChange={handleInputChange} className="form-control" id="last_name" />
                                                <div className="error last_name-error small text-danger px-2 py-1 d-none"></div>
                                            </div>

                                            {
                                                usertype === 'seller_corporate' ? (
                                                <div className="col-md-12">
                                                    <label htmlFor="business_name" className="form-label text-muted mb-1">Business Name</label>
                                                    <input type="text" name="business_name" onChange={handleInputChange} className="form-control" id="business_name" />
                                                    <div className="error business_name-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                ) : ''
                                            }

                                            <div className="col-md-6">
                                                <label htmlFor="phone" className="form-label text-muted mb-1">Phone Number</label>
                                                <input type="tel" name="phone" onChange={handleInputChange} className="form-control" id="phone" />
                                                <div className="error phone-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email" className="form-label text-muted mb-1">{ usertype == 'seller_corporate' ? "Business Email" : "Email" }</label>
                                                <input type="email" name="email" onChange={handleInputChange} className="form-control" id="email" />
                                                <div className="error email-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="password" className="form-label text-muted mb-1">Password</label>
                                                <input type="password" name="password" onChange={handleInputChange} className="form-control" id="password" />
                                                <div className="error password-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="confirm_password" className="form-label text-muted mb-1">Confirm Password</label>
                                                <input type="password" name="confirm_password" onChange={handleInputChange} className="form-control" id="confirm_password" />
                                                <div className="error confirm_password-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="address" className="form-label text-muted mb-1">{ usertype === 'seller_corporate' ? "Business Address" : "Address"}</label>
                                                <textarea className="form-control" onChange={handleInputChange} name="address" id="address"></textarea>
                                                <div className="error address-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            {
                                                usertype != 'seller_corporate' ?
                                                <>
                                                <div className="col-md-12">
                                                    <label htmlFor="nic_card_number" className="form-label text-muted mb-1">NIC Card number</label>
                                                    <input type="tel" name="nic_card_number" onChange={handleInputChange} className="form-control" id="nic_card_number" />
                                                    <div className="error nic_card_number-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nic_card_front_image" className="form-label text-muted mb-1">NIC Card Front Picture </label>
                                                    <input type="file" name="nic_card_front_image" onChange={handleFrontImageChange} className="form-control" id="nic_card_front_image" />
                                                    <div className="form-text fs-12px">Everything in the scanned ID card should be readable.</div>
                                                    <div className="error nic_card_front_image-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nic_card_back_image" className="form-label text-muted mb-1">NIC Card Back Picture</label>
                                                    <input type="file" name="nic_card_back_image" onChange={handleBackImageChange} className="form-control" id="nic_card_back_image" />
                                                    <div className="form-text fs-12px">Everything in the scanned ID card should be readable.</div>
                                                    <div className="error nic_card_back_image-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                </>
                                                : ''
                                            }
                                            {
                                                usertype === 'buyer' ? 
                                                <>
                                                <div className="col-12">
                                                    <hr className="text-muted mt-2 mb-1" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="payment_date" className="form-label text-muted mb-1">Payment Deadline Date</label>
                                                    <DatePicker 
                                                        selected={payment_date.setDate(current.getDate()+7)} 
                                                        onChange={(date) => setPayment_date(date)} 
                                                        className="form-control"
                                                        dateFormat="MMM dd, yyyy"
                                                        disabled
                                                        onChangeRaw={(e) => e.preventDefault()}
                                                    />
                                                    <div className="form-text fs-12px">Details on how to make a payment will be emailed to you shortly</div>
                                                    <div className="error payment_date-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="amount" className="form-label text-muted mb-1">Amount</label>
                                                    <input type="tel" name="amount" className="form-control" defaultValue="PKR 50,000/-" id="amount" readOnly/>
                                                </div>
                                                </>
                                                : ''
                                            }
                                            {
                                                usertype === 'seller_corporate' ? (
                                                    <>
                                                    <div className="col-md-6">
                                                <label htmlFor="business_person_role" className="form-label text-muted mb-1">Person’s Role in the Company</label>
                                                <input className="form-control" onChange={handleInputChange} name="business_person_role" id="business_person_role" />
                                                <div className="error business_person_role-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="estimated_vehicles_per_week" className="form-label text-muted mb-1">Estimated vehicles per week.</label>
                                                <input type="text" name="estimated_vehicles_per_week" onChange={handleInputChange} className="form-control" id="estimated_vehicles_per_week" />
                                                <div className="error estimated_vehicles_per_week-error small text-danger px-2 py-1 d-none"></div>
                                            </div>
                                                    </>
                                                ) : ''
                                            }
                                            <div className="col-md-12" >
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" onClick={handleInputChange} name="terms" type="checkbox" id="terms" value="1" />
                                                    <label className="form-check-label" htmlFor="terms">Terms & Conditions</label>
                                                    <div className="error terms-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary fw-medium px-4" onClick={handleSubmitForm}>Register &#10140; </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="modal fade bd-example-modal-lg" tabIndex="-1" id="termsModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">Terms & Conditions</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        { usertype == 'buyer'? 
                        <ul>
                            <li>Upon winning a vehicle bid <span className="fw-bold">Buyer</span> is in a legal agreement with Q Car auctions to pay the full amount equals to the bid placed in addition to 2% auction fee</li>
                            <li>Upon winning a vehicle bid <span className="fw-bold">Buyer</span> is liable to pay the make the full payment within 72 hours of bid been accepted.</li>
                            <li>Payment needs to be paid via bank transfer in Bank account number shown on the invoice sent to buyer via email</li>
                            <li><span className="fw-bold">Buyer</span> will need to pay 2% auction fee on top of the winning bid price</li>
                            <li><span className="fw-bold">Buyer</span> will need to pay additional delivery cost if he opts to get the purchased vehicle delivered to his site</li>
                            <li>Delivery cost will differ as per individual request, and will be provided by Q Car Auction offices</li>
                            <li><span className="fw-bold">Buyer</span> will need to pay a fully refundable deposit of PKR 50,000 to complete the registration process and to be able to place bids on a vehicle</li>
                            <li><span className="fw-bold">Buyer</span> will be refunded the deposit if membership to Q car Auction is terminated by either Q Car Auction or buyer themselves.</li>
                            <li><span className="fw-bold">Buyer</span> will lose complete deposit of PKR 50,000 if he fails to make a full invoiced payment of purchased vehicles through the auction within 72 hours of auction end time</li>
                            <li><span className="fw-bold">Buyer</span> will need to remove the vehicle from Q Car Auction site within 5 working days, from the end of the auction</li>
                            <li><span className="fw-bold">Buyer</span> can start a claim based on vehicle mechanical and condition report within 5 working days of the end of the auction</li>
                            <li>All payments will need to go through bank transfer where the <span className="fw-bold">Buyer's</span> bank account is required to have same Account name as registered with Q Car Auction</li>
                            <li>Payments can be made in cash on one selected Q Car Auction site on special request</li>
                        </ul>
                        : 
                        <ul>
                            <li><span className="fw-bold">Sellers</span> need to pay no fee and no deposit if he is willing to drop the vehicle to one of the Q Car Auction sites</li>
                            <li>On dropping the vehicle to Q Car Auction site, seller is required to drop original vehicle registration document along with all the accessories of the vehicle (All keys, service Book, Wheel nut key, etc)</li>
                            <li>A private seller will need to show proof of owner ship of the vehicle (For example vehicle registration document with his own name matching with name on his NIC card)</li>
                            <li>All <span className="fw-bold">Sellers</span> if not the registered keeper on vehicle registration document will need to an authority letter from the registered person along with NIC copies of both registered owner of the vehicle and the seller themselves. </li>
                            <li><span className="fw-bold">Seller</span> can list a vehicle for Auction from their Own site with a refundable deposit of PKR 50,000 via bank transfer to Q Car Auction Bank account</li>
                            <li>If Vehicle is auctioned from <span className="fw-bold">Seller’s</span> site, the deposit will be refunded within 2-3 weeks after sale of the vehicle via bank transfer</li>
                            <li><span className="fw-bold">Seller</span> will be paid the full payment of winning bid within 2 weeks of auction end</li>
                            <li>If Vehicle is auctioned from <span className="fw-bold">Seller’s</span> site, <span className="fw-bold">Seller</span> will lose the refundable deposit of PKR 50,000 any information in vehicle Mechanical and condition report proves to be inaccurate</li>
                            <li>All payments to the Seller will be via Bank transfer</li>
                            <li><span className="fw-bold">Seller’s</span> bank account is required to have same of the seller as used when registering with Q Car Auction</li>
                            <li><span className="fw-bold">Seller</span> will not raise any disputes on the vehicle's interior, exterior or mechanical condition under all four circumstances (Vehicle does not get sold, Vehicle is returned to the seller, vehicle is sold but returned due to other reasons, vehicle was sold however sale was not completed due to other reasons)</li>
                        </ul>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register




