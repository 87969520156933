import { Link, useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react';
import { fetchData, scrollbarSetToTop } from '../components/Helper'
import GlobalContext from '../components/GlobalContext';

function Login() {
    const history = useHistory();
    const [context, setContext] = useContext(GlobalContext)
    const [credentials, setCredentials] = useState({email: "", password: ""});
    const [role, setRole] = useState('admin');

    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        
        setCredentials(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    useEffect(() => {
        scrollbarSetToTop()
        if (context){
            if(localStorage.getItem("redirect_path") === 'vehicle-list/sell-a-vehicle' && context.role == 'buyer'){
                history.push('/register')
            }else{
                if(localStorage.getItem("redirect_path")){
                    let redirect = localStorage.getItem("redirect_path")
                    localStorage.removeItem("redirect_path")
                    history.push(`/${redirect}`)
                }else{
                    history.push('/dashboard')
                }
            }
        }
    }, [context]);

    const submitLoginForm = () => {
        let data = {email: credentials.email, password: credentials.password, role: role}
        fetchData('login', 'POST', data, false, false, (res) => {
            if(res.status && res.data){
                localStorage.setItem("ma-token", res.data.token);
                setContext(res.data)
            }
        }, (err) => {
            //console.log(err)
        })
    }

    return (
        <>
            <section className="py-5">
                <div className="container py-md-5">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-10">
                            <div className="row mx-0">
                                <div className="col-md-6 col-lg-5 order-md-2 bg-light ">
                                    <form className="py-3 p-lg-5" autoComplete="off">
                                        <h4 className="lh-1">Login</h4>
                                        <hr className="mt-3 mb-4 w-40px text-muted"/>
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" type="radio" id='admin' defaultChecked onClick={() => setRole('admin')} />
                                                <label className="form-check-label" htmlFor="admin">Admin</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" type="radio" id='buyer' onClick={() => setRole('buyer')} />
                                                <label className="form-check-label" htmlFor="buyer">Buyer</label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" type="radio" id='seller_private' onClick={() => setRole('seller_private')} />
                                                <label className="form-check-label" htmlFor="seller_private">Private Seller</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="role" id='seller_corporate' type="radio" onClick={() => setRole('seller_corporate')} />
                                                <label className="form-check-label" htmlFor="seller_corporate">Corporate Seller</label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" name="email" className="form-control" placeholder="Email" onChange={handleInputChange}/>
                                            <div className="error email-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div className="mb-3">
                                            <input type="password" name="password" className="form-control" placeholder="Password" onChange={handleInputChange}/>
                                            <div className="error password-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div className="d-flex small justify-content-between align-items-center">
                                            <button type="button" className="btn btn-primary fw-medium px-4" onClick={submitLoginForm}>Login &#10140;</button>
                                            <Link to={"/forgot-password"} className="fw-medium">
                                                Forgot Password?
                                            </Link>
                                        </div>
                                        <div className="small text-muted py-3 mt-4">
                                            Don't have an account? <Link to={"/register"} className="fw-medium">Register</Link>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6 col-lg-7">
                                    <img src="/images/login.svg" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login