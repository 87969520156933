import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { fetchData } from "../components/Helper"
import GlobalContext from "../components/GlobalContext";

function Profile(){
    const [context, setContext] = useContext(GlobalContext)
    const [activeForm, setActiveForm] = useState(false)
    const [user, setUser] = useState();
    const [role, setRole] = useState();
    
    useEffect(() => {
        fetchData(`user/edit/${context.id}`, 'GET', '', true, false, (res) => {
            setUser(res.data[0])
            setRole(res.data[1].role)
	    }, (err) => {
		console.log(err)
        })
    },[])
    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        setUser(prevState => ({
            ...prevState,
            'role' : role,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmitForm = () => {
        fetchData(`user/edit/${context.id}`, 'POST', user, true, false, (res) => {
            if(res.status){
                setActiveForm(false)
            }
	    }, (err) => {
		console.log(err)
        })
    }

    return (
    <>
    <div className="content-wrapper">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-sm-6 py-3">
                    <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                        <svg className="bi bi-1x me-2">
                            <use xlinkHref="#bi_peoplecircle"></use>
                        </svg>My Profile
                    </h6>
                </div>
                {
                    activeForm ? '' : (
                        <div className="col-sm-6 py-3 text-sm-end">
                            <button type="button" className="btn btn-primary hideon-active" onClick={() => setActiveForm(true)} title="Profile Edit">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_edit" />
                                </svg>
                                Edit
                            </button>
                        </div>
                    )
                }
                
            </div>
            {
                user ? (
                        <div className="row justify-content-center m-0 g-3" style={{'border': '1px solid #e9e9ef'}}>
                        <div className="col-sm-12 col-md-8 col-xl-7 col-xxl-5">
                            <div className={activeForm ? "row justify-content-center g-4" : "row justify-content-center g-4 my-profile"} id="profileForm">
                                <div className="col-sm-12">
                                    <label className="ratio ratio-1x1 overflow-hidden rounded-10 mx-auto d-block" style={{'maxWidth': '120px'}}>
                                        <input type="file"/>
                                        <img src="/images/user3.jpg" alt="User Avatar" />
                                    </label>
                                </div>
                                {
                                    activeForm ? (
                                        <>
                                        <div className="col-sm-6">
                                            <label htmlFor="firstname" className="form-label">First Name</label>
                                            <input type="text" className="form-control" placeholder="" name="first_name" defaultValue={user.first_name} id="firstname" onChange={handleInputChange}/>
                                            <div className="error first_name-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="lastname" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" placeholder="" name="last_name" defaultValue={user.last_name} id="lastname" onChange={handleInputChange}/>
                                            <div className="error last_name-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        </>
                                    ) : (
                                    <div className="col-sm-12">
                                        <label htmlFor="fullname" className="form-label">Full Name</label>
                                        <input type="text" className="form-control" placeholder="" name="fullname" defaultValue={user.first_name + ' ' + user.last_name} id="fullname" onChange={handleInputChange}/>
                                    </div>
                                    )
                                }
                                <div className="col-sm-6">
                                    <label htmlFor="mobilenumber" className="form-label">Mobile Number</label>
                                    <input type="text" className="form-control" placeholder="" name="phone" defaultValue={user.phone} id="mobilenumber" onChange={handleInputChange}/>
                                    <div className="error phone-error small text-danger px-2 py-1 d-none"></div>
                                </div>
                                {
                                    role === 'seller_corporate' ? (
                                        <>
                                        <div className="col-sm-6">
                                            <label htmlFor="estimated" className="form-label">Estimated Vehicles Per Week</label>
                                            <input type="text" className="form-control" placeholder="" name="estimated_vehicles_per_week" defaultValue={user.estimated_vehicles_per_week} id="estimated" onChange={handleInputChange}/>
                                            <div className="error estimated_vehicles_per_week-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="businessname" className="form-label">Business Name</label>
                                            <input type="text" className="form-control" placeholder="" name="business_name" defaultValue={user.business_name} id="businessname" onChange={handleInputChange}/>
                                            <div className="error business_name-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        </>
                                    ) : (
                                        <div className="col-sm-6">
                                            <label htmlFor="nicnumber" className="form-label">NIC Number</label>
                                            <input type="text" className="form-control" placeholder="" name="nic_number" readOnly defaultValue={user.nic_number} id="nicnumber" onChange={handleInputChange}/>
                                        </div>
                                    )
                                }
                                <div className="col-sm-12">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <textarea type="text" className="form-control" placeholder="" id="address" rows="2" name="address" defaultValue={user.address} onChange={handleInputChange}></textarea>
                                    <div className="error address-error small text-danger px-2 py-1 d-none"></div>
                                </div>
                                {
                                    activeForm ? (
                                        <div className="col-sm-12 mb-4">
                                            <button type="button" className="btn btn-primary px-4" onClick={handleSubmitForm}>Update</button>
                                        </div>
                                    ) : ''
                                }
                                
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </div>
    </div>
    </>
    )
}

export default Profile