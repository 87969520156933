import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { fetchData, scrollbarSetToTop } from "../components/Helper"

function ResetPassword() {
    const token = useParams();
    const history = useHistory()
    const [password, setPassword] = useState({
        password:'',
        confirm_password:'',
        token :token.token,
        email:token.email,
        role:token.role,
    })

    const handleInputChange = (e)=>{
        setPassword(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const resetPasswordSubmit = (e) =>{
        e.preventDefault();
        fetchData('reset-password', 'POST', password, false, false, (res) => {
            if(res.status) {
                setPassword({
                    password:'',
                    confirm_password:''
                });
                history.push('/login');
            }
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        scrollbarSetToTop()
    }, []);

    return (
        <>
            <section className="py-5">
                <div className="container py-md-5">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-10">
                            <div className="row mx-0">
                                <div className="col-md-6 col-lg-5 order-md-2 bg-light ">
                                    <form className="py-3 p-lg-5" autocomplete="off">
                                        <h4 className="lh-1">Reset Password</h4>
                                        <hr className="mt-3 mb-4 w-40px text-muted" />
                                        <div className="mb-3">
                                            <input type="password" className="form-control" name ="password" placeholder="Enter your New Password" onChange={handleInputChange} />
                                        </div>
                                        <div className="mb-3">
                                            <input type="password" className="form-control" name ="confirm_password" placeholder="Re-Enter your New Password" onChange={handleInputChange}/>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <button type="submit" onClick={resetPasswordSubmit} className="btn btn-primary fw-medium px-4">Reset Password &#10140;</button>
                                        </div>
                                        

                                        <div className="small text-muted py-3 mt-4">
                                            Don't have an account? <a href="register" className="fw-medium">Register</a>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6 col-lg-7">
                                    <img src="/images/login.svg" className="w-100" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword