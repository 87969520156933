import { useEffect } from "react"

function  GoogleMap(props) {

    useEffect(() => {
      const map = new window.google.maps.Map(document.getElementById("vehicle_map"), {
        center: { lat: 30.375321, lng: 69.345116 },
        zoom: 13,
        mapTypeId: "roadmap",
      });
      // Create the search box and link it to the UI element.
      const input = document.getElementById("location");
      const searchBox = new window.google.maps.places.SearchBox(input);
    
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });
    
      let markers = [];
    
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
    
        if (places.length == 0) {
          return;
        }
    
        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
    
        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();
    
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }
          console.log(place)
    
          const icon = {
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25),
          };
    
          // Create a marker for each place.
          markers.push(
            new window.google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
          // document.getElementById('latitudeInput').value = place.geometry.location.lat();
          // document.getElementById('longitudeInput').value = place.geometry.location.lng();

        });
        map.fitBounds(bounds);
      });
      
    }, [])

    return (
        <>
            <div id="vehicle_map"></div>
        </>
    )
}

export default GoogleMap