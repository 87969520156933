import { useContext, useEffect, useRef, useState } from 'react';
import DataTables from '../components/Datatable';
import { Link } from 'react-router-dom';
import GlobalContext from "../components/GlobalContext";

function TotalBids() {
    const dt_reload = useRef()
    const [context, setContext] = useContext(GlobalContext)

    const [dt] = useState({
                            dt_url: 'get-total-bids',
                            dt_name: 'total_bids',
                            dt_column: [
                                { "data":"id" , "title": "Bid ID"},
                                { "data":"auction_id" , "title": "Auction ID"},
                                { "data":"vehicle_id" , "title": "Vehicle ID"},
                                { "data":"seller.name" , "title": "Seller ID", "visible": context.role === 'admin' ? true: false },
                                { "data":"buyer.name", "title": "Buyer", "visible": context.role === 'admin' ? true: false },
                                { "data":"make", "name":"vehicles.make", "title":"Make" },
                                { "data":"model", "name":"vehicles.model", "title":"Model" },
                                { "data":"year", "name":"vehicles.year", "title":"Year" },
                                { "data":"bid_amount", "title":"Bid Amount" },
                                { "data":"created_at", "title":"Bid Date", "class":"text-start"},
                            ],
                            dt_paging: true,
                        })

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row py-2 align-items-center">
                        <div className="col-sm-6 py-1">
                            <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                                <svg className="bi me-2">
                                    <use xlinkHref="#bi_gavel"></use>
                                </svg>Total Bids
                            </h6>
                        </div>
                        <div className="col-sm-6 py-1 text-sm-end">
                            <Link className="btn btn-dark" to={"/dashboard"} role="button">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_arrowleft" />
                                </svg>
                                Go Back
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <DataTables dt_data={dt} ref={dt_reload}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TotalBids