import React, { useEffect, useState } from "react";
import bootstrap from 'bootstrap/dist/js/bootstrap';

function LightBox() {
    const [slides, setSlides] = useState({
        indx: 1,
        items: ""
    });
    const [slidesnumber, setSlidesnumber] = useState({
        current: 1,
        total: 0
    });

    useEffect(() => {
        let sl = [];
        document.getElementById('lightbox').addEventListener('show.bs.modal', function (event) {
            sl = [];
            [...document.querySelectorAll('[data-preview]')].map((e)=>{
                sl.push(e.dataset.preview)
            });

            const ind = Array.from(document.querySelectorAll('[data-preview]')).indexOf(document.querySelector('[data-preview="'+event.relatedTarget.dataset.preview+'"]'));

            setSlides(prevState => ({
                ...prevState,
                indx: ind,
                items: sl
            }));

            const totalslider = [...document.querySelectorAll('[data-preview]')].length;
            new bootstrap.Carousel(document.getElementById('carouselLightBox')).dispose();
            new bootstrap.Carousel(document.getElementById('carouselLightBox'));

            setSlidesnumber(prevState => ({
                ...prevState,
                current: ind+1,
                total: totalslider
            }));
            document.getElementById('carouselLightBox').addEventListener('slide.bs.carousel', function (e) {
                setSlidesnumber(prevState => ({
                    ...prevState,
                    current: e.to + 1
                }));
            });
        });

        document.getElementById('lightbox').addEventListener('hidden.bs.modal', function (event) {
            setSlides('');
            sl = [];
            setSlidesnumber({
                current: 1,
                total: 0
            })
        })

    }, []);


    return <>
        <div className="modal modal-full fade" id="lightbox" tabIndex="-1" data-bs-backdrop="true" data-bs-keyboard="false">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content rounded-0 overflow-hidden border-0 bg-transparent">
                    <div className="modal-header border-0 py-2">
                        &nbsp;
                        <button type="button" className="btn btn-lg btn-outline-danger position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close" title="Close">
                            &times;
                        </button>
                    </div>
                    <div className="moday-body d-flex align-items-center justify-content-center p-3 position-relative w-100" style={{minHeight: '60vh'}}>
                        <div id="carouselLightBox" className="carousel slide1 carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="false">
                            <div className="carousel-inner">
                                {
                                    slides.items ? slides.items.map((v,i)=>{
                                        return (
                                            <div className={`carousel-item ${i === slides.indx? ' active':''}`} key={i}>
                                                <img src={v} className="img-cover" style={{ 'height': 'inherit', 'width': 'inherit' }} alt="..." />
                                            </div>
                                        )
                                    })
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 justify-content-center bg-light1">
                        <button className="btn btn-light w-110px" type="button" data-bs-target="#carouselLightBox" data-bs-slide="prev">
                            <svg className="bi me-1">
                                <use xlinkHref="#bi_arrowleft" />
                            </svg>
                        </button>
                        <div className="text-center w-110px text-white fw-bold">{slidesnumber.current} of {slidesnumber.total}</div>
                        <button className="btn btn-light w-110px" type="button" data-bs-target="#carouselLightBox" data-bs-slide="next">
                            <svg className="bi me-1">
                                <use xlinkHref="#bi_arrowright" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default LightBox