import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { fetchData } from '../components/Helper'
import DashboardTile from '../components/DashboardTile'

function Dashboard() {
    const [data, setData] = useState('');
    let auction_id = data && data.data[0] ? data.data[0].auction_id : ''
    useEffect(() => {
        fetchData('statistics', 'GET', '', true, false, (res) => {
            if(res.status){
                setData(res)
            }
        }, (err) => {
            //console.log(err)
        })
    }, []);

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row py-2 align-items-center">
                    <div className="col-sm-6 py-1">
                        <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_home"></use>
                            </svg>Dashboard
                        </h6>
                    </div>
                    <div className="col-sm-6 py-1 text-sm-end">
                        <Link to="/auctions" role="button" className="btn btn-primary mx-3">
                            <svg className="bi me-1">
                                <use xlinkHref="#bi_gavel" />
                            </svg>
                            Vehicles
                        </Link>
                        <Link to="/" role="button" className="btn btn-primary">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_home" />
                            </svg>
                            Go to Home
                        </Link>
                    </div>
                </div>
                <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-4 g-3 g-sm-4">
                    {
                        data ? ( data.data.map((item, i) => {
                            return (
                                <DashboardTile statistics={item} id={auction_id} key={i}/>
                            )
                        })) : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Dashboard