import ReactDOM from 'react-dom';
import { fetchData } from '../components/Helper'
import { useEffect, useRef, useState } from 'react';
import DataTables from '../components/Datatable';
import { useParams } from "react-router-dom";

function User() {
    const [user_status] = useState(useParams().status)
    const dt_reload = useRef()
    const [dt] = useState({
                            dt_url: user_status ? 'users?s_filter='+user_status : 'users',
                            dt_name: 'user',
                            dt_column: [
                                { "data":"id", "title": "ID"},
                                { "data":"user_details.first_name", "name":"userDetails.first_name", "title":"First Name" },
                                { "data":"user_details.last_name", "name":"userDetails.last_name", "title":"Last Name" },
                                { "data":"user_details.business_name", "name":"userDetails.business_name", "title":"Business Name" },
                                { "data":"email", "title":"Email" },
                                { "data":"user_details.phone", "name":"userDetails.phone", "title":"Phone" },
                                { "data":"user_details.nic_number", "name":"userDetails.nic_number", "title":"NIC Number" },
                                { "data":"user_details.address", "name":"userDetails.address", "title":"Address" },
                                { "data":"role", "title":"User Type" },
                                { "data":"payment_date", "name":"payments.payment_date", "title":"Payment Deadline Date" },
                                { "data":"created_at", "title":"Created Date" },
                                { "data":"status", "title":"Status" },
                                { "data":"id", "title":"Action", searchable:false, sortable:false, class: 'text-end mmw-200px'}
                            ],
                            dt_column_defs: [
                                {
                                    targets: 12,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                            <button type="button" className="btn btn-xs btn-outline-secondary m-2px" title=" Add Note" data-bs-target="#showUserModal" data-bs-toggle="modal" data-bs-target="#addNoteModal" onClick={() => setNotes(rowData.id)}>
                                            <svg className="bi bi-2x">
                                               <use xlinkHref="#bi_addnote" />
                                            </svg>
                                            </button>
                                            <button type="button" className="btn btn-xs btn-outline-secondary m-2px" title="View" data-bs-target="#showUserModal" data-bs-toggle="modal" onClick={() => showUserDetails(rowData.id)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_eyefill" />
                                                </svg>
                                            </button>
                                            {    
                                                rowData.status == "pending" || rowData.status == "rejected" ? 
                                                    <button type="button" className="btn btn-xs btn-outline-success m-2px" title="Approved" onClick={ () => handleChangeStatus('approved', rowData.id)}>
                                                    <svg className="bi bi-2x">
                                                        <use xlinkHref="#bi_checkright" />
                                                    </svg>
                                                </button> : ''
                                            }
                                            {
                                                rowData.status == "approved" || rowData.status == "pending" ?
                                                <button type="button" className="btn btn-xs btn-outline-danger m-2px" title="Reject" onClick={ () => handleChangeStatus('rejected', rowData.id)}>
                                                    <svg className="bi bi-2x">
                                                        <use xlinkHref="#bi_checkcross" />
                                                    </svg>
                                                </button> : ''
                                            }
                                            <button type="button" className="btn btn-xs btn-outline-danger m-2px" title="Delete"  onClick={ () => handleChangeStatus('delete', rowData.id)}>
                                                <svg className="bi">
                                                    <use xlinkHref="#bi_trash" />
                                                </svg>
                                            </button>
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 11,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {
                                            rowData.status == "approved" ? <span className="text-success">Approved</span> : rowData.status == "pending" ? <span className="text-warning">Pending</span> : <span className="text-danger">Rejected</span>
                                        }
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 8,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {
                                            rowData.role == "Buyer" ? "Buyer" : rowData.role == "Seller_private" ? "Private Seller" : "Corporate Seller"
                                        }
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 3,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {
                                            rowData.user_details == null  ? "N/A"  : <span style={{textTransform:'capitalize'}}>{rowData.user_details.business_name}</span>
                                        }
                                        </>, td)
                                    },
                                },
                            ],
                            dt_paging: true,
                            dt_filter: () => {
                                ReactDOM.render(
                                    <>
                                        <div className="dropdown d-inline-block align-top">
                                            <button className="btn btn-sm btn-light border align-top dropdown-toggle" data-bs-toggle="dropdown">Status</button>
                                            <ul className="dropdown-menu shadow border-0 p-2">
                                                <li>
                                                    <label className="dropdown-item rounded">
                                                        <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked defaultValue="all" onClick={handleFilterChange}/>
                                                        All
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="dropdown-item rounded">
                                                        <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={user_status==='pending' ? true : false} defaultValue="pending" onClick={handleFilterChange}/>
                                                        Pending
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="dropdown-item rounded">
                                                        <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={user_status==='approved' ? true : false} defaultValue="approved" onChange={handleFilterChange}/>
                                                        Approved
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="dropdown-item rounded">
                                                        <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={user_status==='rejected' ? true : false} defaultValue="rejected" onChange={handleFilterChange}/>
                                                        Rejected
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </>, document.getElementById(`dt-custom-filter`)
                                )
                            }
                        })

    const handleChangeStatus = (status, id) => {
        fetchData(`user/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            dt_reload.current.reloadDatatable()
        }, (err) => {
            
        })
    }
    const [activeForm, setActiveForm] = useState(false)
    const [user, setUser] = useState();
    const [userDetails, setUserDetails] = useState();
    const [note, setNote] = useState();
    const [id, setId] = useState();

    useEffect(()=>{
        document.getElementById('staticBackdrop').addEventListener('show.bs.modal', function (event) {
            document.getElementById('showImage').src = event.relatedTarget.dataset.previewimg
        });
    },[])
    
    
    const showUserDetails = (id) => {
        fetchData(`user/edit/${id}`, 'GET', '', true, false, (res) => {
            setUserDetails(res.data[0])
            setUser(res.data[1])
            setActiveForm(false)
	    }, (err) => {
		console.log(err)
        })
    }
     
    const setNotes = (id) => { 
        fetchData(`user/note/${id}`, 'GET','', true, false, (res) => { 
            setNote(res.data.notes)
            setId(id)
        }, (err) => {}) 
 
    }
    const addNote = () => {   
        fetchData(`user/note/${id}`, 'POST',  {'notes' : note}, true, false, (res) => {
            dt_reload.current.reloadDatatable()
            setNote('')
            document.getElementById('close').click()
            document.getElementById('noteForm').reset()
        }, (err) => {}) 
    }

    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        setUserDetails(prevState => ({
            ...prevState,
            'role' : user.role,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmitForm = () => {
        fetchData(`user/edit/${userDetails.user_id}`, 'POST', userDetails, true, false, (res) => {
            if(res.status){
                setActiveForm(false)
                dt_reload.current.reloadDatatable()
            }
	    }, (err) => {
		console.log(err)
        })
    }

    const handleFilterChange = () => {
        dt_reload.current.reloadDatatable('redraw')
    }

    return (
        <>
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row py-2 align-items-center">
                    <div className="col-sm-6 py-1">
                        <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_usercircle"></use>
                            </svg>Users
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTables dt_data={dt} ref={dt_reload}/>
                    </div>
                </div>

                <div className="modal fade" id="showUserModal" aria-hidden="true" tabIndex="-1" data-bs-backdrop="static">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content border-0 shadow">
                            <div className="modal-header bg-light">
                                <h5 className="modal-title fw-bold">
                                    <svg className="bi bi-2x me-3">
                                        <use xlinkHref="#bi_car"></use>
                                    </svg>User Details
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="content-wrapper">
                                    <div className="container-fluid">
                                        <div className="row align-items-center">
                                            {
                                                activeForm ? '' : (
                                                    <div className="col-sm-12 py-3 text-sm-end">
                                                        <button type="button" className="btn btn-primary hideon-active" onClick={() => setActiveForm(true)} title="Profile Edit">
                                                            <svg className="bi me-1">
                                                                <use xlinkHref="#bi_edit" />
                                                            </svg>
                                                            Edit
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                        {
                                            user ? (
                                                    <div className="row justify-content-center m-0 g-3">
                                                    <div className="col">
                                                        <div className={activeForm ? "row justify-content-center g-4" : "row justify-content-center g-4 my-profile"} id="profileForm">
                                                            
                                                            <div className="col-sm-6">
                                                                <label htmlFor="firstname" className="form-label">First Name</label>
                                                                <input type="text" className="form-control" placeholder="" name="first_name" defaultValue={userDetails.first_name} id="firstname" onChange={handleInputChange} style={{textTransform: 'capitalize'}}/>
                                                                <div className="error first_name-error small text-danger px-2 py-1 d-none"></div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="lastname" className="form-label">Last Name</label>
                                                                <input type="text" className="form-control" placeholder="" name="last_name" defaultValue={userDetails.last_name} id="lastname" onChange={handleInputChange} style={{textTransform: 'capitalize'}}/>
                                                                <div className="error last_name-error small text-danger px-2 py-1 d-none"></div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="email" className="form-label">Email</label>
                                                                <input type="text" className="form-control" placeholder="" name="email" defaultValue={user.email} id="email" readOnly={activeForm} />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="role" className="form-label">Role</label>
                                                                <input type="text" className="form-control" placeholder="" name="role" defaultValue={user.role} id="role" readOnly={activeForm} style={{textTransform: 'capitalize'}}/>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="mobilenumber" className="form-label">Mobile Number</label>
                                                                <input type="text" className="form-control" placeholder="" name="phone" defaultValue={userDetails.phone} id="mobilenumber" onChange={handleInputChange}/>
                                                                <div className="error phone-error small text-danger px-2 py-1 d-none"></div>
                                                            </div>
                                                            {
                                                                user.role === 'seller_corporate' ? (
                                                                    <>
                                                                    <div className="col-sm-6">
                                                                        <label htmlFor="estimated" className="form-label">Estimated Vehicles Per Week</label>
                                                                        <input type="text" className="form-control" placeholder="" name="estimated_vehicles_per_week" defaultValue={userDetails.estimated_vehicles_per_week} id="estimated" onChange={handleInputChange}/>
                                                                        <div className="error estimated_vehicles_per_week-error small text-danger px-2 py-1 d-none"></div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <label htmlFor="businessname" className="form-label">Business Name</label>
                                                                        <input type="text" className="form-control" placeholder="" name="business_name" defaultValue={userDetails.business_name} id="businessname" onChange={handleInputChange} style={{textTransform: 'capitalize'}}/>
                                                                        <div className="error business_name-error small text-danger px-2 py-1 d-none"></div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <label htmlFor="person_role" className="form-label">Person Role</label>
                                                                        <input type="text" className="form-control" placeholder="" name="person_role" defaultValue={userDetails.person_role} id="person_role" readOnly={activeForm} style={{textTransform: 'capitalize'}}/>
                                                                    </div>
                                                                    </>
                                                                    
                                                                ) : (
                                                                    <div className="col-sm-6">
                                                                        <label htmlFor="nicnumber" className="form-label">NIC Number</label>
                                                                        <input type="text" className="form-control" placeholder="" name="nic_number" readOnly={activeForm} defaultValue={userDetails.nic_number} id="nicnumber" />
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="col-sm-6">
                                                                <label htmlFor="status" className="form-label">Status</label>
                                                                <input type="text" className="form-control" placeholder="" name="status" defaultValue={user.status} id="status" readOnly={activeForm} style={{textTransform: 'capitalize'}}/>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="created_at" className="form-label">Created At</label>
                                                                <input type="text" className="form-control" placeholder="" name="created_at" defaultValue={user.created} id="created_at" readOnly={activeForm}/>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <label htmlFor="address" className="form-label">Address</label>
                                                                <textarea type="text" className="form-control" placeholder="" id="address" rows="2" name="address" defaultValue={userDetails.address} onChange={handleInputChange}></textarea>
                                                                <div className="error address-error small text-danger px-2 py-1 d-none"></div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                        <div className="row my-4">
                                                            <div className="col-sm-6" data-bs-toggle="modal" data-bs-target="#staticBackdrop" data-previewimg={process.env.REACT_APP_ASSET_URL + userDetails.nic_image.split(',')[0]}>
                                                                <label className="ratio ratio-1x1 overflow-hidden rounded-10 d-block" style={{'maxWidth': '120px'}}>
                                                                    <img src={process.env.REACT_APP_ASSET_URL + userDetails.nic_image.split(',')[0]} alt="User Avatar" />
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-6" data-bs-toggle="modal" data-bs-target="#staticBackdrop" data-previewimg={process.env.REACT_APP_ASSET_URL + userDetails.nic_image.split(',')[1]}>
                                                                <label className="ratio ratio-1x1 overflow-hidden rounded-10 d-block" style={{'maxWidth': '120px'}}>
                                                                    <img src={process.env.REACT_APP_ASSET_URL + userDetails.nic_image.split(',')[1]} alt="User Avatar" />
                                                                </label>
                                                            </div>
                                                            {
                                                                activeForm ? (
                                                                    <div className="col-sm-12 my-4">
                                                                        <button type="button" className="btn btn-primary px-4" onClick={handleSubmitForm}>Update</button>
                                                                    </div>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                   {/* Add Note Module  */}

                    <div className="modal fade" id="addNoteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-blue" id="exampleModalLabel">Add Note</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                               <form id="noteForm">
                               <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Add Note</label>
                                    <textarea className="form-control" id="notes" name="notes" rows="5" defaultValue={note ? note : ''}  onChange={(e) => setNote(e.target.value)}></textarea>
                                    </div>
                               </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="close" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => addNote()}>Save</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* Add Image Modal */}
                    <div className="modal fade" id="staticBackdrop"  tabindex="-1" data-bs-backdrop="static">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">NIC Image</h5>
                                    <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#showUserModal"></button>
                                </div>
                                <div className="modal-body">
                                   <img src="" id="showImage" className="d-block mx-auto img-fluid"/>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#showUserModal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
        </div>
        </>
    )
}

export default User