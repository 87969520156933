import { useState, useEffect } from "react"
import { fetchData } from "../components/Helper"

function Setting(){
    const [reload, setReload] = useState(0);
    const [setting, setSetting] = useState([])
    const [data, setData] = useState({
        value: '',
        slug: ''
    })
    useEffect(() => {
        fetchData(`settings?type=setting`, 'GET', '', true, false, (res) => {
            setSetting(res.data)
	    }, (err) => {
		console.log(err)
        })
    },[reload])

    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        setData(prevState => ({
            ...prevState,
            value: e.target.value,
            slug: e.target.id
        }))
    }
    const handleSubmitForm = (slug) => {
       var reset = document.getElementById('setform' +data.slug)
        fetchData(`setting/add?type=setting`, 'POST', data, true, false, (res) => {
            if(res.status) {
                reset.reset()
                setReload(reload+1)             
            }
        }, (err) => {
            console.log(err)
        })
    }

    const removeVehicleSetting = (value, slug) => {
        fetchData(`remove/setting?type=setting`, 'POST', {slug: slug, value: value}, true, false, (res) => {
            if(res.status) {
                setReload(reload+1)             
            }
        }, (err) => {
            console.log(err)
        })
    }

    return (
    <>
    <div className="content-wrapper">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-sm-12 py-3">
                    <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                        <svg className="bi bi-1x me-2">
                            <use xlinkHref="#bi_tools"></use>
                        </svg> Settings
                    </h6>
                </div>
            </div>
            {
                setting ? 
                    setting.map((item, index) => {
                        return (
                            <div className="row m-0 mb-3" key={index} style={{'border': '1px solid #e9e9ef'}}>
                                <div className="col-sm-12 p-3 p-sm-4">
                                    <label htmlFor='' className="mb-1">{item.name}</label>
                                    <form className="input-group mb-3  maxw-380px" id={'setform'+ item.slug}>
                                        <input type="text" className="form-control" placeholder="" id={item.slug} name="value" aria-label="Vehicle Type" aria-describedby="addmore" autoComplete="off" required onChange={handleInputChange} />
                                        <button className="btn btn-primary" type="button" onClick={() => handleSubmitForm(item.slug)} id="addmore">
                                            <svg className="bi me-2">
                                                <use xlinkHref="#bi_pluscircle" />
                                            </svg>Add
                                        </button>
                                    </form>
                                    <div className="error value-error small text-danger px-2 py-1 d-none"></div>
                                    <div className="d-flex flex-wrap" id="vehicleBadge">
                                        {
                                            item.value.split(',').map((value) => {
                                                return (
                                                    value ? (
                                                        <div key={value} className="d-inline-flex align-items-center bg-light border rounded-pill ps-3 pe-2 py-1 mb-2 me-2">
                                                            <div className="fw-medium me-3 opacity-85">{value}</div>
                                                            <button type="button" onClick={() => removeVehicleSetting(value, item.slug)}  className="btn-close ms-auto fs-12px" aria-label="Close"></button>
                                                        </div>
                                                    ) : ''
                                                )
                                            })
                                            
                                        }
                                    </div>
                                </div>
                        </div>
                        )
                    })
                : ''
            }
        </div>
    </div>
    </>
    )
}

export default Setting