import { Link, useParams, useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react"
import { countDown, fetchData, scrollbarSetToTop, IMAGE_URL } from '../components/Helper'
import GlobalContext from "../components/GlobalContext";
import ReportIcon from "../components/ReportIcon";
import LightBox from "../components/LightBox"
import { Helmet } from "react-helmet";
import { BidPlace } from "../components/BidPlace";

function VehicleDetail() {
    const [context] = useContext(GlobalContext)
    const history = useHistory()
    const [page] = useState(localStorage.getItem('page'))
    const [mreport, setMReport] = useState([])
    const [interior, setInterior] = useState([])
    const [essential, setEssential] = useState([])
    const [condition, setCondition] = useState([])
    const [reportImage, setReportImage] = useState([])
    const [image, setImage] = useState([])

    const [sellerId, setSellerId] = useState(0)
    const [vehicleId] = useState(useParams().id)
    const [data, setData] = useState([])
    localStorage.setItem('backData', [data.category, page])

    useEffect(() => {
        scrollbarSetToTop()
        // if(context){
            if(history.location.state){
                fetchData(`vehicle-details/${vehicleId}/${history.location.state}`, 'GET', '', true, false, (res) => {
                    setSellerId(res.data.seller_id)
                    setData(res.data)
                    setReportImage(res.data.report_image)
    
                    let interiors = JSON.parse(res.data.interior_checks)
                    setInterior(interiors)
    
                    let essentials = JSON.parse(res.data.essential_checks)
                    setEssential(essentials)
    
                    let reports = JSON.parse(res.data.mechanical_report)
                    setMReport(reports)
    
                    let conditions = JSON.parse(res.data.condition_report)
                    setCondition(conditions)
    
                    let images = res.data.vehicle_images
                    setImage(images)
                    if(res.data.auction_status === 'open') countDown(res.data.auction_end_date)
                }, (err) => {});
            }else{ 
                history.goBack()
            }
        // }
        document.getElementById('imagePreviewModal').addEventListener('show.bs.modal', function (event) {
            document.getElementById('setbigImage').src = event.relatedTarget.dataset.previewimg
        });

    }, [context]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Quality Car Auctions | ${data.year} ${data.make} ${data.model}, ${data.fuel}`}</title>
            </Helmet>
            <section className="bg-light pt-5 pb-3">
                <div className="container py-sm-2">
                    <div className="row justify-content-center text-center">
                        <div className="col-sm-12 col-md-10 col-xl-8">
                            <h2 className="h1 fw-bold w-75 mx-auto">
                                Vehicle Details
                            </h2>
                            <nav className="d-flex justify-content-center" style={{ '--bs-breadcrumb-divider': '&#10140;' }} aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/auctions">Vehicle</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Vehicle Details</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 mb-2 opacity-30">
                            <hr />
                        </div>
                        <div className="col-md-5">
                            <button className="btn btn-primary" onClick={() => history.goBack()} role="button">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_arrowleft" />
                                </svg>
                                Go Back
                            </button>
                        </div>
                        <div className="col-md-7 text-md-end">
                            <a className="btn btn-outline-primary fw-medium ms-1" href="#" role="button">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_facebook" />
                                </svg> Facebook
                            </a>
                            <a className="btn btn-outline-info fw-medium ms-1" href="#" role="button">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_twitter" />
                                </svg>
                                Twitter
                            </a>
                            <a className="btn btn-outline-success fw-medium ms-1" href="#" role="button">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_mail" />
                                </svg>
                                Email
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="h1 fw-bold">
                                ID {vehicleId} - {data.year} {data.make} {data.model}{data.fuel ? ', '+ data.fuel : ''}
                            </h2>
                            <h3 className="h5 fw-medium mx-auto "><small>Auction Ends In:</small> <span className="auctionCounter">00d 00h 00m 00s</span></h3>
                        </div>
                    </div>
                    {/* { 
                        data.vehicle_images?
                        data.vehicle_images.map((item) =>{
                        return(
                            <h5>{item.image}</h5>
                        )
                    })
                    :''
                    } */}


                    <div className="row">
                        <div className="col-sm-12 col-lg-7">
                            <div className="row g-2">
                                {
                                    data.vehicle_images ?
                                        <>
                                            <div className="col-sm-6">
                                                <div className="ratio ratio-1x1 d-block" role="button" data-bs-target="#lightbox" data-bs-toggle="modal" title="Preview" data-preview={image[0]}>
                                                    <img src={image[0]} className="img-cover w-100 h-100" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="row g-2">
                                                    <div className="col-6">
                                                        <div className="ratio ratio-1x1 d-block" role="button" data-bs-target="#lightbox" data-bs-toggle="modal" title="Preview" data-preview={image[1]}>
                                                            <img src={image[1]} className="img-cover w-100 h-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="ratio ratio-1x1 d-block" role="button" data-bs-target="#lightbox" data-bs-toggle="modal" title="Preview" data-preview={image[2]}>
                                                            <img src={image[2]} className="img-cover w-100 h-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="ratio ratio-1x1 d-block" role="button" data-bs-target="#lightbox" data-bs-toggle="modal" title="Preview" data-preview={image[3]}>
                                                            <img src={image[3]} className="img-cover w-100 h-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="ratio ratio-1x1 d-block" role="button" data-bs-target="#lightbox" data-bs-toggle="modal" title="Preview" data-preview={image[4]}>
                                                            <img src={image[4]} className="img-cover w-100 h-100" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }

                            </div>

                            {data && data.category === 'accidental_or_faulty_vehicles' ? <div className="row py-3 py-sm-4">
                                <div className="col-sm-12 col-md-12">
                                    <h2 className="h5 text-center text-danger m-0">PLEASE NOTE VEHICLES CAN NOT BE DRIVEN FROM SITE</h2>
                                </div>
                            </div> : ''

                            }
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="bg-light p-3 p-md-4 key-facts mb-4" style={{ textTransform: 'capitalize' }}>
                                        <h2 className="h4 fw-medium mb-3">Key facts about this Vehicle:</h2>
                                        <div className="row g-3 text-center">
                                            <div className="col-sm-12">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Auction Ends In</div>
                                                    <div className="fw-bold auctionCounter">00d 00h 00m 00s</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Date Of Registration</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.date_of_registration}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">City Of Registration</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.city_of_registration}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Token Paid Till</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.token_paid_till}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Category</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.category ? data.category.split('_').join(' ') : ''}</div>
                                                </div>
                                            </div>
                                            {
                                                data.category == 'accidental_or_faulty_vehicles' ?
                                                    <>
                                                        <div className="col-sm-6 col-lg-4">
                                                            <div className="bg-white border-purple rounded p-3 h-100">
                                                                <div className="small fs-13px lh-sm text-primary">Engin Starts</div>
                                                                <div className="small fs-14px lh-sm text-dark">{data.engin_start}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-4">
                                                            <div className="bg-white border-purple rounded p-3 h-100">
                                                                <div className="small fs-13px lh-sm text-primary">Engin Drive</div>
                                                                <div className="small fs-14px lh-sm text-dark">{data.drive}</div>
                                                            </div>
                                                        </div>
                                                    </> : ''
                                            }


                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Make</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.make}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Model</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.model}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3">
                                                    <div className="small fs-13px lh-sm text-primary">Year</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.year}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Type</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.type}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Number of Owners</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.number_of_owners}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Number of Doors</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.number_of_doors}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3">
                                                    <div className="small fs-13px lh-sm text-primary">Mileage</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.mileage} kms</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Fuel Type</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.fuel}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3">
                                                    <div className="small fs-13px lh-sm text-primary">Transmission</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.transmission}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Colour</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.colour}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3">
                                                    <div className="small fs-13px lh-sm text-primary">Ownership Document</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.ownership_document_present == 0 ? 'Not Present' : 'Present'}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3">
                                                    <div className="small fs-13px lh-sm text-primary">Proof of Ownership</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.proof_of_ownership == 0 ? 'Not Present' : 'Present'}</div>
                                                </div>
                                            </div>

                                            <div className={`${data.category === 'accidental_or_faulty_vehicles' ? "col-12" : "col-sm-12 col-lg-8 "}`}>
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Location</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.location}</div>
                                                </div>
                                            </div>

                                            {/* <div className="col-sm-6 col-lg-4">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Reserve Price</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.reserve_price}</div>
                                                </div>
                                            </div> */}

                                            <div className="col-12">
                                                <div className="bg-white border-purple rounded p-3 h-100">
                                                    <div className="small fs-13px lh-sm text-primary">Equipments</div>
                                                    <div className="small fs-14px lh-sm text-dark">{data.equipment}</div>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            data.notes ?
                                                <>
                                                    <div className="row mt-4">
                                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                                            <h2 className="h5 fw-medium mb-3">Notes</h2>

                                                            <p className="opacity-80 text-dark">

                                                                {data.notes}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </> : ''
                                        }

                                        <div className="row mt-4 fs-12px">
                                            <div className="col-12">
                                                <ul className="list-inline border p-3 bg-light rounded-10">
                                                    <li className="list-inline-item fw-medium">Key:</li>
                                                    <li className="list-inline-item me-4">
                                                        <svg className="bi bi-3x me-1 text-success">
                                                            <use xlinkHref="#bi_checkright"></use>
                                                        </svg> Ok
                                                    </li>
                                                    <li className="list-inline-item me-4">
                                                        <svg className="bi bi-3x me-1 text-orange">
                                                            <use xlinkHref="#bi_exclamation"></use>
                                                        </svg> Requires attention
                                                    </li>
                                                    <li className="list-inline-item me-4">
                                                        <svg className="bi bi-3x me-1 text-danger">
                                                            <use xlinkHref="#bi_exclamation2"></use>
                                                        </svg> Not working
                                                    </li>
                                                    <li className="list-inline-item me-4">
                                                        <svg className="bi bi-3x me-1 text-muted">
                                                            <use xlinkHref="#bi_checkcross"></use>
                                                        </svg> Not applicable
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <svg className="bi bi-3x me-1">
                                                            <use xlinkHref="#bi_question"></use>
                                                        </svg> I don't know
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <h2 className="h5 fw-medium mb-3">Vehicle Mechanical Report</h2>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.brakes_pads_disc} />
                                                    Brakes Pads/Disc
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.aircon_receives_power} />
                                                    Aircon Receives Power (Ex: Gassing)
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.first_and_reverse_drive_or_clutch_slip_test} />
                                                    1st & Reverse Rrive/Clutch Slip Test (Static)
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.central_locking} />
                                                    Central Locking (Ex: Key Fob)
                                                </p>


                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 mt-4">

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.static_gear_selection} />
                                                    Static Gear Selection (Engine Running)
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.suspension_ride_height} />
                                                    Suspension Ride Height (Ex-Air Susp)
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.nav_receives_power} />
                                                    Nav Receives Power (Ex: Functionality)
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={mreport.horn} />
                                                    Horn
                                                </p>

                                            </div>
                                        </div>



                                        <div className="row mt-3">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <h2 className="h5 fw-medium mb-3">Essential Checks</h2>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.headlights_illuminate} />
                                                    Headlights Illuminate
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.brakelights_illuminate} />
                                                    Brakelights Illuminate
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.indicators_operate} />
                                                    Indicators Operate
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.electric_mirror_movement} />
                                                    Electric Mirror Movement
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.battery_state_of_health} />
                                                    Battery State Of Health
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.power_steering_fluid_level} />
                                                    Power Steering Fluid Level
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.engine_oil_level} />
                                                    Engine Oil Level
                                                </p>

                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 mt-4">

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.running_lights} />
                                                    Running Lights (Side Lights)
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.foglights_illuminate} />
                                                    Foglights Illuminate
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.electric_window_movement} />
                                                    Electric Window Movement
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.wiper_arm_movement_and_washers} />
                                                    Wiper Arm Movement & Washers
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.coolant_level} />
                                                    Coolant Level
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.brake_fluid_level} />
                                                    Brake Fluid Level
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={essential.oil_or_coolant_contamination} />
                                                    Oil/Coolant Contamination
                                                </p>

                                            </div>
                                        </div>


                                        <div className="row mt-3">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <h2 className="h5 fw-medium mb-3">Interior checks</h2>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.engine_management_light} />
                                                    Engine Management Light
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.brake_efficiency} />
                                                    Brake Efficiency
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.ice_recieves_power} />
                                                    Ice Recieves Power
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.abs_warning_light} />
                                                    ABS Warning Light
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.airbag_warning_light} />
                                                    Airbag Warning Light
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.engine_running_or_smoking} />
                                                    Engine Running/Smoking
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.exhaust} />
                                                    Exhaust (No Leaks & Secure)
                                                </p>

                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 mt-4">

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.brake_wear_indicator_light} />
                                                    Brake Wear Indicator Light
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.hand_brake_efficiency} />
                                                    Hand Brake Efficiency
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.convertible_sunroof_electrics} />
                                                    Convertible Sunroof Electrics
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.oil_warning_light} />
                                                    Oil Warning Light
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.glow_plug_light} />
                                                    Glow Plug Light
                                                </p>

                                                <p className="opacity-80 text-dark">
                                                    <ReportIcon type={interior.aux_belt_or_pulley_noise} />
                                                    BAux Belt/Pulley Noise
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            condition != '' ?

                                                <div className="row mt-3">
                                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                        <h2 className="h5 fw-medium mb-3">Vehicle Conditional Report</h2>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Bonnet : </b> {condition.bonnet}
                                                            {reportImage && reportImage.bonnet_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.bonnet_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Trunk or diggy : </b> {condition.trunk_or_diggy}
                                                            {reportImage && reportImage.trunk_or_diggy_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.trunk_or_diggy_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Driver side rear door : </b> {condition.driver_side_rear_door}
                                                            {reportImage && reportImage.driver_side_rear_door_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.driver_side_rear_door_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Driver side front door : </b> {condition.driver_side_front_door}
                                                            {reportImage && reportImage.driver_side_front_door_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.driver_side_front_door_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span> : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Driver side rear fender : </b> {condition.driver_side_rear_fender}
                                                            {reportImage && reportImage.driver_side_rear_fender_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.driver_side_rear_fender_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Driver side front fender : </b> {condition.driver_side_front_fender}
                                                            {reportImage && reportImage.driver_side_front_fender_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.driver_side_front_fender_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                    </div>

                                                    <div className="col-sm-12 col-md-6 col-lg-6 mt-4">

                                                        <p className="opacity-80 text-dark">
                                                            <b>Passenger side rear door : </b> {condition.passenger_side_rear_door}
                                                            {reportImage && reportImage.passenger_side_rear_door_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.passenger_side_rear_door_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Passenger side front door : </b> {condition.passenger_side_front_door}
                                                            {reportImage && reportImage.passenger_side_front_door_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.passenger_side_front_door_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Passenger side rear fender : </b> {condition.passenger_side_rear_fender}
                                                            {reportImage && reportImage.passenger_side_rear_fender_images ?
                                                                <span role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.passenger_side_rear_fender_images}>
                                                                    <svg className="bi bi-4x m-1">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Passenger side front fender : </b> {condition.passenger_side_front_fender}
                                                            {reportImage && reportImage.passenger_side_front_fender_images ?
                                                                <span role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.passenger_side_front_fender_images}>
                                                                    <svg className="bi bi-4x me-1">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Driver side under door panel : </b> {condition.driver_side_under_door_panel}
                                                            {reportImage && reportImage.driver_side_under_door_panel_images ?
                                                                <span className="m-2" role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.driver_side_under_door_panel_images}>
                                                                    <svg className="bi bi-4x me-3">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                        <p className="opacity-80 text-dark">
                                                            <b>Passenger side under door panel : </b> {condition.passenger_side_under_door_panel}
                                                            {reportImage && reportImage.passenger_side_under_door_panel_images ?
                                                                <span role="button" data-bs-target="#imagePreviewModal" data-bs-toggle="modal" title="PreviewImg" data-previewimg={IMAGE_URL + reportImage.passenger_side_under_door_panel_images}>
                                                                    <svg className="bi bi-4x">
                                                                        <use xlinkHref="#bi_eyefill" />
                                                                    </svg>
                                                                </span>
                                                                : ''}
                                                        </p>

                                                    </div>
                                                </div> : ''
                                        }

                                        <div className="row mt-5">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <h2 className="h5 fw-medium">Viewing:</h2>

                                                <p className="opacity-80">For Information / viewing call 0322 6443232</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <h2 className="h5 fw-medium">Guide Price:</h2>

                                                <p className="opacity-80">{data.sold_price}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-sm-12 col-md-6 col-lg-6">
                                            <h2 className="h5 fw-medium">Extra information:</h2>
                                            <p className="opacity-80">Sold at direction of police. Registration Plates damaged.</p>
                                        </div> */}
                                            {
                                                data.main_damages && data.main_damages != 'null' ?
                                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                        <h2 className="h5 fw-medium">Damage report:</h2>
                                                        <p className="opacity-80">{data.main_damages}</p>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </div>

                                    <div className="bg-light p-3 p-sm-4">
                                        <p className="opacity-80">
                                            All images and descriptions are only an indication of damage. Please contact the holding agent for a more detailed description. If you cannot see any photographs and need to, please contact <a href="#" className="anchor-bluehover">admin@qcarauctions.com</a> For viewing and collection see our <a href="#" className="anchor-bluehover">see vehicle’s location in vehicle details</a> for agents contact numbers.
                                        </p>
                                        <p className="opacity-80 mb-0">
                                            The category of a vehicle is checked when processed to auction and may be subject to change. Quality Car Auctions are not liable for these changes and you must do your own checks to ensure you are satisfied with the vehicle. For more information regarding ABI category changes please read our <a href="#" className="anchor-bluehover">top questions.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-5 ps-lg-5">
                            <BidPlace sellerId={sellerId}>
                                <p className="">
                                    The vehicle has a buyer's premium of 2% of the winning bid amound, payable to Quality Car Auctions payable on top of the winning Bid amount under the same invoice.
                                </p>
                                <div className="row mt-4">
                                    <div className="col-sm-12 col-md-6">
                                        <h4 className="h5 fw-medium">Auction Start Date:</h4>
                                        <p className="mb-0">{data.auction_start_date}</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <h4 className="h5 fw-medium">Auction End Date:</h4>
                                        <p className="mb-0"> {data.auction_end_date}</p>
                                    </div>
                                    <div className="col-sm-12 mt-4">
                                        <p>Payment Info: <a href="javascript.void(0)" className="text-light">Standard methods</a></p>
                                    </div>
                                </div>
                            </BidPlace>

                            <div className="bg-primary mt-4 p-3 p-sm-4 text-white">

                                <h2 className="d-flex">
                                    <svg className="bi bi-3x me-3">
                                        <use xlinkHref="#bi_question" />
                                    </svg>
                                    Need some help? Get in touch
                                </h2>
                                <p className="py-3">
                                    Simply quote the vehicle ID: <b>{vehicleId}</b> if on the phone or alternatively use the email option below.
                                </p>
                                <div className="w-sm-75 mx-auto">
                                    <a className="btn btn-light d-flex align-items-center mb-3" href="mailto:enquiries@qcarauctions.com" role="button">
                                        <svg className="bi bi-4x me-3">
                                            <use xlinkHref="#bi_mail" />
                                        </svg>
                                        enquiries@qcarauctions.com
                                    </a>
                                    <a className="btn btn-light d-flex align-items-center mb-3" href="tel:0322 6443232" role="button">
                                        <svg className="bi bi-4x me-3">
                                            <use xlinkHref="#bi_phone" />
                                        </svg>
                                        0322 6443232
                                    </a>
                                    <a className="btn btn-light d-flex align-items-center mb-3" href="#" role="button">
                                        <svg className="bi bi-4x me-3">
                                            <use xlinkHref="#bi_question" />
                                        </svg>
                                        View Help
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Start | Modal for Image Preview */}
            <div className="modal fade" tabIndex="-1" id="imagePreviewModal" data-bs-backdrop="true" data-bs-keyboard="false">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content rounded-0 overflow-hidden border-0 bg-transparent">
                    <div className="modal-header border-0 py-2">
                        &nbsp;
                        <button type="button" className="btn btn-lg btn-outline-danger position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close" title="Close">
                            &times;
                        </button>
                    </div>
                        <div className="moday-body d-flex align-items-center justify-content-center p-3 position-relative w-100" style={{minHeight: '60vh'}}>
                            <img src="" id="setbigImage" className="img-cover" style={{ 'height': 'inherit', 'width': 'inherit' }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* End | Modal for Image Preview */}
            <LightBox />
        </>
    )
}

export default VehicleDetail