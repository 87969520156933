import ReactDOM from 'react-dom';
import { useRef, useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { fetchData } from '../components/Helper'
import DataTables from '../components/Datatable';
import GlobalContext from "../components/GlobalContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { ReportCheckIcon } from '../components/ReportIcon';
import GoogleMap from '../components/GoogleMap';
import $ from 'jquery'

function Vehicle() {
    const history = useHistory()
    const dt_reload = useRef()
    const [context, setContext] = useContext(GlobalContext)
    const [sellOption] = useState(useParams().sell_option)

    const [vehicleDetails, setVehicleDetails] = useState()
    const [uploadImage, setUploadImage] = useState()
    const [registrationDate, setRegistrationDate] = useState(new Date());
    const [tokenPaidTill, setTokenPaidTill] = useState(new Date());
    const [vehicleYear, setVehicleYear] = useState(new Date());
    const [calender, setCalender] = useState(new Date());
    const [vehicleSetting, setVehicleSetting] = useState('')
    const [seller, setSeller] = useState()
    const [input, setInput] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [seller_id, setSeller_id] = useState({
        'seller_id' : ''
    })
    const [googleMapKey, setGoogleMapKey] = useState()
    
    const [isAccidental, setIsAccidental] = useState(false)
    const [isConditionReport, setIsConditionReport] = useState(false)
    const [isOwnership, setIsOwnership] = useState(false)
    const [isShowDetails, setIsShowDetails] = useState(false)
    const [isEditDetails, setIsEditDetails] = useState(false)

    const [reportChecks] = useState({
        mechanical : [
            {name: 'brakes_pads_disc', title: 'Brakes Pads/Disc'},
            {name: 'static_gear_selection', title: 'Static Gear Selection (Engine Running)'},
            {name: 'first_and_reverse_drive_or_clutch_slip_test', title: '1st & Reverse Rrive/Clutch Slip Test (Static)'},
            {name: 'suspension_ride_height', title: 'Suspension Ride Height (Ex-Air Susp)'},
            {name: 'aircon_receives_power', title: 'Aircon Receives Power (Ex: Gassing)'},
            {name: 'nav_receives_power', title: 'Nav Receives Power (Ex: Functionality)'},
            {name: 'central_locking', title: 'Central Locking (Ex: Key Fob)'},
            {name: 'horn', title: 'Horn'}
        ],
        essential: [
            {name: 'headlights_illuminate', title: 'Headlights Illuminate'},
            {name: 'running_lights', title: 'Running Lights (Side Lights)'},
            {name: 'brakelights_illuminate', title: 'Brakelights Illuminate'},
            {name: 'foglights_illuminate', title: 'Foglights Illuminate'},
            {name: 'indicators_operate', title: 'Indicators Operate'},
            {name: 'electric_window_movement', title: 'Electric Window Movement'},
            {name: 'electric_mirror_movement', title: 'Electric Mirror Movement'},
            {name: 'wiper_arm_movement_and_washers', title: 'Wiper Arm Movement & Washers'},
            {name: 'battery_state_of_health', title: 'Battery State Of Health'},
            {name: 'coolant_level', title: 'Coolant Level'},
            {name: 'power_steering_fluid_level', title: 'Power Steering Fluid Level'},
            {name: 'brake_fluid_level', title: 'Brake Fluid Level'},
            {name: 'engine_oil_level', title: 'Engine Oil Level'},
            {name: 'oil_or_coolant_contamination', title: 'Oil/Coolant Contamination'}
        ],
        interior: [
            {name: 'engine_management_light', title: 'Engine Management Light'},
            {name: 'brake_wear_indicator_light', title: 'Brake Wear Indicator Light'},
            {name: 'brake_efficiency', title: 'Brake Efficiency'},
            {name: 'hand_brake_efficiency', title: 'Hand Brake Efficiency'},
            {name: 'ice_recieves_power', title: 'Ice Recieves Power'},
            {name: 'convertible_sunroof_electrics', title: 'Convertible Sunroof Electrics'},
            {name: 'abs_warning_light', title: 'ABS Warning Light'},
            {name: 'oil_warning_light', title: 'Oil Warning Light'},
            {name: 'airbag_warning_light', title: 'Airbag Warning Light'},
            {name: 'glow_plug_light', title: 'Glow Plug Light'},
            {name: 'engine_running_or_smoking', title: 'Engine Running/Smoking'},
            {name: 'aux_belt_or_pulley_noise', title: 'Aux Belt/Pulley Noise'},
            {name: 'exhaust', title: 'Exhaust (No Leaks & Secure)'}
        ],
        condition: [
            {name: 'bonnet', title: 'Bonnet'},
            {name: 'driver_side_front_fender', title: 'Driver Side Front Fender'},
            {name: 'driver_side_front_door', title: 'Driver Side Front Door'},
            {name: 'driver_side_rear_door', title: 'Driver Side Rear Door'},
            {name: 'driver_side_rear_fender', title: 'Driver Side Rear Fender'},
            {name: 'driver_side_under_door_panel', title: 'Driver Side Under Door Panel'},
            {name: 'trunk_or_diggy', title: 'Trunk/Diggy'},
            {name: 'passenger_side_front_fender', title: 'Passenger Side Front Fender'},
            {name: 'passenger_side_front_door', title: 'Passenger Side Front  Door'},
            {name: 'passenger_side_rear_door', title: 'Passenger Side Rear Door'},
            {name: 'passenger_side_rear_fender', title: 'Passenger Side Rear Fender'},
            {name: 'passenger_side_under_door_panel', title: 'Passenger Side Under Door Panel'},
        ]
    })
    
    useEffect(() => {
        getSettings()
        if(sellOption === 'sell-a-vehicle' && document.querySelector('#addVehicleModel')){
            document.getElementById('openModal').click()
        }

        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })

        if(localStorage.getItem('status')){
            localStorage.removeItem('status')
        }
    }, []);

    const [dt] = useState({
                        dt_url: sellOption && sellOption!=='sell-a-vehicle' ? 'vehicles?s_filter='+sellOption : 'vehicles',
                        dt_name: 'vehicle',
                        dt_column: [
                            { "data":"id" , "title": "ID"},
                            { "data":"seller_id" , "title": "Registered Seller Id", "visible": context.role === 'admin' ? true: false },
                            { "data":"make", "title":"Make" },
                            { "data":"model", "title":"Model" },
                            { "data":"year", "title":"Year" },
                            { "data":"type", "title":"Type" },
                            { "data":"colour", "title":"Color" },
                            { "data":"fuel", "title":"Fuel" },
                            { "data":"transmission", "title":"Transmission" },
                            { "data":"mileage", "title":"Mileage" },
                            { "data":"location", "title":"Location" },
                            { "data":"reserve_price", "name":"reserve_price", "title":"Reserve Price" },
                            { "data":"city_of_registration", "title":"City of Registration" },
                            { "data":"date_of_registration", "title":"Date of Registration" },
                            { "data":"calender", "title":"Vehicle Delivery Date" },
                            { "data":"won_buyer_id", "name":"won_buyer_id", "title":"Winner Buyer Id", "visible": context.role === 'admin' ? true: false },
                            { "data":"max_bid", "name":"biddings.max_bid", "title":"Maximum Bid Amount" },
                            { "data":"number_plate", "title":"Number Plate" },
                            { "data":"token_paid_till", "title":"Token Paid Till"},
                            { "data":"status", "title":"Status" },
                            { "data":"id", "title":"Action", searchable:false, sortable:false , class:'text-end'}
                        ],
                        dt_column_defs: [
                            // {
                            //     targets: 6,
                            //     createdCell: (td, cellData, rowData, row, col) => {
                            //         ReactDOM.render(<><span className={`${_.kebabCase(rowData.colour)} ${rowData.colour == 'Yellow' || rowData.colour == 'White' ?  "badge text-dark border": "badge" }`} >{rowData.colour}</span></>, td)
                            //     },
                            // },
                            {
                                targets: 11,
                                createdCell: (td, cellData, rowData, row, col) => {
                                    ReactDOM.render(<><span>PKR {rowData.reserve_price.toFixed(2)}</span></>, td)
                                },
                            },
                            {
                                targets: 15,
                                createdCell: (td, cellData, rowData, row, col) => {
                                    ReactDOM.render(<><span>{rowData.won_buyer_id ? rowData.won_buyer_id : 'NA'}</span></>, td)
                                },
                            },
                            {
                                targets: 16,
                                createdCell: (td, cellData, rowData, row, col) => {
                                    ReactDOM.render(<><span>PKR {rowData.max_bid ? rowData.max_bid.toFixed(2) : '0.00'}</span></>, td)
                                },
                            },
                            {
                                targets: 19,
                                createdCell: (td, cellData, rowData, row, col) =>{
                                    ReactDOM.render(<>
                                    { rowData.status == "approved" ? <span className="text-success">Approved</span> : '' }
                                    { rowData.status == "pending" ? <span className="text-warning">Pending</span> : '' }
                                    { rowData.status == "rejected" ? <span className="text-danger">Rejected</span> : '' }
                                    { rowData.status == "in_auction" ? <span className="text-info">In Auction</span> : '' }
                                    { rowData.status == "reserve_not_met" ? <span className="text-info">Reserve Not Met</span> : '' }
                                    { rowData.status == "on_sale" ? <span className="text-info">On Sale</span> : '' }
                                    { rowData.status == "pending_payment" ? <span className="text-info">Sold</span> : '' }
                                    { rowData.status == "paid" ? <span className="text-success">Paid</span> : '' }
                                    </>, td)
                                },
                            },
                            {
                                targets: 20,
                                createdCell: (td, cellData, rowData, row, col) =>
                                    ReactDOM.render(<>
                                        <button type="button" className="btn btn-xs btn-outline-secondary m-2px" data-bs-target="#addVehicleModel" data-bs-toggle="modal" title="View" onClick={() => {showVehicleDetails(rowData.id)}} >
                                            <svg className="bi bi-2x">
                                                <use xlinkHref="#bi_eyefill" />
                                            </svg>
                                        </button>
                                        { context.role == 'admin' && (rowData.status == "pending" || rowData.status == "rejected") ? 
                                            <button type="button" className="btn btn-xs btn-outline-success m-2px" title="Approve" onClick={ () => handleChangeStatus('approved', rowData.id)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_checkright" />
                                                </svg>
                                            </button> : '' }
                                        { context.role == 'admin' && (rowData.status == "approved" || rowData.status == "pending") ? 
                                            <button type="button" className="btn btn-xs btn-outline-danger m-2px" title="Reject" onClick={ () => handleChangeStatus('rejected', rowData.id)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_checkcross" />
                                                </svg>
                                            </button> : '' }
                                        { context.role == 'admin' ? 
                                            <button type="button" className="btn btn-xs btn-outline-danger m-2px" title="Delete"  onClick={ () => handleChangeStatus('delete', rowData.id)}>
                                                <svg className="bi">
                                                    <use xlinkHref="#bi_trash" />
                                                </svg>
                                            </button> : ''}
                                        
                                    </>, td),
                            }
                        ],
                        dt_paging: true,
                        dt_filter: () => {
                            ReactDOM.render(
                                <>
                                    <div className="dropdown d-inline-block align-top">
                                        <button className="btn btn-sm btn-light border align-top dropdown-toggle" data-bs-toggle="dropdown">Status</button>
                                        <ul className="dropdown-menu shadow border-0 p-2">
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked defaultValue="all" onClick={handleFilterChange}/>
                                                    All
                                                </label>
                                            </li>
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='pending' ? true : false} defaultValue="pending" onClick={handleFilterChange}/>
                                                    Pending
                                                </label>
                                            </li>
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='approved' ? true : false} defaultValue="approved" onChange={handleFilterChange}/>
                                                    Approved
                                                </label>
                                            </li>
                                            {/* <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultValue="in_auction" onChange={handleFilterChange}/>
                                                    In-auction
                                                </label>
                                            </li> */}
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='reserve_not_met' ? true : false} defaultValue="reserve_not_met" onChange={handleFilterChange}/>
                                                    Reserve Not Met
                                                </label>
                                            </li>
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='on_sale' ? true : false} defaultValue="on_sale" onChange={handleFilterChange}/>
                                                    On Sale
                                                </label>
                                            </li>
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='pending_payment' ? true : false} defaultValue="pending_payment" onChange={handleFilterChange}/>
                                                    Sold
                                                </label>
                                            </li>
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='paid' ? true : false} defaultValue="paid" onChange={handleFilterChange}/>
                                                    Paid
                                                </label>
                                            </li>
                                            <li>
                                                <label className="dropdown-item rounded">
                                                    <input className="form-check-input me-1" type="radio" name="dt_filter_status" defaultChecked={sellOption==='rejected' ? true : false} defaultValue="rejected" onChange={handleFilterChange}/>
                                                    Rejected
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </>, document.getElementById(`dt-custom-filter`)
                            )
                        }
                    })

    const handleChangeStatus = (status, id) => {
        fetchData(`vehicle/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            dt_reload.current.reloadDatatable()
        }, (err) => {
            
        })
    }

    const handleFilterChange = () => {
        dt_reload.current.reloadDatatable('redraw')
    }
    
    const showDiv = (e) => {
        if(e.target.value == 'untouched') {
            document.getElementById(e.target.name).classList.add('d-none');
            document.getElementById(e.target.name+ "img").classList.add('d-none');
        }else{
            document.getElementById(e.target.name).classList.remove('d-none'); 
            document.getElementById(e.target.name+ "img").classList.remove('d-none'); 
        } 
    }

    const handleInputChange = (e) => {
        let a = document.getElementsByClassName(`${e.target.name}-error`)[0]
        setSeller_id(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        if(a){
            a.classList.add('d-none')
        }
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();
        let reset = document.getElementById('vehicleForm')
        let formData = new FormData(reset);

        formData.append('vehicle_id', vehicleDetails ? vehicleDetails.id : '')
        if(context.role == 'admin' && seller_id.seller_id){
            formData.append('seller_id', seller_id.seller_id)
        }else if(context.role != 'admin'){
            formData.append('seller_id', context.id)
        }
        // formData.append('seller_id', context.role == 'admin' ? seller_id.seller_id : context.id)
        formData.append('date_of_registration', registrationDate.toISOString())
        formData.append('token_paid_till', `${tokenPaidTill.getFullYear()}-${tokenPaidTill.getMonth()}-01`)
        formData.append('vehicle_year', vehicleYear.getFullYear())
        formData.append('calender', calender.toISOString())
        formData.append('images', uploadImage ? JSON.stringify(uploadImage) : '')
        setBtnDisabled(true)
        fetchData('vehicle/add', 'POST', formData, true, true, (res) => {
            setBtnDisabled(false)
            if(res.status) {
                reset.reset()
                document.getElementById('closeVehicleAddModal').click()

                dt_reload.current.reloadDatatable()
            }
        }, (err) => {
            console.log(err)
        })
    }

    const getSettings = () => {
        document.getElementById('vehicleForm').reset()
        fetchData('getall/user', 'GET', '', true, false, (res) => {
            setSeller(res.data)
        }, (err) => {
            
        })

        fetchData(`vehicle-setting-type`, 'GET', '', true, false, (res) => {
            setVehicleSetting(res.data)
        }, (err) => {
            console.log(err)
        })

        fetchData(`settings?type=setting&setting_name=google-map-key`, 'GET', '', true, false, (res) => {
            if(res.status && res.data){
                setGoogleMapKey(res.data.value)
            }
        }, (err) => {
            console.log(err)
        })
    }

    const showVehicleDetails = (id) => {
        setIsShowDetails(true)
        fetchData(`vehicle/edit/${id}`, 'GET', '', true, false, (res) => {
            if(res.status) {
                setVehicleDetails(res.data)
                setRegistrationDate(new Date(res.data.date_of_registration))
                setTokenPaidTill(new Date(res.data.token_paid_till))
                setVehicleYear(new Date(res.data.year))
                setCalender(new Date(res.data.calender))
                res.data.category == 'accidental_or_faulty_vehicles' ? setIsAccidental(true) : setIsAccidental(false)
                res.data.ownership_document_present ? setIsOwnership(true) : setIsOwnership(false)
                res.data.deliver_vehicle_to_auction_site ? setIsConditionReport(true) : setIsConditionReport(false)
                setUploadImage(res.data.image)
                for (var key in res.data.image.images) {
                    displayImageThumb(res.data.image, key)
                }

                $(".image_remove_button").hide()
            }
	    }, (err) => {})
    }

    const handleUpdateForm = (e) => {
        e.preventDefault();
        const formData = new FormData();
        fetchData(`vehicle/edit/${vehicleDetails.id}`, 'POST', formData, true, true, (res) => {
            if(res.status) {
                dt_reload.current.reloadDatatable()
                document.getElementById('closeModal').click()
            }
        }, (err) => {
            console.log(err)
        })
    }

    const closeVehicleModal = () => {
        setVehicleDetails()
        
        setIsShowDetails(false)
        setIsEditDetails(false)
        setIsOwnership(false)
        setIsAccidental(false)
        setIsConditionReport(false)

        setVehicleDetails()
        setRegistrationDate(new Date())
        setTokenPaidTill(new Date())
        setVehicleYear(new Date())
        setCalender(new Date())
        setUploadImage('')
    }

    const displayConditionReport = (i, data, checked_value = '') => {
        return ( 
            <li className="list-group-item d-flex1 px-0 hover-hightlight" key={`${data.name}_${i}`}>
                <span className="text-muted">{data.title}</span>
                <div className="row">
                    <div className={`col-lg-6 ${isShowDetails ? 'border-0' : ' border-0 mt-2'}`}>
                        <label className="form-check" data-render={vehicleDetails && vehicleDetails.condition_report[data.name] == 'onlyrepainted' ? 1 : 0}>
                            <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name={data.name} defaultChecked={checked_value == 'onlyrepainted' ? true : false} value="onlyrepainted" onChange={showDiv}/>
                            <div className="form-check-label">Only Re-painted not repaired</div>
                        </label>
                        <label className="form-check" data-render={vehicleDetails && vehicleDetails.condition_report[data.name] == 'onlyrepaired' ? 1 : 0}>
                            <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name={data.name} defaultChecked={checked_value == 'onlyrepaired' ? true : false} value="onlyrepaired" onChange={showDiv}/>
                            <div className="form-check-label">Only repaired not painted</div>
                        </label>
                        <label className="form-check" data-render={vehicleDetails && vehicleDetails.condition_report[data.name] == 're-pairedpainted' ? 1 : 0}>
                            <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name={data.name} defaultChecked={checked_value == 're-pairedpainted' ? true : false} value="re-pairedpainted" onChange={showDiv}/>
                            <div className="form-check-label">Repaired & Re-painted</div>
                        </label>
                        <label className="form-check" data-render={vehicleDetails && vehicleDetails.condition_report[data.name] == 'untouched' ? 1 : 0}>
                            <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name={data.name} defaultChecked={checked_value ? (checked_value == 'untouched' ? true : false) : true} value="untouched" onChange={showDiv}/>
                            <div className="form-check-label">Untouched with no dents and thick scratched.</div>
                        </label>
                    </div>

                    <div className={`col-lg-6 ${(isShowDetails || isEditDetails) && checked_value != '' ? '' : 'd-none'}`} id={data.name}>
                        <div className="row">
                            <div className={`col-lg-8 ${!isShowDetails ? (checked_value == 'untouched' ? 'd-none': '') : 'd-none' }`} id={data.name+ "img"}>
                                <div className="form-control form-control-file">
                                    <input type="file" id={`${data.name}_images`} name={`${data.name}_images`} accept="image/png, image/jpg, image/jpeg" onChange={(e) => handleUploadImage(e, `${data.name}_images`, vehicleDetails ? vehicleDetails.id : '')}/>
                                    <div className="form-placehoder" data-title="Upload Single Images">
                                        <svg className="bi fs-30px opacity-40">
                                            <use xlinkHref="#bi_image" />
                                        </svg>
                                    </div>
                                </div>
                                <div className={`error ${data.name}_images-error small text-danger px-2 py-1 d-none`}></div>
                            </div>
                            <div className={`col-lg-4`}>
                            { uploadImage != '' ? 
                            <div className="form-fileview" id={`${data.name}_images_preview`}></div>
                            : '' }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    const displayImageThumb = (uploadedImages, imageType) => {
        ReactDOM.render(
            <>{
                uploadedImages.images && uploadedImages.images[imageType] ? uploadedImages.images[imageType].map((input_name, i) => {
                    return (
                            <label key={`${imageType}_preview_${i}`} className="ratio ratio-1x1 overflow-hidden rounded-10 me-2 form-image" style={{'maxWidth': '78px'}}>
                                <img src={input_name.url} alt="User Avatar" />
                                <button key={`${imageType}_image_remove_${i}`} className="image_remove_button" type="button" title="Remove" onClick={() => removeImage(imageType, input_name.name, uploadedImages)}>&times;</button>
                            </label>
                        )
                }): ''
            }</>,
            document.getElementById(`${imageType}_preview`)
        )
    }

    const handleUploadImage = (e, input_name, vehicle_id = '') => {
        e.preventDefault();
        input.push(input_name)
        setInput(input)
        let a = document.getElementsByClassName(`${input_name}-error`)[0]
        if(a){
            a.classList.add('d-none')
        }

        let vehicleForm = document.getElementById('vehicleForm')
        let formData = new FormData(vehicleForm);

        formData.append('vehicle_id', vehicle_id)
        formData.append('input_name', input_name)
        formData.append('previous_data', uploadImage ? JSON.stringify(uploadImage) : '')

        fetchData('vehicle-image-upload', 'POST', formData, true, true, (res) => {
            if(res.status && res.data) {
                setUploadImage(res.data)
                displayImageThumb(res.data, [input_name])
            }
        }, (err) => {
            console.log(err)
        })
        console.log(uploadImage);
    }

    const removeImage = (input_name, image_name, uploaded_images) => {
        let formData = new FormData();
        
        formData.append('input_name', input_name)
        formData.append('image_name', image_name)
        formData.append('previous_data', uploaded_images ? JSON.stringify(uploaded_images) : '')

        fetchData('vehicle-image-remove', 'POST', formData, true, true, (res) => {
            if(res.status && res.data) {
                setUploadImage(res.data)
                displayImageThumb(res.data, [input_name])
            }
        }, (err) => {
            console.log(err)
        })
    }

    return (
        <>  
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row py-2 align-items-center">
                        <div className="col-sm-6 py-1">
                            <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                                <svg className="bi bi-1x me-2">
                                    <use xlinkHref="#bi_tools"></use>
                                </svg>Vehicle
                            </h6>
                        </div>
                        <div className="col-sm-6 py-1 text-sm-end">
                            <button type="button" className="btn btn-primary" id="openModal" data-bs-target="#addVehicleModel" data-bs-toggle="modal">
                                <svg className="bi me-1">
                                    <use xlinkHref="#bi_pluscircle" />
                                </svg>
                                Vehicle Registration
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <DataTables dt_data={dt} ref={dt_reload}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addVehicleModel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">
                                <svg className="bi bi-2x me-3">
                                    <use xlinkHref="#bi_car"></use>
                                </svg>{ isShowDetails ? 'Vehicle Details' : (isEditDetails ? 'Update Vehicle Details' : 'Vehicle Registration')}
                            </h5>
                            <button id="closeVehicleAddModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeVehicleModal}></button>
                        </div>
                        <div className="modal-body" key={`form_id_${vehicleDetails ? vehicleDetails.id : 1}`}>
                            <div className="container mb-sm-3">
                                <div className="col-sm-12">
                                    <div className="legend-heading fw-medium text-blue h5 mmb-10px">Vehicle Description
                                        { isShowDetails ? <span className="float-end text-dark" data-bs-title="Edit Vehicle Details" data-bs-toggle="tooltip" data-bs-trigger="hover" onClick={() => {setIsEditDetails(true);setIsShowDetails(false);$(".image_remove_button").show()}}>
                                            <svg className="bi">
                                                <use xlinkHref="#bi_edit" />
                                            </svg>
                                        </span> : '' }
                                    </div>
                                </div>
                            </div>
                            <form className={`container ${isShowDetails ? 'my-profile' : ''}`} id="vehicleForm">
                                <div className="row g-3 g-sm-4 mb-4 mb-sm-5">
                                    <div className="col-sm-6">
                                        <label htmlFor="vehicle_category" className="form-label mb-3px text-muted">Vehicle Category</label>
                                        <div className={`form-control ${isShowDetails ? '' : 'border-0 p-0 mt-2'}`}>
                                            <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.category == 'road_worthy_vehicles' ? 1 : 0}>
                                                <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" id="road_worthy_vehicles" value="road_worthy_vehicles" defaultChecked={vehicleDetails ? (vehicleDetails.category == 'road_worthy_vehicles' ? true : false) : true} onChange={() => setIsAccidental(false)} name="vehicle_category"/>
                                                <div className="form-check-label">Road Worthy Vehicles </div>
                                            </label>
                                            <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.category == 'accidental_or_faulty_vehicles' ? 1 : 0}>
                                                <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" id="accidental_or_faulty_vehicles" value="accidental_or_faulty_vehicles" defaultChecked={vehicleDetails && vehicleDetails.category == 'accidental_or_faulty_vehicles' ? true : false} onChange={() => setIsAccidental(true)} name="vehicle_category"/>
                                                <div className="form-check-label"> Accidental Or Faulty Vehicles </div>
                                            </label>
                                        </div>
                                    </div>  
                                    { context.role == 'admin' ? (<>
                                        <div className="col-sm-6 col-lg-3">
                                            <label htmlFor="seller_id" className="form-label mb-1 text-muted">Seller</label>
                                            <select className="form-select" id="seller_id" name="seller_id" onChange={handleInputChange}>
                                                <option value="">Select Seller</option>
                                                { seller ? ( seller.map((user, index) => {
                                                        return <option key={`seller_id_${index}`} value={user.id} selected={vehicleDetails && vehicleDetails.seller_id == user.id ? true : false}>{user.name} - {user.email}</option>
                                                    })
                                                ) : ''}
                                            </select>
                                            <div className="error seller_id-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div className="col-sm-6 col-lg-3">
                                            <label htmlFor="guide_price" className="form-label mb-1 text-muted">Guide Price</label>
                                            <input type="number" className="form-control" id="guide_price" name="guide_price" placeholder="Guide Price" defaultValue={vehicleDetails && vehicleDetails.sold_price}/>
                                            <div className="error guide_price-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                     </>) : <div className="col-sm-6"></div> }
                                    { isAccidental ? <>
                                        <div className="col-sm-6 col-lg-3">
                                            <label htmlFor="engine_start" className="form-label mb-3px text-muted d-block">Vehicle Engine Start</label>
                                            <div className={`form-control ${isShowDetails ? '' : 'border-0 p-0 mt-2'}`}>
                                                <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.engin_start == 'Yes' ? 1 : 0}>
                                                    <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name="engine_start" defaultChecked={vehicleDetails && vehicleDetails.engin_start == 'Yes' ? true : false} value="Yes"/>
                                                    <div className="form-check-label">Yes</div>
                                                </label>
                                                <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.engin_start == 'No' ? 1 : 0}>
                                                    <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name="engine_start" defaultChecked={vehicleDetails ? (vehicleDetails.engin_start == 'No' ? true : false) : true} value="No"/>
                                                    <div className="form-check-label">No</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-3">
                                            <label htmlFor="drive" className="form-label mb-3px text-muted d-block">Vehicle Drive</label>
                                            <div className={`form-control ${isShowDetails ? '' : 'border-0 p-0 mt-2'}`}>
                                                <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.drive == 'Yes' ? 1 : 0}>
                                                    <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name="drive" defaultChecked={vehicleDetails && vehicleDetails.drive == 'Yes' ? true : false} value="Yes"/>
                                                    <div className="form-check-label">Yes</div>
                                                </label>
                                                <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.drive == 'No' ? 1 : 0}>
                                                    <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name="drive" defaultChecked={vehicleDetails ? (vehicleDetails.drive == 'No' ? true : false) : true} value="No"/>
                                                    <div className="form-check-label">No</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="main_damages" className="form-label mb-1 text-muted">Main Damages</label>
                                            <input type="text" className="form-control" id="main_damages" name="main_damages" placeholder="" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.main_damages : ''}/>
                                            <div className="error main_damages-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                    </> : '' }
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_make" className="form-label mb-1 text-muted">Vehicle Make</label>
                                        <select className="form-select" id="vehicle_make" name="vehicle_make" onChange={handleInputChange}>
                                            <option value="">Select Vehicle Make</option>
                                            { vehicleSetting ? vehicleSetting.vehicle_make.map((item, index) => {
                                                return <option key={`vehicle_make_${index}`} value={item} selected={vehicleDetails && vehicleDetails.make == item ? true : false}>{item}</option>
                                            }) : '' }
                                        </select>
                                        <div className="error vehicle_make-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_model" className="form-label mb-1 text-muted">Vehicle Model</label>
                                        <select className="form-select" id="vehicle_model" name="vehicle_model" onChange={handleInputChange}>
                                            <option value="">Select Vehicle Model</option>
                                            { vehicleSetting ? vehicleSetting.vehicle_model.map((item, index) => {
                                                return <option key={`vehicle_model_${index}`} value={item} selected={vehicleDetails && vehicleDetails.model == item ? true : false}>{item}</option>
                                            }) : '' }
                                        </select>
                                        <div className="error vehicle_model-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_year" className="form-label mb-1 text-muted">Vehicle Year</label>
                                        <DatePicker 
                                            selected={vehicleYear}
                                            onChange={(date) => setVehicleYear(date)} 
                                            className="form-control form-dateicon"
                                            dateFormat="yyyy"
                                            maxDate={new Date()}
                                            showYearPicker
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_mileage" className="form-label mb-1 text-muted">Vehicle Mileage</label>
                                        <input type="text" className="form-control" id="vehicle_mileage" name="vehicle_mileage" placeholder="" onChange={handleInputChange} defaultValue={vehicleDetails ? (isShowDetails ? `${vehicleDetails.mileage} kms` : vehicleDetails.mileage) : ''}/>
                                        <div className="error vehicle_mileage-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_transmission" className="form-label mb-1 text-muted">Vehicle Transmission   </label>
                                        <select className="form-select" id="vehicle_transmission" name="vehicle_transmission" onChange={handleInputChange}>
                                            <option value="">Select Vehicle Transmission</option>
                                            { vehicleSetting ? vehicleSetting.vehicle_transmission.map((item, index) => {
                                                return <option key={`vehicle_transmission_${index}`} value={item} selected={vehicleDetails && vehicleDetails.transmission == item ? true : false}>{item}</option>
                                            }) : '' }
                                        </select>
                                        <div className="error vehicle_transmission-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="categoryname" className="form-label mb-3px text-muted d-block">Fuel Type</label>
                                        <div className={`form-control ${isShowDetails ? '' : 'border-0 p-0 mt-2'}`} key={`fuel_${isShowDetails}`}>
                                           { vehicleSetting ? vehicleSetting.fuel.map((item, index) => {
                                                return (
                                                    <label key={`fuel_${index}`} className="form-check form-check-inline mb-0" data-render={vehicleDetails && vehicleDetails.fuel == item ? 1 : 0}>
                                                        <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" name="fuel" defaultChecked={vehicleDetails ? (vehicleDetails.fuel == item ? true : false) : true} value={item} key={`fuel_input_${index}`}/>
                                                        <div className="form-check-label" key={`fuel_label_${index}`}>{item}</div>
                                                    </label>
                                                )
                                            }) : '' }
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_colour" className="form-label mb-1 text-muted">Vehicle Colour</label>
                                        <select className="form-select" id="vehicle_color" name="vehicle_color" onChange={handleInputChange}>
                                            <option value="">Select Vehicle Colour</option>
                                            { vehicleSetting ? vehicleSetting.vehicle_colour.map((item, index) => {
                                                return <option key={`vehicle_color_${index}`} value={item} selected={vehicleDetails && vehicleDetails.colour == item ? true : false}>{item}</option>
                                            }) : '' }
                                        </select>
                                        <div className="error vehicle_color-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="number_of_doors" className="form-label mb-1 text-muted">Number Of Doors</label>
                                        <select className="form-select" id="number_of_doors" name="number_of_doors">
                                            {[1,2,3,4,5,6,7,8,9,10].map((x, i) =>
                                                <option key={`number_of_doors_${i}`} selected={vehicleDetails && vehicleDetails.number_of_doors == x ? true : false}>{ x }</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="vehicle_type" className="form-label mb-1 text-muted d-block">Vehicle Type</label>
                                        <select className="form-select" id="vehicle_type" name="vehicle_type" onChange={handleInputChange}>
                                            <option value="">Select Vehicle Type</option>
                                            { vehicleSetting ? vehicleSetting.vehicle_type.map((item, index) => {
                                                return <option key={`vehicle_type_${index}`} value={item} selected={vehicleDetails && vehicleDetails.type == item ? true : false}>{item}</option>
                                            }) : '' }
                                        </select>
                                        <div className="error vehicle_type-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="number_of_owners" className="form-label mb-1 text-muted">Number Of Owners</label>
                                        <input type="text" className="form-control" id="number_of_owners" name="number_of_owners" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.number_of_owners : ''}/>
                                        <div className="error number_of_owners-error small text-danger px-2 py-1 d-none"></div>
                                        {/* <select className="form-select" id="number_of_owners" name="number_of_owners">
                                            {[1,2,3,4,5,6,7,8,9,10].map((x, i) =>
                                                <option key={`number_of_owners_${i}`} selected={vehicleDetails && vehicleDetails.number_of_owners == x ? true : false}>{ x }</option>
                                            )}
                                        </select> */}
                                    </div>

                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="city_of_registration" className="form-label mb-1 text-muted">City Of Registration</label>
                                        <select className="form-select" id="city_of_registration" name="city_of_registration" onChange={handleInputChange}>
                                            <option value="">Select City of Registration</option>
                                            <optgroup label="Popular Cities">
                                            { vehicleSetting ? vehicleSetting.vehicle_popular_city.map((item, index) => {
                                                return <option key={`vehicle_popular_city_${index}`} value={item} selected={vehicleDetails && vehicleDetails.city_of_registration == item ? true : false}>{item}</option>
                                            }) : '' }
                                            </optgroup>
                                            <optgroup label="Other Cities">
                                            { vehicleSetting ? vehicleSetting.vehicle_city.map((item, index) => {
                                                return <option key={`vehicle_city_${index}`} value={item} selected={vehicleDetails && vehicleDetails.city_of_registration == item ? true : false}>{item}</option>
                                            }) : '' }
                                            </optgroup>
                                        </select>
                                        <div className="error city_of_registration-error small text-danger px-2 py-1 d-none"></div>
                                    </div>

                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="date_of_registration" className="form-label mb-1 text-muted">Date Of Registration</label>
                                        <DatePicker 
                                            selected={registrationDate} 
                                            onChange={(date) => setRegistrationDate(date)} 
                                            className="form-control"
                                            maxDate={new Date()}
                                            dateFormat="MMM dd, yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="token_paid_till" className="form-label mb-1 text-muted">Token Paid Till</label>
                                        <DatePicker 
                                            selected={tokenPaidTill} 
                                            onChange={(date) => setTokenPaidTill(date)} 
                                            className="form-control"
                                            dateFormat="MMM, yyyy"
                                            showMonthYearPicker
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                        <div className="error token_paid_till-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="reserve_price" className="form-label mb-1 text-muted">Vehicle Reserve Price   </label>
                                        <div className="input-group">
                                            { isShowDetails ? <>
                                                <input type="text" className="form-control" id="reserve_price" name="reserve_price" onChange={handleInputChange} defaultValue={vehicleDetails ? `PKR ${vehicleDetails.reserve_price}` : 'PKR 0'}/>
                                            </> : <>
                                                <span className="input-group-text min-w38px justify-content-center">PKR</span>
                                                <input type="text" className="form-control" id="reserve_price" name="reserve_price" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.reserve_price : 0}/>
                                            </> }
                                            </div>
                                        <div className="error reserve_price-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="equipment" className="form-label mb-1 text-muted">Equipment</label>
                                        <input type="text" className="form-control" id="equipment" name="equipment" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.equipment : ''}/>
                                        <div className="error equipment-error small text-danger px-2 py-1 d-none"></div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3">
                                        <label htmlFor="number_plate" className="form-label mb-1 text-muted">Number Plate</label>
                                        <input type="text" className="form-control" id="number_plate" name="number_plate" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.number_plate : ''}/>
                                        <div className="error number_plate-error small text-danger px-2 py-1 d-none"></div>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="location" className="form-label mb-1 text-muted">Location</label>
                                        <input className="form-control gmap-search-field vehicle_input_location1" id="location" name="location" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.location : ''}/>
                                        <div className="error location-error small text-danger px-2 py-1 d-none"></div>

                                        <div className="set-mapbox">
                                            { isShowDetails ? '' : <GoogleMap/> }
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <label className="form-label mb-3px text-muted d-block">Ownership Document Present</label>
                                        <div className={`form-control ${isShowDetails ? '' : 'border-0 p-0 mt-2'}`}>
                                            <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.ownership_document_present == 0 ? 1 : 0}>
                                                <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" id="notpresent" defaultChecked={vehicleDetails ? (vehicleDetails.ownership_document_present == 0 ? true : false) : true} name="ownership_document_present" onClick={() => setIsOwnership(false)} value="0"/>
                                                <label className="form-check-label" htmlFor="notpresent">Not Present</label>
                                            </label>
                                            <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.ownership_document_present == 1 ? 1 : 0}>
                                                <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" id="present" name="ownership_document_present" defaultChecked={vehicleDetails && vehicleDetails.ownership_document_present == 1 ? true : false} onClick={() => setIsOwnership(true)} value="1"/>
                                                <label className="form-check-label" htmlFor="present">Present</label>
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className={`col-sm-4 ms-auto ${isOwnership === true ? '' : 'd-none'}`}>
                                        <label htmlFor="ownership_present_images" className="form-label mb-1 text-muted">{!isShowDetails ? 'Upload' : ''} Ownership Document Image</label>
                                        { !isShowDetails ? <>
                                        <div className="form-control form-control-file">
                                            <input type="file" id="ownership_present_images" accept="image/png, image/jpg, image/jpeg" name="ownership_present_images[]" onChange={(e) => handleUploadImage(e, 'ownership_present_images', vehicleDetails ? vehicleDetails.id : '')} multiple/>
                                            <div className="form-placehoder" data-title="Upload Multiple Images">
                                                <svg className="bi fs-30px opacity-40">
                                                    <use xlinkHref="#bi_image" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="error ownership_present_images-error small text-danger px-2 py-1 d-none"></div>
                                        </> : '' }
                                        { uploadImage != '' ? 
                                        <div className="form-fileview mt-1" id="ownership_present_images_preview"></div>
                                        : '' }
                                    </div>
                                    { context.role == 'seller_private' ? <>
                                        <div className={`col-sm-4 ${isOwnership === true ? '' : 'd-none'}`}>
                                            { !isShowDetails ? <>
                                            <label htmlFor="proof_of_ownership_present_images" className="form-label mb-1 text-muted">{!isShowDetails ? 'Upload' : ''} Proof Of Ownership Present Image</label>
                                            <div className="form-control form-control-file">
                                                <input type="file" id="proof_of_ownership_present_images" accept="image/png, image/jpg, image/jpeg" name="proof_of_ownership_present_images" onChange={(e) => handleUploadImage(e, 'proof_of_ownership_present_images', vehicleDetails ? vehicleDetails.id : '')}/>
                                                <div className="form-placehoder" data-title="Upload Single Images">
                                                    <svg className="bi fs-30px opacity-40">
                                                        <use xlinkHref="#bi_image" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="error proof_of_ownership_present_images-error small text-danger px-2 py-1 d-none"></div>
                                            </> : '' }
                                            { uploadImage != '' ? 
                                            <div className="form-fileview mt-1" id="proof_of_ownership_present_images_preview"></div>
                                            : '' }
                                        </div> 
                                    </> : '' } 

                                    <div className="col-12">
                                        <label htmlFor="vehicle_outside_images" className="form-label mb-1 text-muted">{!isShowDetails ? 'Upload' : ''} Vehicle Outside Image</label>
                                        { !isShowDetails ? <>
                                        <div className="form-control form-control-file">
                                            <input type="file" id="vehicle_outside_images" accept="image/png, image/jpg, image/jpeg" name="vehicle_outside_images[]" onChange={(e) => handleUploadImage(e, 'vehicle_outside_images', vehicleDetails ? vehicleDetails.id : '')}  multiple/>
                                            <div className="form-placehoder" data-title="Upload Multiple Images">
                                                <svg className="bi fs-30px opacity-40">
                                                    <use xlinkHref="#bi_image" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="error vehicle_outside_images-error small text-danger px-2 py-1 d-none"></div>
                                        </> : '' }

                                        { (uploadImage && input.includes('vehicle_outside_images')) || isShowDetails ||isEditDetails ? 
                                        <div className="form-fileview mt-1" id="vehicle_outside_images_preview"></div>
                                        :
                                            [1,2,3,4,5,6,7,8].map((i) =>
                                            <label key={i} className="ratio ratio-1x1 overflow-hidden rounded-10 mt-2 me-2 form-image" style={{'maxWidth': '78px'}}>
                                                <img src='/images/car.jpg' alt="Example Image" />
                                            </label>)
                                        }
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="vehicle_inside_images" className="form-label mb-1 text-muted">{!isShowDetails ? 'Upload' : ''} Vehicle Inside Image</label>
                                        { !isShowDetails ? <>
                                        <div className="form-control form-control-file">
                                            <input type="file" id="vehicle_inside_images" accept="image/png, image/jpg, image/jpeg" name="vehicle_inside_images[]" onChange={(e) => handleUploadImage(e, 'vehicle_inside_images', vehicleDetails ? vehicleDetails.id : '')} multiple/>
                                            <div className="form-placehoder" data-title="Upload Multiple Images">
                                                <svg className="bi fs-30px opacity-40">
                                                    <use xlinkHref="#bi_image" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="error vehicle_inside_images-error small text-danger px-2 py-1 d-none"></div>
                                        </> : '' }

                                        { (uploadImage && input.includes('vehicle_inside_images')) || isShowDetails || isEditDetails ? 
                                            <div className="form-fileview mt-1" id="vehicle_inside_images_preview"></div>
                                            :
                                           [1,2,3,4].map((i) =>
                                            <label key={i} className="ratio ratio-1x1 overflow-hidden rounded-10 mt-2 me-2 form-image" style={{'maxWidth': '78px'}}>
                                                <img src='/images/car.jpg' alt="Example Image" />
                                            </label>)
                                        }
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-2 g-3 g-sm-4 mb-4 mb-sm-3">
                                    <div className="col-12">
                                        <ul className="list-inline border p-3 bg-light rounded-10">
                                            <li className="list-inline-item fw-medium">Key:</li>
                                            <li className="list-inline-item me-4">
                                                <svg className="bi bi-3x me-1 text-success">
                                                    <use xlinkHref="#bi_checkright"></use>
                                                </svg> Ok
                                            </li>
                                            <li className="list-inline-item me-4">
                                                <svg className="bi bi-3x me-1 text-orange">
                                                    <use xlinkHref="#bi_exclamation"></use>
                                                </svg> Requires attention
                                            </li>
                                            <li className="list-inline-item me-4">
                                                <svg className="bi bi-3x me-1 text-danger">
                                                    <use xlinkHref="#bi_exclamation2"></use>
                                                </svg> Not working
                                            </li>
                                            <li className="list-inline-item me-4">
                                                <svg className="bi bi-3x me-1 text-muted">
                                                    <use xlinkHref="#bi_checkcross"></use>
                                                </svg> Not applicable
                                            </li>
                                            <li className="list-inline-item">
                                                <svg className="bi bi-3x me-1">
                                                    <use xlinkHref="#bi_question"></use>
                                                </svg> I don't know
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-2 g-3 g-sm-4 mb-4 mb-sm-5">
                                    <div className="col-12">
                                        <div className="legend-heading fw-medium text-blue h5 mmb-15px">Vehicle Mechanical Report</div>
                                    </div>
                                    <div className="col-12">
                                        <ul className="row mx-0 flex-row list-group list-group-flush">
                                            <ReportCheckIcon reportChecks={reportChecks} checked_value={vehicleDetails ? vehicleDetails.mechanical_report : "" } report_type="mechanical"/>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row row-cols-1 row-cols-md-2 g-3 g-sm-4 mb-4 mb-sm-5">
                                    <div className="col-12">
                                        <div className="legend-heading fw-medium text-blue h5 mmb-15px">Essential Checks</div>
                                    </div>
                                    <div className="col-12">
                                        <ul className="row mx-0 flex-row list-group list-group-flush">
                                            <ReportCheckIcon reportChecks={reportChecks} checked_value={vehicleDetails ? vehicleDetails.essential_checks : "" } report_type="essential"/>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-2 g-3 g-sm-4 mb-4 mb-sm-5">
                                    <div className="col-12">
                                        <div className="legend-heading fw-medium text-blue h5 mmb-15px">Interior checks</div>
                                    </div>
                                    <div className="col-12">
                                        <ul className="row mx-0 flex-row list-group list-group-flush">
                                            <ReportCheckIcon reportChecks={reportChecks} checked_value={vehicleDetails ? vehicleDetails.interior_checks : "" } report_type="interior"/>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="notes" className="form-label mb-1 text-muted">Notes</label>
                                        <textarea className="form-control" id="notes" name="notes" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.notes : ''}>
                                        </textarea>
                                        <div className="error notes-error small text-danger px-2 py-1 d-none"></div>
                                    </div>  
                                </div>
                                {context.role !== 'admin' ? 
                                    <div className="row g-3 g-sm-4 mb-4 mb-sm-5">
                                    <div className={`col-12 ${isShowDetails ? 'border-0' : ' border-0 mt-2'}`}>
                                        <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.deliver_vehicle_to_auction_site == 0 ? 1 : 0}>
                                            <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" id="free" value="0" name="vehicle_site" defaultChecked={vehicleDetails ? (vehicleDetails.deliver_vehicle_to_auction_site ? false : true) : true} onChange={() => setIsConditionReport(false)}/>
                                            <div className="form-check-label">Deliver the vehicle to QAuctions site</div>
                                        </label>
                                        <label className="form-check form-check-inline" data-render={vehicleDetails && vehicleDetails.deliver_vehicle_to_auction_site == 1 ? 1 : 0}>
                                            <input className={`form-check-input ${isShowDetails ? 'd-none' : ''}`} type="radio" id="pay" value="1" name="vehicle_site" defaultChecked={vehicleDetails && vehicleDetails.deliver_vehicle_to_auction_site ? true : false} onChange={() => setIsConditionReport(true)}/>
                                            <div className="form-check-label">Pay a refundable deposit if the car will be auctioned from the seller's site</div>
                                        </label>
                                    </div>
                                    {
                                        isConditionReport ? '' :
                                        <>
                                        <div className="col-sm-6 col-lg-3">
                                            <label htmlFor="city" className="form-label mb-1 text-muted">City</label>
                                            <input type="text" className="form-control" id="city" name="city" onChange={handleInputChange} defaultValue={vehicleDetails ? vehicleDetails.city : ''}/>
                                            <div className="error city-error small text-danger px-2 py-1 d-none"></div>
                                        </div>
                                        <div className="col-sm-6 col-lg-3">
                                            <label htmlFor="calender" className="form-label mb-1 text-muted">Vehicle Delivery Date</label>
                                            <DatePicker 
                                            selected={calender} 
                                            onChange={(date) => setCalender(date)} 
                                            className="form-control"
                                            dateFormat="MMM dd, yyyy"
                                            onChangeRaw={(e) => e.preventDefault()}
                                            />
                                        </div>
                                        </>
                                    }                   
                                    </div>
                                : ''}
                                <div className={`row row-cols-1 row-cols-md-2 g-3 g-sm-4 mb-4 mb-sm-5 ${isConditionReport ? '' : 'd-none'}`}>
                                    <div className="col-12">
                                        <div className="legend-heading fw-medium text-blue h5 mmb-15px">Vehicle Conditional Report</div>
                                    </div>
                                    <div className="col-12">
                                        <ul className="row mx-0 flex-row list-group list-group-flush">
                                            { reportChecks ? reportChecks.condition.map((item, i) => {
                                                return displayConditionReport(i, item, vehicleDetails ? vehicleDetails.condition_report[item.name]: '')
                                            }) : '' }
                                        </ul>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-2 g-3 g-sm-4 mb-4 mb-sm-5">
                                    <div className="col-12">
                                        <button type="submit" onClick={handleSubmitForm} className="btn btn-primary btn-lg px-5 showon-active" disabled={btnDisabled}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Vehicle
