import ReactDOM from 'react-dom';
import React from 'react'
import { useRef, useState } from 'react';
import DataTables from '../components/Datatable';

function InvoiceList() {

    const dt_reload = useRef()
    const [dt] = useState({
                            dt_url: 'invoice',
                            dt_name: 'invoice',
                            dt_column: [
                                { "data":"id", "title": "ID"},
                                { "data":"seller_id", "title":"Seller Id"},
                                { "data":"buyer_id", "title":"Buyer Id" },
                                { "data":"vehicle_id",  "title":"Vehicle Id" },
                                { "data":"bid_amount", "title":"Bid Amount" },
                                { "data":"percent", "title":"Percent" },
                                { "data":"platform_amount", "title":"Platform Amount" },
                                // { "data":"status", "title":"Status" },
                                { "data":"id", "title":"Action", searchable:false, sortable:false, class: 'text-end'}
                            ],
                            dt_column_defs: [
                                {
                                    targets: 7,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                            <button type="button" className="btn btn-xs btn-outline-secondary" title="Download Invoice" onClick={ () => handleChangeStatus(rowData.invoice)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_download" />
                                                </svg>
                                            </button>
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 5,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<><span> {rowData.percent+"%"}</span></>, td)
                                    },
                                },
                                {
                                    targets: 4,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<><span> PKR {rowData.bid_amount.toFixed(2)}</span></>, td)
                                    },
                                },
                                {
                                    targets: 6,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<><span> PKR {rowData.platform_amount.toFixed(2)}</span></>, td)
                                    },
                                },

                            ],
                            dt_paging: true,
                        })

    const handleChangeStatus = (pdf) => {
            // const linkSource = `data:application/pdf;base64,${pdf}`;
            // const downloadLink = document.createElement("a");
            // const fileName = "invoice.pdf";
        
            // downloadLink.href = linkSource;
            // downloadLink.download = fileName;
            // downloadLink.click();
            let pdfWindow = window.open("")
                pdfWindow.document.write(
                    "<iframe width='100%'s height='100%' src='data:application/pdf;base64, " +
                    encodeURI(pdf) + "'></iframe>"
                )
                pdfWindow.document.title = "Quality Car Auction | Invoice";
        
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row py-2 align-items-center">
                        <div className="col-sm-6 py-1">
                            <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                                <svg className="bi bi-1x me-2">
                                    <use xlinkHref="#bi_file"></use>
                                </svg>Invoice List
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <DataTables dt_data={dt} ref={dt_reload}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceList