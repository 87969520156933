import { Link } from "react-router-dom";
import GlobalContext from "./GlobalContext";
import { useContext } from 'react';

export default function DashboardTile({statistics, id}) {
    const [context, setContext] = useContext(GlobalContext)
    return (
        <div className="col">
            <div className={`card-tiles ${statistics.class} text-white p-3 p-sm-4`}>
                <h5 className="fw-medium">{statistics.title}</h5>
                <h4 className="flex-grow-1 fw-bold">{statistics.total}</h4>
                <div className="d-flex flex-wrap m-01 mt-3">
                {
                statistics.title === 'Total Vehicles' ? 
                    <>
                    {statistics.approved ? <Link to="/vehicle-list/approved" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block  text-white bg-success">Approved : {statistics.approved}</Link> : '' }
                    {statistics.pending ? <Link to="/vehicle-list/pending" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 text-body d-inline-block bg-warning">Pending : {statistics.pending}</Link> : '' }
                    {statistics.rejected ? <Link to="/vehicle-list/rejected" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block bg-danger">Rejected : {statistics.rejected}</Link> : '' }
                    {statistics.on_sale  ? <Link to="/vehicle-list/on_sale" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">On Sale : {statistics.on_sale}</Link> : '' }
                    {statistics.reserve_not_met  ? <Link to="/vehicle-list/reserve_not_met" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">Reserve Not Met : {statistics.reserve_not_met}</Link> : '' }
                    {statistics.sold  ? <Link to="/vehicle-list/pending_payment" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">Sold : {statistics.sold}</Link> : '' }
                    {statistics.paid  ? <Link to="/vehicle-list/paid" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">Paid : {statistics.paid}</Link> : '' }
                    {statistics.in_auction  ? <Link to="/vehicle-list/in_auction" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">In Auction : {statistics.in_auction}</Link> : '' }
                    {statistics.bid_vehicle  ? <Link to="/bid-vehicles" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block bg-opacity-75 text-white bg-success">Vehicles</Link> : '' }
                    </>
                    :
                    <>
                    {statistics.approved ? <Link to="/user/approved" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-white bg-success">Approved : {statistics.approved}</Link> : '' }
                    {statistics.pending ? <Link to="/user/pending" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 text-body d-inline-block bg-warning">Pending : {statistics.pending}</Link> : '' }
                    {statistics.rejected ? <Link to="/user/rejected" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block bg-danger">Rejected : {statistics.rejected}</Link> : '' }
                    {/* {statistics.on_sale  ? <span className="px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">On Sale : {statistics.on_sale}</span> : '' } */}
                    {/* {statistics.reserve_not_met  ? <span className="px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">Reserve Not Met : {statistics.reserve_not_met}</span> : ''} 
                    {statistics.sold  ? <span className="px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-body bg-info bg-opacity-75">Sold : {statistics.sold}</span> : '' } */}
                    </>
                }
                { statistics.title === 'Total Invoice' && <Link to="/invoice-list" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block  text-white bg-black">Go to Invoice List</Link> }
                {
                    statistics.title === 'Total Auction' ?
                    <>
                    {statistics.open ? <Link to="/auction-list?open" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block  text-white bg-success">Open : {statistics.open}</Link> : '' }
                    {statistics.closed ? <Link to="/auction-list?closed" className="btn px-2 py-1 m-2px fs-15px rounded-15 lh-1 text-body d-inline-block bg-warning">Closed : {statistics.closed}</Link> : '' }
                    </>
                    :''
                }
                    {statistics.total_vehicles ? <spna className="px-2 py-1 m-2px fs-15px rounded-15 lh-1 d-inline-block text-white bg-dark bg-opacity-50">Total Vehicles : {statistics.total_vehicles}</spna> : '' }
                    {context.role === 'buyer' ?
                        <>
                        {statistics.bid_sum ? <Link to={'/total-bids'} className="btn px-2 py-1 m-2px fs-15px text-white rounded-15 lh-1 d-inline-block bg-dark">Total Bid Amount : {statistics.bid_sum}</Link> : '' }
                        </>
                        : 
                        <>
                        {statistics.bid_sum ? <Link to={`total-bids`} className="btn px-2 py-1 m-2px fs-15px text-white rounded-15 lh-1 d-inline-block bg-dark">Total Bid Amount : {statistics.bid_sum}</Link> : '' }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}