import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import GlobalContext from "../components/GlobalContext";
import { fetchData, scrollbarSetToTop } from "../components/Helper";

function ContactUs(){
	const [context, setContext] = useContext(GlobalContext)
	const history = useHistory()
	const [data, setData] = useState({
				first_name: "",
				last_name: "",
				phone: "",
				email: "",
				message: ""
	})

	const handleInputChange = (e) => {
       document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }


	const handleSubmitForm=(e)=>{
		e.preventDefault();
		var reset = document.getElementById('contactForm')

		fetchData('contact', 'POST', data, false, false, (res) => {
            if(res.status) {
                setData(prevState => ({
                    first_name: "",
                    last_name: "",
                    phone: "",
                    email: "",
                    message: "",
                }))
                reset.reset()
            }
        }, (err) => {
            console.log(err)
        })
	}

	useEffect(() => {
        scrollbarSetToTop()
    }, []);

    return (
    <>
	<Helmet>
		<meta charSet="utf-8" />
		<title>Quality Car Auctions | Contact Us</title>
	</Helmet>
    <section className="py-5" style={{background: '#cfe2ff'}}>
		<div className="container py-sm-2">
			<div className="row justify-content-center text-center">
				<div className="col-sm-12 col-md-10 col-xl-8">
					<h2 className="h1 fw-bold w-75 mx-auto">
						Contact Us
					</h2>
					<nav className="d-flex justify-content-center" style={{'--bs-breadcrumb-divider': '&#10140;'}} aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
							<li className="breadcrumb-item active" aria-current="page">Contact Us</li>
						</ol>
					</nav>
				</div>
			</div>
			{context ? 
				<div className="row mt-3">
					<div className="col-md-5">
						<button className="btn btn-primary" onClick={()=> history.goBack()} role="button">
							<svg className="bi me-1">
								<use xlinkHref="#bi_arrowleft" />
							</svg>
							Go Back
						</button>
					</div>
				</div>
				: ''
			}
		</div>
	</section>

	<section className="py-4 py-sm-5">
		<div className="container">
			<div className="row row-cols-1 row-cols-lg-2 g-4">
				<div className="col py-4 pe-lg-5">
					<form className="row" id="contactForm">
						<div className="col-sm-12">
							<h2 className="fw-medium">Leave a message</h2>
							<p className="opacity-80">
								Use the form below to leave us a message, or alternatively, scroll down this page to see our contact numbers for each department, locations, and more.
							</p>
						</div>
						<div className="col-sm-6 mb-3">
							<label htmlFor="exampleFormControlInput1" className="form-label mb-1 text-muted">Enter First Name</label>
							<input type="text" className="form-control" name="first_name" onChange={handleInputChange} id="first_name"/>
							<div className="error first_name-error small text-danger px-2 py-1 d-none"></div>
						</div>
						<div className="col-sm-6 mb-3">
							<label htmlFor="exampleFormControlInput2" className="form-label mb-1 text-muted">Enter Last Name</label>
							<input type="text" className="form-control" name="last_name" onChange={handleInputChange} id="last_name"/>
							<div className="error last_name-error small text-danger px-2 py-1 d-none"></div>
						</div>
						<div className="mb-3">
							<label htmlFor="exampleFormControlInput3" className="form-label mb-1 text-muted">Email address</label>
							<input type="email" className="form-control" name="email" onChange={handleInputChange} id="email"/>
							<div className="error email-error small text-danger px-2 py-1 d-none"></div>
						</div>
						<div className="mb-3">
							<label htmlFor="exampleFormControlInput4" className="form-label mb-1 text-muted">Phone Number</label>
							<input type="num" className="form-control" name="phone" onChange={handleInputChange} id="phone"/>
							<div className="error phone-error small text-danger px-2 py-1 d-none"></div>
						</div>
						<div className="mb-3">
							<label htmlFor="exampleFormControlTextarea5" className="form-label mb-1 text-muted">Message</label>
							<textarea className="form-control" name="message" onChange={handleInputChange} id="message" rows="3"></textarea>
							<div className="error message-error small text-danger px-2 py-1 d-none"></div>
						</div>
						<div className="d-block">
							<button type="submit" onClick={handleSubmitForm} className="btn btn-primary px-5">Submit</button>
						</div>
					</form>
				</div>

				<div className="col py-4">
					<div className="p-3 p-sm-4 bg-dark rounded-10 text-white d-flex flex-column justify-content-center align-items-center h-100">
						<h2 className="d-flex mt-3">
							<svg className="bi bi-3x me-3">
								<use xlinkHref="#bi_question" />
							</svg>
							Looking for these?
						</h2>
						<div className="d-flex flex-column w-75 mx-auto mt-4">
							<a className="btn btn-light d-flex align-items-center mb-3" role="button">
								<svg className="bi bi-4x me-3">
									<use xlinkHref="#bi_question" />
								</svg>
								Location
							</a>
							<Link className="btn btn-light d-flex align-items-center mb-3" to="/privacy-policy" role="button">
								<svg className="bi bi-4x me-3">
									<use xlinkHref="#bi_question" />
								</svg>
								Terms
							</Link>
							<Link className="btn btn-light d-flex align-items-center mb-3" to={context.role === 'buyer' ? "/register" : "/vehicle-list/sell-a-vehicle"} onClick={() => localStorage.setItem('redirect_path', 'vehicle-list/sell-a-vehicle')} role="button">
								<svg className="bi bi-4x me-3">
									<use xlinkHref="#bi_question" />
								</svg>
								Sell a Vehicle
							</Link>
							<Link className="btn btn-light d-flex align-items-center mb-3" to="/scrap-a-vehicle" role="button">
								<svg className="bi bi-4x me-3">
									<use xlinkHref="#bi_question" />
								</svg>
								Scrap a Vehicle
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="row row-cols-1 row-cols-md-2 g-4 py-5 mt-4">
				<div className="col">
					<div className="text-center shadow-sm h-100 p-3 p-sm-4">
						<svg className="bi bi-2x fs-1 mb-3">
							<use xlinkHref="#bi_location"></use>
						</svg>
						<h5 className="fw-medium mb-3">Postal Address</h5>
						<address className="text-muted">
							Quality Car Auctions ,<br/>
							(Near Grammar Public School), <br/>
							Sialkot Byepass , Gujranwala, Punjab
						</address>
					</div>
				</div>
				<div className="col">
					<div className="text-center shadow-sm h-100 p-3 p-sm-4">
						<svg className="bi bi-2x fs-1 mb-3">
							<use xlinkHref="#bi_phone"></use>
						</svg>
						<h5 className="fw-medium mb-3">Website and Sales Enquiries</h5>
						<div className="text-muted">
							<strong>Tel1:</strong> 0313 7001002<br/>
							<strong>Tel2:</strong> 0322 6443232<br/>
							<strong>Email:</strong> <a href="mailto:technical@motorauction.com">enquiries@qcarauctions.com</a>
						</div>
					</div>
				</div>
				{/* <div className="col">
					<div className="text-center shadow-sm h-100 p-3 p-sm-4">
						<svg className="bi bi-2x fs-1 mb-3">
							<use xlinkHref="#bi_mail"></use>
						</svg>
						<h5 className="fw-medium mb-3">Private Sales</h5>
						<div className="text-muted">
							<strong>Tel:</strong> 01226 123456<br/>
							<strong>Fax:</strong> 01226 145414<br/>
							<strong>Email:</strong> <a href="mailto:technical@motorauction.com">technical@motorauction.com</a>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	</section>
    </>
    )
}

export default ContactUs