import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { scrollbarSetToTop } from '../components/Helper'

function AboutUs(){

	useEffect(() => {
        scrollbarSetToTop()
    }, []);

    return (
    <>
	<Helmet>
		<meta charSet="utf-8" />
		<title>Quality Car Auctions | About Us</title>
	</Helmet>
    <section className="py-5" style={{background: '#cfe2ff'}}>
		<div className="container py-sm-2">
			<div className="row justify-content-center text-center">
				<div className="col-sm-12 col-md-10 col-xl-8">
					<h2 className="h1 fw-bold w-75 mx-auto">
						About Us
					</h2>
					<nav className="d-flex justify-content-center" style={{'--bs-breadcrumb-divider': '&#10140;'}} aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
							<li className="breadcrumb-item active" aria-current="page">About Us</li>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	</section>

	<section className="py-5">
		<div className="container">
			<div className="row">
				<div className="col-sm-12 col-md-4 col-lg-4 pe-4">
					<div className="image-tiled image-tiled-reverse bg-blackis rounded-10">
						<img src="images/car6.jpg" className="w-100" alt="car"/>
					</div>
				</div>
				<div className="col-sm-12 col-md-8 col-lg-8 ps-5 opacity-80 lh-lg">
					<p>
					   Working with the public and private sectors, we manage the sale, de-fleet, or scrappage of vehicles from A to Z. We are open for any kind of vehicle on Quality Car Auctions site and ensure the safe storage of all the vehicles in stock until it gets sold, from no damage vehicles in perfect running condition to accident damaged or faulty vehicles, we can auction them all.
					</p>
					<p>
						We also offer an all-in-one solution to resale or recycling of vehicles.
						We not only guarantee keen commercial pricing and excellent service levels but meet the highest environmental standards and all compliance obligations around the correct disposal of vehicles.
					</p>
					<p>
						Motor Auction is creating virtually social impact with every contract it wins. Seller can sell vehicles and track the progress of their vehicles in an online portal,
					    where Buyers can bid from anywhere in the world if they have access to internet,
						and can track the progress of winning bids in their personal online portal at Quality Car Auctions website	
					</p>
				</div>
			</div>
		</div>
	</section>
    </>
    )
}

export default AboutUs