import 'datatables.net';
import $ from 'jquery';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

const API_URL = process.env.REACT_APP_API_URL

function DataTables(props, ref) {
    useEffect(() => {
        loadDataTable();
        if(document.querySelector('.modal-backdrop')){
            document.querySelector('.modal-backdrop').remove();
         }
    }, []);

    const loadDataTable = (reload_type = 'reload', url) => {
        if ($.fn.DataTable.isDataTable('#ma_datatable_'+props.dt_data.dt_name)) {
            if(reload_type == 'reload'){
                if(url){
                    $('#ma_datatable_'+props.dt_data.dt_name).DataTable().clear().ajax.url(API_URL+url).load()
                }else{
                    $('#ma_datatable_'+props.dt_data.dt_name).DataTable().clear().ajax.reload(null, false);
                }
            }else{
                $('#ma_datatable_'+props.dt_data.dt_name).DataTable().clear().draw(null, false);
            }
        }else{
            $('#ma_datatable_'+props.dt_data.dt_name).DataTable({
                dom: "<'row g-2'<'col-md-12 col-lg-2 text-center text-lg-start'l><'#dt-custom-filter.col-md-12 col-lg-8 text-center text-lg-end'><'col-md-12 col-lg-2 text-center text-lg-end'f>>" +
                    "<'row'<'col-sm-12'<'table-responsive position-relative my-2' tr>>>" +
                    "<'row g-2'<'col-md-12 col-lg-5 text-center text-lg-start'i><'col-md-12 col-lg-7 text-center text-lg-end'p>>",
                language: {
                    searchPlaceholder: "Search..."
                },
                processing: true,
                serverSide: true,
                paging: props.dt_data.dt_paging,
                ajax: {
                    url: API_URL+props.dt_data.dt_url,
                    headers: {
                        "Authorization": 'Bearer ' + localStorage.getItem("ma-token")
                    },
                    data: (d) => {
                        d.s_filter = $('input[name=dt_filter_status]:checked').val();
                    }
                },
                columns: props.dt_data.dt_column,
                columnDefs: props.dt_data.dt_column_defs, 
                order: [[ 0, 'desc' ]],
                initComplete: props.dt_data.dt_filter,
                createdRow: props.dt_data.dt_createdRow
            })
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            reloadDatatable(reload_type, url=false) {
                loadDataTable(reload_type, url)
            }
        }),
    )

    return (
        <>
        <table id={`ma_datatable_${props.dt_data.dt_name}`} className="table table-hover align-middle table-lasttd-end text-nowrap">
            <thead className="bg-light"></thead>
        </table>            
        </>
    )
}

export default forwardRef(DataTables)