import { now, startCase, toInteger } from "lodash";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { isNumberKey, showAlert } from "../components/Helper";
import DatePicker from "react-datepicker";
import { useState } from "react";

export const ButtomContent = () => {
    return (
    <div className="row row-cols-2 row-cols-md-2 row-cols-xl-2 g-4 my-4">
        <div className="col">
            <h2 className="fw-medium">Car Auctions Online at Motor Auction</h2>
            <p className="opacity-80 lh-base">
                With over 20 years of experience, Quality Car Auctions is Pakistan’s first online platform for vehicle auctions open to public. <br/><br/>
                We have a dizzying range of choice available in our online vehicle auctions, spanning a variety of used, seized and salvage vehicles.<br/><br/>
                It couldn’t be simpler to get started. All you need to do is search, sign up and start bidding immediately! From there, you can take your pick from cars, vans and light commercial vehicles. What’s more, we regularly refresh our stock in weekly online vehicle auctions. If you don’t find what you’re looking for at first, don’t forget to check back!
            </p>
        </div>
        <div className="col">
            <h2 className="fw-medium">Motor Auction - the leading site for online car auctions</h2>
            <p className="opacity-80 lh-base">
                There are all sorts of reasons to choose Quality Car Auctions. For starters, we don’t hold dealer-only auctions, so you don’t need to have any special qualifications to start bidding. We’ve also carefully designed all our listings to be explicitly clear and easy to understand, so that you can get all the key information at a glance.
                <br/><br/>
                Many of our used vehicles are driveable and in good condition. Our salvage vehicles, on the other hand, have sometimes been involved in accidents, and are therefore sold with slight to significant damage. In some cases, they may have suffered certain mechanical or electrical failures, leaving other aspects (such as the shell and bodywork) completely intact.
                <br/><br/>
                The vast range of choice alone here at QCA means that we service a wide range of customers, including:
            </p>
            <ul className="opacity-80 lh-base">
                <li>New drivers looking for a second-hand car</li>
                <li>Mechanically minded customers looking to ‘fix up’ and sell on cars</li>
                <li>Professional dealers</li>
                <li>Private customers searching for a certain make or model that might be otherwise difficult to find</li>
                <li>Those simply looking for vehicles to take apart for spare parts</li>
            </ul>
            <p className="opacity-80 lh-base">
                In our online vehicle auctions, you’ll find makes and models from leading global manufacturers such as  <a href="#">Toyota</a>, <a href="#">Honda</a>, <a href="#">Nissan</a>, <a href="#">Suzuki</a>, <a href="#">Kia</a>, <a href="#">Audi</a>, <a href="#">Renault</a>, <a href="#">Mercedes</a> and more!
            </p>
        </div>
    </div>
    )
}

export const TopContent = () => {
    return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Quality Car Auctions | Vehicle List</title>
        </Helmet>
        <section className="bg-light1 py-5" style={{background: '#cfe2ff'}}>
            <div className="container py-sm-2">
                <div className="row justify-content-center text-center">
                    <div className="col-sm-12 col-md-10 col-xl-8">
                        <h2 className="h1 fw-bold w-75 mx-auto">
                            Vehicle List
                        </h2>
                        <nav className="d-flex justify-content-center" style={{'--bs-breadcrumb-divider': '&#10140;'}} aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Vehicle List</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export const SelectVehicleType = (props) => {
    return (
        <div className="row justify-content-center text-center cat-btn">
            <div className="col-sm-12 col-md-10 col-xl-8">
                <ul className="nav nav-pills nav-pills-common mb-3 justify-content-center" id="pills-tab" role="tablist">
                    <li className="nav-item me-2" role="presentation">
                        <button className={ props.category === 'road_worthy_vehicles' ? "nav-link fw-medium active" : "nav-link fw-medium"} id="road_worthy_vehicles" onClick={() => props.setCategoryData('road_worthy_vehicles')} type="button">
                            <span className="d-inline-block rounded-circle form-check-input me-1"></span>
                            Road worthy vehicles
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={ props.category === 'accidental_or_faulty_vehicles' ? "nav-link fw-medium active" : "nav-link fw-medium"} id="accidental_or_faulty_vehicles" onClick={() => props.setCategoryData('accidental_or_faulty_vehicles')}  type="button">
                            <span className="d-inline-block rounded-circle form-check-input me-1"></span>
                            Accidental or faulty vehicles
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export const SearchVehicle = (props) => {
    return (
        <div className="search-box p-2">
            <div className="position-relative">
                <span className="position-absolute search-icon1 start-0 top-0 mt-1 ms-1 opacity-50">
                    <svg className="bi fs-13px">
                        <use xlinkHref="#bi_search" />
                    </svg>
                </span>
                <input type="text" className="form-control form-control-sm ps-4" name={props.name} defaultValue={props.value} placeholder={props.placeholder} onChange={props.filter}/>
            </div>
        </div>
    )
}

export const CheckboxFilter = (props) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target={`#${props.name}_flush`}>
                    {startCase(props.name)}
                </button>
            </h2>
            <div id={`${props.name}_flush`} className="accordion-collapse collapse" data-bs-parent="#flushFilter">
                <div className="accordion-body p-2">
                { props.data.map((item, index) => {
                        return(
                        <label className="form-check" key={`${props.name}_${index}`}>
                            <input className="form-check-input" type="checkbox" value={item} name={`${props.name}[]`} defaultChecked={props.value.includes(item)} onChange={props.filter}/>
                            <div className="form-check-label">{item}</div>
                        </label>
                        )
                }) }
                </div>
            </div>
        </div>
    )
}

export const RadioFilter = (props) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target={`#${props.name}_flush`}>
                    {props.title}
                    {/* <span>{props.title} {props.value && <svg className="bi text-success"><use xlinkHref="#bi_checkright" /></svg>}</span> */}
                </button>
            </h2>
            <div id={`${props.name}_flush`} className="accordion-collapse collapse" data-bs-parent="#flushFilter">
                <div className="accordion-body p-2">
                <label className="form-check">
                    <input className="form-check-input" value="" type="radio" name={props.name} defaultChecked={props.value === ""} onChange={props.filter}/>
                    <div className="form-check-label">All</div>
                </label>
                { !props.preDefinedData && props.data.map((item, index) => {
                        return(
                        <label className="form-check" key={`${props.name}_${index}`}>
                            <input className="form-check-input" type="radio" value={item.value} name={props.name} defaultChecked={props.value === item.value} onChange={props.filter}/>
                            <div className="form-check-label">{item.label}</div>
                        </label>
                        )
                }) }

                { props.preDefinedData && props.data.map((item, index) => {
                        return(
                            <label className="form-check" key={`${props.name}_${index}`}>
                                <input className="form-check-input" value={item} type="radio" name={props.name} defaultChecked={props.value === item} onChange={props.filter}/>
                                <div className="form-check-label">{item}</div>
                            </label>
                        )
                }) }
                </div>
            </div>
        </div>
    )
}

export const FilterHeader = (props) => {
    const [sort, setSort] = useState(props.price ?? 'ASC');

    const changeGrid = (value) => {
        if(value) document.getElementById("listgridView").classList.add("row-cols-full")
        else document.getElementById("listgridView").classList.remove("row-cols-full")
    }

    return (
        <div className="row">
            <div className="col-auto mb-3">
                <h2 className="h6 fw-medium">View Style:</h2>
                <div className="d-flex">
                    <input type="radio" className="btn-check" name="list-grid" id="listviewing" autoComplete="off" onChange={() =>changeGrid(true)} defaultChecked/>
                    <label className="btn btn-sm btn-outline-secondary me-1" htmlFor="listviewing">
                        <svg className="bi bi-4x me-1">
                            <use xlinkHref="#bi_list" />
                        </svg> List
                    </label>
                    <input type="radio" className="btn-check" name="list-grid" id="gridviewing" autoComplete="off" onChange={() =>changeGrid(false)}/>
                    <label className="btn btn-sm btn-outline-secondary" htmlFor="gridviewing">
                        <svg className="bi me-1">
                            <use xlinkHref="#bi_grid" />
                        </svg>
                        Grid
                    </label>
                </div>
            </div>
            <div className="col-auto mb-3 ms-auto">
                <h2 className="h6 fw-medium">Sort by price:</h2>
                <div className="d-flex">
                    <input type="hidden" form="filterForm" name="sort_by_price" value={sort}  />
                    <button type="button" value="ASC" form="filterForm" onClick={() => {setSort('ASC'); setTimeout(() => props.filter(now), 0);}} className={sort === 'ASC' ? "btn btn-sm btn-secondary me-1" : "btn btn-sm btn-light border me-1"}>Low ⟺ High</button>
                    <button type="button" value="DESC" form="filterForm" onClick={() => {setSort('DESC'); setTimeout(() => props.filter(now), 0);}} className={sort === 'DESC' ? "btn btn-sm btn-secondary" : "btn btn-sm btn-light border"}>High ⟺ Low</button>
                </div>
            </div>
            <div className="col-auto mb-3 text-end">
                <h2 className="h6 fw-medium">Page</h2>
                <select className="form-select form-select-sm" id="pagination" form="filterForm" name="page" defaultValue={props.page} onChange={props.filter}>
                    {[1,2,3,4,5,6,7,8,9,10].map((p, i) => (props.page > i) && <option key={i}>{p}</option> )}
                </select>
            </div>
        </div>
    )
}

export const VehiclePart = (props) => {
    return (
        <div className={props.col ?? "col"} style={props.style}>
            <div className="bg-light py-2 h-100">
                <div className="small fs-13px lh-sm">{props.title}</div>
                <div className="small fs-13px lh-sm text-dark">{props.value}</div>
            </div>
        </div>
    )
}

export const FilteredData = (props) => {
    return (
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-full g-4" id="listgridView">
            { props.data.map((item, index) => {
                    return (
                        
                        <div className="col" key={index}>
                            <Link to={{pathname: `/vehicle/details/${item.id}`, state: item.current_auction_id}}  className="card overflow-hidden rounded-lg text-decoration-none h-100">
                                <div className="ratio ratio-4x3">
                                    <div className="d-flex">
                                        <img src={item.vi_image} className="w-100 img-cover" alt="..."/>
                                        <div className="ratio-gradient">
                                            <h4 className="text-in2line fw-light">{item.make}</h4>
                                            <div className="fs-15px text-light">{item.model}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body flex-grow-1">
                                    <div className="row row-cols-3 g-2 text-center">
                                        { item.category === 'accidental_or_faulty_vehicles' && <VehiclePart title="Engin Starts &amp; Drive" value={item.engin_start === 'Yes' && item.drive === 'Yes' ? 'Yes' : 'No' } /> }
                                        <VehiclePart title="Token Paid Till" value={item.token_paid_till} />
                                        <VehiclePart title="Registered City" value={item.city_of_registration} />
                                        <VehiclePart title="Mileage" value={`${item.mileage} kms`} />
                                        <VehiclePart title="Transmission" value={item.transmission} />
                                        <VehiclePart title="Fuel Type" value={item.fuel} />
                                        <VehiclePart title="Guide Price" value={item.sold_price} />
                                        <VehiclePart title="Vehicle Year" value={item.year} />
                                        <VehiclePart title="Location" value={item.location} col={item.category != 'accidental_or_faulty_vehicles' ? "col-8" : false} />
                                    </div>
                                </div>
                                <div className="card-footer border-0 bg-light py-1 rounded-0 align-items-center" id="card-footer">
                                    <div className="text-center me-1">
                                        <h6 className="text-dark fw-light mt-0 mb-1 fs-12px text-nowrap">Current Bid:</h6>
                                        <h4 className="text-nowrap fw-bold m-0 fs-14px text-nowrap">{item.bid_amount}</h4>
                                    </div>
                                    
                                    <div className="text-danger py-1 fs-11px text-center" id="card-footerp">
                                        <p className="mb-0 text-nowrap">Auction end date:</p>
                                        <p className="fw-medium mb-0 text-nowrap">{item.auction_end_date}</p>
                                    </div>

                                    <div className="text-center me-1">
                                        <button type="button" className="btn btn-primary text-nowrap px-1 py-1 fs-12px ms-2" id="card-footerbutton">
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const CityFilter = (props) => {
    return (
        <div className="city-box p-2">
            <div className="position-relative">
                <select className="form-select" id={props.name} name={props.name} onChange={props.filter} defaultValue={props.value}>
                    <option value="" className="text-secondary">Select City</option>
                    <optgroup label="Popular Cities">
                        { props.popular_city.map((item, index) => <option key={`vehicle_popular_city_${index}`} value={item} >{item}</option>) }
                    </optgroup>
                    <optgroup label="Other Cities">
                        { props.city.map((item, index) => <option key={`vehicle_city_${index}`} value={item} >{item}</option>) }
                    </optgroup>
                </select>
            </div>
        </div>
    )
}

export const PriceFilter = (props) => {

    const priceFilter = () => {
        let min_price = toInteger(document.getElementById("min_price_range").value);
        let max_price = toInteger(document.getElementById("max_price_range").value);
        if(min_price > max_price){
            showAlert({message: 'The maximum price must be greater than the minimum price.'})
        }else{
            props.filter(now)
        }
    }

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush1">Price</button>
            </h2>
            <div id="flush1" className="accordion-collapse collapse" data-bs-parent="#flushFilter">
                <div className="accordion-body p-2">
                    <div className="d-flex">
                        <div className="col-4 pt-2">Min</div>
                        <div className="col-8 pt-1">
                            <input type="text" className="form-control form-control-sm mb-2" name="min_price_range" id="min_price_range" placeholder="Minimum price" defaultValue={props.min_price_range} onKeyDown={isNumberKey} />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-4 pt-2">Max</div>
                        <div className="col-8 pt-1">
                        <input type="text" className="form-control form-control-sm" name="max_price_range" id="max_price_range" placeholder="Maximum price" defaultValue={props.max_price_range} onKeyDown={isNumberKey} />
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type="button" className="btn btn-primary text-nowrap px-1 py-1 fs-12px mt-2 ms-auto" onClick={priceFilter}>Submit </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const YearFilter = (props) => {
    const [minYear, setMinYear] = useState(props.min_year ? new Date(props.min_year) : null);
    const [maxYear, setMaxYear] = useState(props.max_year ? new Date(props.max_year) : null);

    const setYearCalenderHeader = () => {
        setTimeout(() => {
            document.querySelectorAll(".custom-year-filter .react-datepicker-year-header").forEach(e => e.innerHTML = "Select Year")
        }, 0);
    }

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush17">Year</button>
            </h2>
            <div id="flush17" className="accordion-collapse collapse" data-bs-parent="#flushFilter">
                <div className="accordion-body p-2 custom-year-filter">
                    <div className="d-flex">
                        <div className="col-4 pt-2">From</div>
                        <div className="col-8">
                        <DatePicker 
                            selected={minYear}
                            onChange={(date) => {setMinYear(date); setTimeout(() => props.filter(now), 0);}} 
                            className="form-control form-dateicon"
                            dateFormat="yyyy"
                            maxDate={maxYear ?? new Date()}
                            yearItemNumber={8}
                            name="min_year"
                            isClearable
                            showYearPicker
                            onChangeRaw={(e) => e.preventDefault()}
                            onFocus={setYearCalenderHeader}
                            onYearChange={setYearCalenderHeader}
                        />
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                        <div className="col-4 pt-2">To</div>
                        <div className="col-8">
                        <DatePicker 
                            selected={maxYear}
                            onChange={(date) => {setMaxYear(date); setTimeout(() => props.filter(now), 0);}} 
                            className="form-control form-dateicon"
                            dateFormat="yyyy"
                            maxDate={new Date()}
                            yearItemNumber={8}
                            minDate={minYear ?? new Date()}
                            name="max_year"
                            isClearable
                            showYearPicker
                            onChangeRaw={(e) => e.preventDefault()}
                            onFocus={setYearCalenderHeader}
                            onYearChange={setYearCalenderHeader}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const TokenPaidTill = (props) => {
    const [tokenPaidTill, setTokenPaidTill] = useState(props.token_paid_till ? new Date(props.token_paid_till) : null);

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush16">
                    Token Paid Till
                </button>
            </h2>
            <div id="flush16" className="accordion-collapse collapse" data-bs-parent="#flushFilter">
                <div className="accordion-body p-2">
                    <DatePicker 
                        selected={tokenPaidTill} 
                        onChange={(date) => {setTokenPaidTill(date); setTimeout(() => props.filter(now), 0);}} 
                        className="form-control"
                        dateFormat="MMM, yyyy"
                        showMonthYearPicker
                        name="token_paid_till"
                        isClearable
                        onChangeRaw={(e) => e.preventDefault()}
                    />
                </div>
            </div>
        </div>
    )
}

export const OdoMeter = (props) => {
    const mileageFilter = () => {
        let min_price = toInteger(document.getElementById("min_mileage_range").value);
        let max_price = toInteger(document.getElementById("max_mileage_range").value);
        if(min_price > max_price){
            showAlert({message: 'The maximum mileage must be greater than the minimum mileage.'})
        }else{
            props.filter(now)
        }
    }

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush2">Odometer</button>
            </h2>
            <div id="flush2" className="accordion-collapse collapse" data-bs-parent="#flushFilter">
                <div className="accordion-body p-2">
                    <div className="d-flex">
                        <div className="col-4 pt-2">From</div>
                        <div className="col-8 pt-1">
                            <input type="text" className="form-control form-control-sm mb-2" name="min_mileage_range" id="min_mileage_range" placeholder="" defaultValue={props.min_mileage_range} onKeyDown={isNumberKey} />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-4 pt-2">To</div>
                        <div className="col-8 pt-1">
                        <input type="text" className="form-control form-control-sm" name="max_mileage_range" id="max_mileage_range" placeholder="" defaultValue={props.max_mileage_range} onKeyDown={isNumberKey} />
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type="button" className="btn btn-primary text-nowrap px-1 py-1 fs-12px mt-2 ms-auto" onClick={mileageFilter}>Submit </button>
                    </div>
                </div>
            </div>
        </div>
    )


    // return (
    //     <div className="accordion-item">
    //         <h2 className="accordion-header">
    //             <button className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush2" aria-expanded="false">Odometer</button>
    //         </h2>
    //         <div id="flush2" className="accordion-collapse collapse" data-bs-parent="#flushFilter">
    //             <div className="accordion-body p-2">
    //                 <label className="form-check">
    //                     <input className="form-check-input" type="checkbox" value="0-50" name="mileage[]" defaultChecked={props.odometer.includes("0-50")} onChange={props.filter}/>
    //                     <div className="form-check-label">
    //                         Less than 50 Kms
    //                     </div>
    //                 </label>
    //                 <label className="form-check">
    //                     <input className="form-check-input" type="checkbox" value="50-80" name="mileage[]" defaultChecked={props.odometer.includes("50-80")} onChange={props.filter}/>
    //                     <div className="form-check-label">
    //                         50 - 80 Kms
    //                     </div>
    //                 </label>
    //                 <label className="form-check">
    //                     <input className="form-check-input" type="checkbox" value="80-100" name="mileage[]" defaultChecked={props.odometer.includes("80-100")} onChange={props.filter}/>
    //                     <div className="form-check-label">
    //                         80 - 100 Kms
    //                     </div>
    //                 </label>
    //                 <label className="form-check">
    //                     <input className="form-check-input" type="checkbox" value="100-1000000" name="mileage[]" defaultChecked={props.odometer.includes("100-1000000")} onChange={props.filter}/>
    //                     <div className="form-check-label">
    //                         100 Kms or more
    //                     </div>
    //                 </label>
    //             </div>
    //         </div>
    //     </div>
    // )
}