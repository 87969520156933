import { useContext, useState } from "react";
import { fetchData } from '../components/Helper'
import GlobalContext from "../components/GlobalContext";

function ChangePassword(){
    const [context, setContext] = useContext(GlobalContext);
    const [data, setData] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    })
    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmitForm = () => {
        var reset = document.getElementById('changePasswordForm')

        fetchData(`user/change/password/${context.id}`, 'POST', data, true, false, (res) => {
            if(res.status) {
                setData({
                    current_password: '',
                    new_password: '',
                    confirm_password: ''
                })
                reset.reset()
            }
        }, (err) => {
            //console.log(err)
        })
    }


    return (
    <>
    <div className="content-wrapper">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-sm-12 py-3">
                    <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                        <svg className="bi bi-1x me-2">
                            <use xlinkHref="#bi_shieldlock"></use>
                        </svg>Change Password
                    </h6>
                </div>
            </div>
            <div className="row justify-content-center m-0 g-3" style={{'border': '1px solid #e9e9ef'}}>
                <div className="col-sm-12 col-md-8 col-xl-7 col-xxl-4">
                    <div className="row justify-content-center g-4">
                    <form className="row g-3" id="changePasswordForm">
                        <div className="col-sm-12">
                            <label htmlFor="currentPassword" className="form-label text-muted mb-1">Current Password</label>
                            <input type="password" className="form-control" name="current_password" id="currentPassword" onChange={handleInputChange}/>
                            <div className="error current_password-error small text-danger px-2 py-1 d-none"></div>
                        </div>
                        <div className="col-sm-12">
                            <label htmlFor="newPassword" className="form-label text-muted mb-1">New Password</label>
                            <input type="password" className="form-control" name="new_password" id="newPassword" onChange={handleInputChange}/>
                            <div className="error new_password-error small text-danger px-2 py-1 d-none"></div>
                        </div>
                        <div className="col-sm-12">
                            <label htmlFor="newconfPassword" className="form-label text-muted mb-1">New Confirm Password</label>
                            <input type="password" className="form-control" name="confirm_password" id="newconfPassword" onChange={handleInputChange}/>
                            <div className="error confirm_password-error small text-danger px-2 py-1 d-none"></div>
                        </div>
                        <div className="col-sm-12 mb-4">
                            <button type="button" onClick={handleSubmitForm} className="btn btn-primary px-4">Update</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default ChangePassword