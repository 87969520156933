import ReactDOM from 'react-dom';
import { fetchData } from '../components/Helper'
import { useContext, useEffect, useRef, useState } from 'react';
import DataTables from '../components/Datatable';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import GlobalContext from '../components/GlobalContext';

function Bids() {
    const dt_reload = useRef()
    const history = useHistory();
    const [context, setContext] = useContext(GlobalContext)
    const [vehicleId] =  useState(useParams().vehicle_id)
    const [auctionId] =  useState(useParams().id)
    const [data, setData] = useState()

    useEffect(() => {
        fetchData(`auction-details/${auctionId}`, 'GET', '', true, false, (res) => {
            if(res.status){
                setData(res)
            }
        }, (err) => {
            //console.log(err)
        })
    }, []);

    const [dt] = useState({
                            dt_url: `bidding?auction=${auctionId}&vehicle=${vehicleId}`,
                            dt_name: 'auction',
                            dt_column: [
                                { "data":"id", "title": "ID"},
                                { "data":"name", "name":"users.name", "title":"Buyer Name"},
                                { "data":"phone", "name":"user_details.phone","title":"Buyer Phone"},
                                { "data":"make", "name":"vehicles.make", "title":"Vehicle Maker" },
                                { "data":"model", "name": "vehicles.model", "title":"Vehicle Model" },
                                { "data":"year", "name": "vehicles.year", "title":"Vehicle Year" },
                                { "data":"reserve_price", "name": "vehicles.reserve_price", "title":"Vehicle Reserve Price", "visible": context.role ==='buyer' ? false: true},
                                { "data":"bid_amount", "title":"Bid Amount" },
                                { "data":"out_bid", "title":"Out Bid Amount", "visible": false },
                                { "data":"max_bid", "title":"Maximum Bid Amount" },
                                { "data":"created_at", "title":"Bid Date" },
                                { "data":"status", "title":"Status", class: 'text-end'},
                                { "data":"id", "title":"Action", searchable:false, sortable:false, class: 'text-end', "visible": context.role ==='admin' ? true: false }
                            ],
                            dt_column_defs: [
                                {
                                    targets: 11,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                            { rowData.status == "accept" ? <span className="text-success">Accept</span> : ''}
                                            {rowData.status == "closed" ? <span className="text-danger">Closed</span> :'' }
                                            {rowData.status == "outbid" ? <span className="text-warning">Out Bid</span> : ''}
                                            {rowData.status == "put" && (!['pending_payment', 'paid'].includes(rowData.vehicle_status)) ? <span className="text-warning">Put</span> : '' }
                                            {rowData.status == "put" && context.role == "buyer" && (['pending_payment', 'paid'].includes(rowData.vehicle_status)) ? <span className="text-warning">{ rowData.vehicle_won_price == rowData.max_bid ? (rowData.vehicle_status == 'paid' ? <b className='text-success fw-light'>Paid</b> : 'Pending Payment') : 'Put' }</span> : ''}
                                            {rowData.status == "put" && context.role != "buyer" && ['pending_payment', 'paid'].includes(rowData.vehicle_status) ? <span className="text-warning">{ rowData.vehicle_won_price == rowData.max_bid ? (rowData.vehicle_status == 'paid' ? <b className='text-success fw-light'>Paid</b> : 'Awaiting Payment') : 'Put' }</span> :''}

                                            {/* {rowData.status == "put" && history.location.state[1] == "open" ? <span className="text-warning">Put</span> : '' } */}
                                            {/* {rowData.status == "put" && (context.role == "seller_private" || context.role == "seller_corporate" || context.role == "admin") && history.location.state[0] == rowData.max_bid && history.location.state[1] == "closed" ? <span className="text-warning">Awaiting Payment</span> : rowData.status == "put" 
                                            && (context.role != "buyer") && history.location.state[1] == "closed" ? <span className="text-warning">Put</span> :''}  */}
                                            {/* {rowData.status == "put" && context.role == "buyer"  && history.location.state[0] == rowData.max_bid && history.location.state[1] == "closed" ?  <span className="text-warning">Pending Payment</span> : rowData.status == "put" && context.role == "buyer" && history.location.state[1] == "closed" ? <span className="text-warning">Put</span> : ' '}  */}
                                        
                                        </>, td)
                                    },
                                },
                                {
                                    targets: 6,
                                    createdCell: (td, cellData, rowData, row, col) => {
                                        ReactDOM.render(<><span>PKR {rowData.reserve_price.toFixed(2)}</span></>, td)
                                    },
                                },
                                {
                                    targets: 7,
                                    createdCell: (td, cellData, rowData, row, col) => {
                                        ReactDOM.render(<><span>PKR {rowData.bid_amount.toFixed(2)}</span></>, td)
                                    },
                                },
                                {
                                    targets: 9,
                                    createdCell: (td, cellData, rowData, row, col) => {
                                        ReactDOM.render(<><span>PKR {rowData.max_bid ? rowData.max_bid.toFixed(2) : '0.00'}</span></>, td)
                                    },
                                },
                                {
                                    targets: 12,
                                    createdCell: (td, cellData, rowData, row, col) =>{
                                        ReactDOM.render(<>
                                        {rowData.status == "put" && rowData.vehicle_status == "pending_payment" && rowData.vehicle_won_price == rowData.max_bid ?
                                            <button type="button" className="btn btn-xs btn-outline-success" title="Paid" onClick={() => paidVehicle(rowData.vehicle_id)}>
                                                <svg className="bi bi-2x">
                                                    <use xlinkHref="#bi_thumbsup" />
                                                </svg>
                                            </button>
                                        :'' }
                                        </>, td)
                                    },
                                },

                            ],
                            dt_paging: true,
                            dt_createdRow: ''
                        })

    const handleChangeStatus = (status, id) => {
        fetchData(`auction/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            dt_reload.current.reloadDatatable()
        }, (err) => {
            
        })
    }

    const paidVehicle = (id) => {
        fetchData(`vehicle/change-status`, 'POST', {'id': id, 'status': 'paid'}, true, false, (res) => {
            dt_reload.current.reloadDatatable()
        }, (err) => {})
    }

    return (
        <>
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row py-2 align-items-center">
                    <div className="col-sm-6 py-1">
                        <h6 className="page-topheading fs-16px fw-bold text-blue d-inline-block m-0 opacity-85">
                            <svg className="bi me-2">
                                <use xlinkHref="#bi_gavel"></use>
                            </svg>Bidding List for Vehicle #{vehicleId} from Auction #{auctionId} - From {data ? data.data.auction_start_date : '#'} to {data ? data.data.auction_end_date : '#'}
                        </h6>
                    </div>
                    <div className="col-sm-6 py-1 text-sm-end">
                        <Link className="btn btn-dark" to={({pathname:`/auction-list/${auctionId}/vehicles`,state:history.location.state[1]})} role="button">
                            <svg className="bi me-1">
                                <use xlinkHref="#bi_arrowleft" />
                            </svg>
                            Go Back
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTables dt_data={dt} ref={dt_reload}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="editCategory" aria-hidden="true" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header bg-light py-2">
                        <h5 className="modal-title">Edit Category</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
              
                </div>
            </div>
        </div>
        </>
    )
}

export default Bids