import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { scrollbarSetToTop } from "../components/Helper";

function Privacy(){

    useEffect(() => {
        scrollbarSetToTop()
    }, []);

    return (
    <>
    <Helmet>
		<meta charSet="utf-8" />
		<title>Quality Car Auctions | Terms & Conditions</title>
	</Helmet>
    <section className="py-5" style={{background: '#cfe2ff'}}>
		<div className="container py-sm-2">
			<div className="row justify-content-center text-center">
				<div className="col-sm-12 col-md-10 col-xl-8">
					<h2 className="h1 fw-bold w-75 mx-auto">
                        Terms & Conditions
					</h2>
					<nav className="d-flex justify-content-center" style={{'--bs-breadcrumb-divider': '&#10140;'}} aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
							<li className="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	</section>

	<section className="privacy-policy py-5">
		<div className="container">
		    <div className="row">
			    <div className="col-sm-12">
                    <h4 className="fw-bold">Buyer:</h4>

                    <ul className="browser-default">
                        <li>Upon winning a vehicle bid Buyer is liable to pay the make the full payment within 72 hours of bid been accepted</li>
                        <li>Payment needs to be paid via bank transfer in Bank account number shown on the invoice sent to buyer via email.</li>
                        <li>Buyer will need to pay 2% auction fee on top of the winning bid price.</li>
                        <li>Buyer will need to pay additional delivery cost if he opts to get the purchased vehicle delivered to his site.</li>
                        <li>Delivery cost will differ as per individual request, and will be provided by Q Car Auction offices.</li>
                        <li>Buyer will need to pay a fully refundable deposit of PKR 50,000 to complete the registration process and to be able to place bids on a vehicle.</li>
                        <li>Buyer will be refunded the deposit if membership to Q car Auction is terminated by either Q Car Auction or buyer themselves.</li>
                        <li>Buyer will lose complete deposit of PKR 50,000 if he fails to make a full invoiced payment of purchased vehicles through the auction within 72 hours of auction end time.</li>
                        <li>Buyer will need to remove the vehicle from Q Car Auction site within 5 working days, from the end of the auction.</li>
                        <li>Buyer can start a claim based on vehicle mechanical and condition report within 5 working days of the end of the auction.</li>
                        <li>All payments will need to go through bank transfer where the Buyer’s bank account is required to have same Account name as registered with Q Car Auction.</li>
                        <li>Payments can be made in cash on one selected Q Car Auction site on special request.</li>
                    </ul>
                    <h4 className="fw-bold">Seller:</h4>
                    <ul className="browser-default">
                        <li>Sellers need to pay no fee and no deposit if he is willing to drop the vehicle to one of the Q Car Auction sites.</li>
                        <li>On dropping the vehicle to Q Car Auction site, seller is required to drop original vehicle registration document along with all the accessories of the vehicle (All keys, service Book, Wheel nut key, etc).</li>
                        <li>A private seller will need to show proof of owner ship of the vehicle (For example vehicle registration document with his own name matching with name on his NIC card).</li>
                        <li>All sellers if not the registered keeper on vehicle registration document will need to an authority letter from the registered person along with NIC copies of both registered owner of the vehicle and the seller themselves. </li>
                        <li>Seller can list a vehicle for Auction from their Own site with a refundable deposit of PKR 50,000 via bank transfer to Q Car Auction Bank account.</li>
                        <li>If Vehicle is auctioned from Seller’s site, the deposit will be refunded within 2-3 weeks after sale of the vehicle via bank transfer.</li>
                        <li>Seller will be paid the full payment of winning bid within 2 weeks of auction end.</li>
                        <li>If Vehicle is auctioned from Seller’s site, Seller will lose the refundable deposit of PKR 50,000 any information in vehicle Mechanical and condition report proves to be inaccurate.</li>
                        <li>All payments to the Seller will be via Bank transfer.</li>
                        <li>Seller’s bank account is required to have same of the seller as used when registering with Q Car Auction.</li>
                        <li>Seller will not raise any disputes on the vehicle's interior, exterior or mechanical condition under all four circumstances (Vehicle does not get sold, Vehicle is returned to the seller, vehicle is sold but returned due to other reasons, vehicle was sold however sale was not completed due to other reasons). </li>
                    </ul>
                </div>
            </div>
		</div>
	</section>
    </>
    )
}

export default Privacy