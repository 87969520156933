import _ from 'lodash'

export default function ReportIcon(props){
    return(
        <>
        { props.type === 'ok' ? <svg className="bi bi-3x me-2 text-success"><use xlinkHref="#bi_checkright"></use></svg> : '' }
        { props.type === 'requires_attention' ? <svg className="bi bi-3x me-2 text-orange"><use xlinkHref="#bi_exclamation"></use></svg> : '' }
        { props.type === 'not_working' ? <svg className="bi bi-3x me-2 text-danger"><use xlinkHref="#bi_exclamation2"></use></svg> : '' }
        { props.type === 'not_applicable' ? <svg className="bi bi-3x me-2 text-muted"><use xlinkHref="#bi_checkcross"></use></svg> : '' }
        { props.type === "i_don't_know" ? <svg className="bi bi-3x me-2 text-black"><use xlinkHref="#bi_question"></use></svg> : '' }
        </>
    )
}

export function ReportCheckIcon(props) {
    return (
        <>
        { props.reportChecks ? props.reportChecks[props.report_type].map((item, i) => {
            return <li className="col-lg-6 list-group-item d-inline-flex justify-content-between align-items-center px-01 hover-hightlight" key={`${item.name}_${i}`}>
                <span className="opacity-90">{item.title}</span>
                <div className="d-flex">
                    <label className="form-icon-check text-success me-3" role="button" data-bs-title="Ok" data-bs-toggle="tooltip" data-bs-trigger="hover">
                        <input className="form-check-input" type="radio" name={item.name} defaultChecked={props.checked_value[item.name] ? (props.checked_value[item.name] === 'ok' ? true : false) : true} value="ok"/>
                        <svg className="bi fs-20px">
                            <use xlinkHref="#bi_checkright"></use>
                        </svg>
                    </label>
                    <label className="form-icon-check text-orange me-3" role="button" data-bs-title="Requires attention" data-bs-toggle="tooltip" data-bs-trigger="hover">
                        <input className="form-check-input" type="radio" name={item.name} defaultChecked={props.checked_value[item.name] === 'requires_attention' ? true : false} value="requires_attention"/>
                        <svg className="bi fs-20px">
                            <use xlinkHref="#bi_exclamation"></use>
                        </svg>
                    </label>
                    <label className="form-icon-check text-danger me-3" role="button" data-bs-title="Not working" data-bs-toggle="tooltip" data-bs-trigger="hover">
                        <input className="form-check-input" type="radio" name={item.name} defaultChecked={props.checked_value[item.name] === 'not_working' ? true : false} value="not_working"/>
                        <svg className="bi fs-20px">
                            <use xlinkHref="#bi_exclamation2"></use>
                        </svg>
                    </label>
                    <label className="form-icon-check text-muted me-3" role="button" data-bs-title="Not applicable" data-bs-toggle="tooltip" data-bs-trigger="hover">
                        <input className="form-check-input" type="radio" name={item.name} defaultChecked={props.checked_value[item.name] === 'not_applicable' ? true : false} value="not_applicable"/>
                        <svg className="bi fs-20px">
                            <use xlinkHref="#bi_checkcross"></use>
                        </svg>
                    </label>
                    <label className="form-icon-check text-black" role="button" data-bs-title="I don't know" data-bs-toggle="tooltip" data-bs-trigger="hover">
                        <input className="form-check-input" type="radio" name={item.name} defaultChecked={props.checked_value[item.name] === "i_don't_know" ? true : false} value="i_don't_know"/>
                        <svg className="bi fs-20px">
                            <use xlinkHref="#bi_question"></use>
                        </svg>
                    </label>
                </div>
            </li>
        }) : '' }
        </>
    )
}
