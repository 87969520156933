function NotFound(){
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
            <section className="d-flex align-items-center align-middle p-5 w-100 start-0 top-0 h-100">
                <style jsx="true">{`
                    h1{
                        font-size: calc( 12vw + 24px);
                    }
                    h5{
                        font-size: calc( 1vw + 16px);
                    }
                `}</style>
                <div className="col-sm-12 text-center">
                    <h1 className="fw-bolder text-blue my-5">404</h1>
                    <h5 className="fw-bolder text-blue my-5">Opps! Page Not Found</h5>
                </div>
            </section>
            </div>
        </div>
    )
}

export default NotFound;